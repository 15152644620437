import { Button, Divider, Popconfirm } from "antd";
import EmailCard from "./EmailCard";
import { IoAddOutline } from "react-icons/io5";
import { useState } from "react";
import { toast } from "react-toastify";
import { FaSave } from "react-icons/fa";
import {
  EmailCardType,
  LeavePreferncesRes,
} from "../../../../models/super-panel";
import {
  convertPresetsToEmailCards,
  generateLeavePreferences,
} from "../../../../utils/preferences";
import Loading from "../../../common/Loading";
import useFetch from "../../../../hooks/useFetch";
import useAxios from "../../../../hooks/useAxios";
import { LuTimerReset } from "react-icons/lu";
import { useTranslation } from "react-i18next";

const LeaveSettings = () => {
  const { t } = useTranslation();
  const [emailCards, setEmailCards] = useState<EmailCardType[]>([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { fetch, isLoading: isLoadingSubmitForm } = useAxios({
    disabledStates: false,
  });

  const { isLoading, refetch } = useFetch<LeavePreferncesRes>({
    url: "/admin/preference/leave",
    method: "GET",
    onSuccess: (res) => {
      const setting = res?.data?.preference?.setting;
      const erbil = setting?.erbil || [];
      const baghdad = setting?.baghdad || [];
      const sulaymaniyah = setting?.sulaymaniyah || [];

      const convertedErbil = convertPresetsToEmailCards(erbil, "Erbil") || [];
      const convertedSulaymaniyah =
        convertPresetsToEmailCards(sulaymaniyah, "Sulaymaniyah") || [];
      const convertedBaghdad =
        convertPresetsToEmailCards(baghdad, "Baghdad") || [];

      setEmailCards([
        ...convertedErbil,
        ...convertedSulaymaniyah,
        ...convertedBaghdad,
      ]);
    },
  });

  if (isLoading || isSubmiting) {
    return <Loading />;
  }

  return (
    <div className="mb-10">
      <Popconfirm
        placement="bottomLeft"
        title={t("send-leave-emails")}
        description={t("send-leave-emails-confirm-message")}
        okText={t("yes")}
        cancelText={t("no")}
        onConfirm={async () => {
          fetch({
            url: `/admin/leave/reschedule`,
            method: "POST",
            payload: {},
            onSuccess: () => {
              refetch();
              toast.success(t("leave-emails-sent-successfully"));
            },
          });
        }}
        okButtonProps={{ className: "bg-primary" }}
      >
        <Button
          htmlType="button"
          type="primary"
          className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary mb-5"
        >
          <LuTimerReset size={20} /> {t("send-leave-emails")}
        </Button>
      </Popconfirm>
      <Divider />
      <Button
        htmlType="button"
        type="primary"
        className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary mb-5"
        onClick={() => {
          setEmailCards((prev) => [
            {
              id: `card ${Date.now() + "" + Math.random()}`,
              name: `Leave Emails: ${emailCards.length}`,
              emails: {
                adminToInputs: [{ id: "email main", value: "" }],
                reqLeaveToInputs: [{ id: "email main", value: "" }],
                leaveCancelToInputs: [{ id: "email main", value: "" }],
                adminCcInputs: [{ id: "email main", value: "" }],
                reqLeaveCcInputs: [{ id: "email main", value: "" }],
                leaveCancelCcInputs: [{ id: "email main", value: "" }],
              },
              selectedCityDays: { city: "", days: [] },
            },
            ...prev,
          ]);
        }}
      >
        <IoAddOutline size={20} />
        <div>{t("new")}</div>
      </Button>
      <div className="flex flex-col gap-5">
        {emailCards.map((card) => {
          return (
            <EmailCard
              key={card.id}
              emails={card.emails}
              selectedCityDays={card.selectedCityDays}
              name={card.name}
              group={card.group}
              disabledDays={emailCards
                .filter(
                  (c) =>
                    c.selectedCityDays.city === card.selectedCityDays.city &&
                    c.id !== card.id
                )
                .map((c) => c.selectedCityDays.days)
                .flat(1)}
              onRemoveCard={() => {
                setEmailCards((prev) => prev.filter((c) => c.id !== card.id));
              }}
              onChangeName={(value) => {
                setEmailCards((prev) =>
                  prev.map((c) => {
                    if (c.id === card.id) {
                      return {
                        ...c,
                        name: value,
                      };
                    }
                    return c;
                  })
                );
              }}
              onChangeGroup={(value) => {
                setEmailCards((prev) =>
                  prev.map((c) => {
                    if (c.id === card.id) {
                      return {
                        ...c,
                        group: value,
                      };
                    }
                    return c;
                  })
                );
              }}
              onAddNewEmail={(label) => {
                setEmailCards((prev) =>
                  prev.map((c) => {
                    if (card.id === c.id) {
                      return {
                        ...card,
                        emails: {
                          ...card.emails,
                          [label]: [
                            ...card.emails[label],
                            {
                              id: `email ${Date.now() + "" + Math.random()}`,
                              value: "",
                            },
                          ],
                        },
                      };
                    }
                    return c;
                  })
                );
              }}
              onRemoveEmail={(label, id) => {
                setEmailCards((prev) =>
                  prev.map((c) => {
                    if (c.id === card.id) {
                      return {
                        ...c,
                        emails: {
                          ...c.emails,
                          [label]: c.emails[label].filter(
                            (email) => email.id !== id
                          ),
                        },
                      };
                    }
                    return c;
                  })
                );
              }}
              onEmailValueChange={(label, id, value) => {
                setEmailCards((prev) =>
                  prev.map((c) => {
                    if (c.id === card.id) {
                      return {
                        ...c,
                        emails: {
                          ...c.emails,
                          [label]: c.emails[label].map((email) => {
                            if (email.id === id) {
                              return { ...email, value: value };
                            }
                            return email;
                          }),
                        },
                      };
                    }
                    return c;
                  })
                );
              }}
              onDaysChange={(values) => {
                setEmailCards((prev) =>
                  prev.map((c) => {
                    if (c.id === card.id) {
                      if (!card.selectedCityDays.city) {
                        toast.warn(t("select-city-first"));
                        return c;
                      }
                      return {
                        ...card,
                        selectedCityDays: {
                          ...card.selectedCityDays,
                          days: values,
                        },
                      };
                    }
                    return c;
                  })
                );
              }}
              onCityChange={(value) => {
                setEmailCards((prev) =>
                  prev.map((c) => {
                    if (c.id === card.id) {
                      return {
                        ...card,
                        selectedCityDays: {
                          days: [],
                          city: value,
                        },
                      };
                    }
                    return c;
                  })
                );
              }}
            />
          );
        })}
      </div>
      <Button
        htmlType="button"
        type="primary"
        className="fixed bottom-6 right-12 btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary"
        onClick={() => {
          setIsSubmiting(true);
          const erbil = emailCards.filter(
            (c) => c.selectedCityDays.city === "Erbil"
          );
          const sulaymaniyah = emailCards.filter(
            (c) => c.selectedCityDays.city === "Sulaymaniyah"
          );
          const baghdad = emailCards.filter(
            (c) => c.selectedCityDays.city === "Baghdad"
          );

          const erbilPreferences = generateLeavePreferences(erbil);
          const sulaymaniyahPreferences =
            generateLeavePreferences(sulaymaniyah);
          const baghdadPreferences = generateLeavePreferences(baghdad);

          const leavePreferences = {
            erbil: erbilPreferences,
            sulaymaniyah: sulaymaniyahPreferences,
            baghdad: baghdadPreferences,
          };
          fetch({
            url: "/admin/preference/leave",
            method: "PUT",
            payload: leavePreferences,
            onSuccess: () => {
              refetch();
              toast.success("Leave Preferences Saved Successfully");
            },
            onEnd: () => {
              setIsSubmiting(false);
            },
          });
        }}
        loading={isLoadingSubmitForm}
      >
        <FaSave size={20} />
        <div>{t("save")}</div>
      </Button>
    </div>
  );
};

export default LeaveSettings;
