import classes from "./EmailCard.module.css";
import {
  Checkbox,
  InputNumber,
  Popconfirm,
  Select,
  Switch,
  Typography,
} from "antd";
import EmailInputs from "./EmailInputs";
import { AiOutlineDelete } from "react-icons/ai";
import { BsInfo } from "react-icons/bs";
import { Emails } from "../../../../models/super-panel";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const cities: { value: string; label: string }[] = [
  {
    value: "Erbil",
    label: "Erbil",
  },
  {
    value: "Sulaymaniyah",
    label: "Sulaymaniyah",
  },
  {
    value: "Baghdad",
    label: "Baghdad",
  },
];

export type emailLabels =
  | "adminToInputs"
  | "reqLeaveToInputs"
  | "leaveCancelToInputs"
  | "adminCcInputs"
  | "reqLeaveCcInputs"
  | "leaveCancelCcInputs";

interface EmailCardProps {
  onRemoveCard: () => void;
  onChangeName: (value: string) => void;
  onChangeGroup: (value: number) => void;
  onAddNewEmail: (label: emailLabels) => void;
  onRemoveEmail: (label: emailLabels, id: string) => void;
  onEmailValueChange: (label: emailLabels, id: string, value: string) => void;
  onDaysChange: (values: string[]) => void;
  onCityChange: (value: string) => void;
  emails: Emails;
  name: string;
  group?: number;
  selectedCityDays: {
    city: string;
    days: string[];
  };
  disabledDays: string[];
}

const EmailCard = ({
  onRemoveCard,
  onAddNewEmail,
  onChangeName,
  onRemoveEmail,
  onEmailValueChange,
  onDaysChange,
  onCityChange,
  onChangeGroup,
  emails,
  selectedCityDays,
  disabledDays,
  name,
  group,
}: EmailCardProps) => {
  const { t } = useTranslation();
  const [isGrouped, setIsGrouped] = useState(group && group > 0 ? true : false);
  const daysOfWeek = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];

  return (
    <div className={classes.card}>
      <div className={classes.cardHeading}>
        <div className="flex flex-row gap-3 items-center">
          <Typography.Text
            editable={{ onChange: onChangeName }}
            className={classes.cardHeader}
          >
            {name}
          </Typography.Text>
          <div className="flex flex-row gap-1 items-center">
            <div>{t("group")}</div>
            <Switch
              value={isGrouped}
              onChange={(val) => {
                setIsGrouped(val);
                onChangeGroup(0);
              }}
            />
            {isGrouped && (
              <InputNumber
                value={group}
                onChange={(val) => {
                  const num = !val ? 0 : val;
                  onChangeGroup(num);
                }}
              />
            )}
          </div>
        </div>
        <div className={classes.btnShortCuts}>
          <div className={classes.info}>
            <BsInfo size={25} />
          </div>

          <Popconfirm
            placement="bottomLeft"
            title={t("remove-email-card")}
            description={t("remove-confirm-message")}
            okText={t("yes")}
            cancelText={t("no")}
            onConfirm={onRemoveCard}
            okButtonProps={{ className: "bg-primary" }}
          >
            <div className={classes.trash}>
              <AiOutlineDelete size={18} />
            </div>
          </Popconfirm>
        </div>
      </div>
      <div className={classes.cardBody}>
        <div className={classes.emails}>
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="adminToInputs"
            inputs={emails["adminToInputs"]}
            title={t("admin")}
            subTitle={t("to")}
          />
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="reqLeaveToInputs"
            inputs={emails["reqLeaveToInputs"]}
            title={t("leave-request")}
            subTitle={t("to")}
          />
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="leaveCancelToInputs"
            inputs={emails["leaveCancelToInputs"]}
            title={t("cancel-leave")}
            subTitle={t("to")}
          />
        </div>
        <div className={classes.borderDashedAnt}></div>
        <div className={classes.emails}>
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="adminCcInputs"
            inputs={emails["adminCcInputs"]}
            subTitle="CC"
          />
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="reqLeaveCcInputs"
            inputs={emails["reqLeaveCcInputs"]}
            subTitle="CC"
          />
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="leaveCancelCcInputs"
            inputs={emails["leaveCancelCcInputs"]}
            subTitle="CC"
          />
        </div>
      </div>
      <div className={classes.finalize}>
        <div>
          <div className={classes.label}>{t("select-city")}</div>
          <Select
            options={cities}
            onChange={(value) => {
              onCityChange(value);
            }}
            value={selectedCityDays.city}
            className="w-[120px]"
          />
        </div>

        <div>
          <div className={classes.label}>{t("select-days")}</div>
          <Checkbox.Group
            className="w-full"
            onChange={(values) => {
              onDaysChange(values);
            }}
            value={selectedCityDays.days}
          >
            <div className={classes.checkboxes}>
              {daysOfWeek.map((day) => (
                <Checkbox
                  disabled={disabledDays.includes(day)}
                  className="select-none"
                  key={day}
                  value={day}
                >
                  {day}
                </Checkbox>
              ))}
            </div>
          </Checkbox.Group>
        </div>
      </div>
    </div>
  );
};

export default EmailCard;
