import { Tooltip, Typography, Space } from "antd";
import { LinkOutlined, CopyOutlined } from "@ant-design/icons";
import copy from "copy-to-clipboard";
import { useTranslation } from "react-i18next";

interface ExternalLinkProps {
  url: string;
  target?: "_self" | "_blank";
}

const { Text } = Typography;

const ExternalLink = ({ url, target = "_blank" }: ExternalLinkProps) => {
  const { t } = useTranslation();
  const maxLength = 15;
  const isLongUrl = url.length > maxLength;
  const displayUrl = isLongUrl ? `${url.slice(0, maxLength)}...` : url;

  const handleCopy = () => {
    copy(url);
  };

  return (
    <Space>
      {url && (
        <>
          <Tooltip title={t("click-to-copy-url")}>
            <CopyOutlined
              onClick={handleCopy}
              className="cursor-pointer text-blue-500"
            />
          </Tooltip>
          <Tooltip title={t("click-to-open") + url}>
            <Text
              className="flex items-center cursor-pointer text-blue-500 text-nowrap"
              onClick={() => window.open(url, target)}
            >
              {displayUrl}
              <LinkOutlined className="ml-1 text-blue-500" />
            </Text>
          </Tooltip>
        </>
      )}
    </Space>
  );
};

export default ExternalLink;
