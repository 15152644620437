import classes from "./EmailCard.module.css";
import { Popconfirm, Typography } from "antd";
import EmailInputs from "./EmailInputs";
import { AiOutlineDelete } from "react-icons/ai";
import { BsInfo } from "react-icons/bs";
import { RiderEmails } from "../../../../models/super-panel";
import { useTranslation } from "react-i18next";

export type emailLabels =
  | "adminToInputs"
  | "freezeToInputs"
  | "terminateToInputs"
  | "changeDataToInputs"
  | "adminCcInputs"
  | "freezeCcInputs"
  | "changeDataCcInputs"
  | "terminateCcInputs";

interface EmailCardProps {
  onRemoveCard: () => void;
  onChangeName: (value: string) => void;
  onAddNewEmail: (label: emailLabels) => void;
  onRemoveEmail: (label: emailLabels, id: string) => void;
  onEmailValueChange: (label: emailLabels, id: string, value: string) => void;
  emails: RiderEmails;
  name: string;
  city: string;
}

const EmailCard = ({
  onRemoveCard,
  onAddNewEmail,
  onChangeName,
  onRemoveEmail,
  onEmailValueChange,
  emails,
  city,
  name,
}: EmailCardProps) => {
  const { t } = useTranslation();
  return (
    <div className={classes.card}>
      <div className={classes.cardHeading}>
        <div className={classes.cardTextHeading}>
          <Typography.Text
            editable={{ onChange: onChangeName }}
            className={classes.cardHeader}
          >
            {name}
          </Typography.Text>
          {city && <div className={classes.cardHeader}>({city})</div>}
        </div>
        <div className={classes.btnShortCuts}>
          <div className={classes.info}>
            <BsInfo size={25} />
          </div>

          <Popconfirm
            placement="bottomLeft"
            title={t("remove-email-card")}
            description={t("remove-confirm-message")}
            okText={t("yes")}
            cancelText={t("no")}
            onConfirm={onRemoveCard}
            okButtonProps={{ className: "bg-primary" }}
          >
            <div className={classes.trash}>
              <AiOutlineDelete size={18} />
            </div>
          </Popconfirm>
        </div>
      </div>
      <div className={classes.cardBody}>
        <div className={classes.emails}>
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="adminToInputs"
            inputs={emails["adminToInputs"]}
            title={t("admin")}
            subTitle={t("to")}
          />
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="freezeToInputs"
            inputs={emails["freezeToInputs"]}
            title={t("freeze")}
            subTitle={t("to")}
          />
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="terminateToInputs"
            inputs={emails["terminateToInputs"]}
            title={t("terminate-resign")}
            subTitle={t("to")}
          />
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="changeDataToInputs"
            inputs={emails["changeDataToInputs"]}
            title={t("change-data")}
            subTitle={t("to")}
          />
        </div>
        <div className={classes.borderDashedAnt}></div>
        <div className={classes.emails}>
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="adminCcInputs"
            inputs={emails["adminCcInputs"]}
            subTitle="CC"
          />
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="freezeCcInputs"
            inputs={emails["freezeCcInputs"]}
            subTitle="CC"
          />
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="terminateCcInputs"
            inputs={emails["terminateCcInputs"]}
            subTitle="CC"
          />
          <EmailInputs
            onAddNewEmail={onAddNewEmail}
            onRemoveEmail={onRemoveEmail}
            onEmailValueChange={onEmailValueChange}
            label="changeDataCcInputs"
            inputs={emails["changeDataCcInputs"]}
            subTitle="CC"
          />
        </div>
      </div>
    </div>
  );
};

export default EmailCard;
