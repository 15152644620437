import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal, Select, Table, Button } from "antd";
import { toast } from "react-toastify";
import { MapListValuesType } from "./CSVProcessor";

interface Mapping {
  csvValue: string | number;
  mappedValue: number | null;
}

interface CSVMapValuesProps {
  show: boolean;
  onHide: () => void;
  data: string[][];
  csvColumn: string;
  onSetData: (val: string[][]) => void;
  csvColumnIndex: number;
  setColumnValuesChecked: Dispatch<SetStateAction<Record<string, boolean>>>;
  mapListValues?: MapListValuesType;
  dbColumn: string;
}

const getUniqueColumnValues = (data: string[][], csvColumnIndex: number) => {
  if (data.length === 0) return [];

  if (csvColumnIndex === -1) return [];

  const uniqueValues = new Set<string>();

  for (let i = 1; i < data.length; i++) {
    const value = data[i][csvColumnIndex] || "Null Values";
    uniqueValues.add(value.trim());
  }

  return Array.from(uniqueValues);
};

const CSVMapValues = ({
  show,
  onHide,
  data,
  csvColumn,
  csvColumnIndex,
  onSetData,
  mapListValues,
  setColumnValuesChecked,
  dbColumn,
}: CSVMapValuesProps) => {
  const [uniqueValues, setUniqueValues] = useState<(string | number | null)[]>(
    []
  );

  const [mappings, setMappings] = useState<Mapping[]>([]);

  useEffect(() => {
    if (show) {
      const uniqueColumns = getUniqueColumnValues(data, csvColumnIndex);
      setUniqueValues(uniqueColumns);
      setMappings(
        uniqueColumns.map((u) => ({ csvValue: u, mappedValue: null }))
      );
    } else {
      setUniqueValues([]);
      setMappings([]);
    }
  }, [show, csvColumnIndex, data]);

  const handleMappingChange = (
    csvValue: string | number | null,
    mappedValue: number
  ) => {
    setMappings((prev) =>
      prev.map((mapping) =>
        mapping.csvValue === csvValue ? { ...mapping, mappedValue } : mapping
      )
    );
  };

  const applyMappings = () => {
    const lookup = mappings.reduce(
      (acc, { csvValue, mappedValue }) => {
        acc[csvValue] = mappedValue;
        return acc;
      },
      {} as Record<string, string | number | null>
    );

    const dataToUpdate = Array.from(data);
    for (let i = 0; i < dataToUpdate.length; i++) {
      const value = dataToUpdate[i][csvColumnIndex]?.trim() || "Null Values";

      if (value in lookup) {
        dataToUpdate[i][csvColumnIndex] =
          lookup[value] === -1 ? value : lookup[value]?.toString() || "";
      }
    }
    onSetData(dataToUpdate);
  };

  const handleSubmit = () => {
    const allMapped = mappings.every((m) => m.mappedValue !== null);
    if (!allMapped) {
      toast.warn("All unique values must be mapped before submitting.");
      return;
    }
    applyMappings();
    setColumnValuesChecked((prev) => ({ ...prev, [dbColumn]: true }));
    onHide();
  };

  return (
    <Modal
      title={`Map CSV Values of ${csvColumn}`}
      open={show}
      onCancel={onHide}
      footer={[
        <Button key="cancel" onClick={onHide}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit Mappings
        </Button>,
      ]}
      styles={{ body: { maxHeight: "80vh", overflowY: "auto" } }}
      centered
    >
      {uniqueValues.length > 0 && (
        <Table
          dataSource={uniqueValues.map((value) => ({
            key: value,
            csvValue: value,
            mappedValue:
              mappings.find((m) => m.csvValue === value)?.mappedValue || null,
          }))}
          columns={[
            {
              title: "CSV Value",
              dataIndex: "csvValue",
              key: "csvValue",
            },
            {
              title: "Mapped Value",
              dataIndex: "mappedValue",
              key: "mappedValue",
              render: (_, record) => (
                <Select
                  value={record.mappedValue}
                  placeholder="Select"
                  onChange={(value) =>
                    handleMappingChange(record.csvValue, value)
                  }
                  className="w-full"
                  options={[
                    { label: "Default", value: -1 },
                    ...(mapListValues || []),
                  ]}
                />
              ),
            },
          ]}
          pagination={false}
        />
      )}
    </Modal>
  );
};

export default CSVMapValues;
