import { useEffect, useState } from "react";
import { Button, Input, Modal, Pagination, Table, Tooltip } from "antd";
import moment from "moment-timezone";
import ViewImageModal from "../../common/ViewImageModal";
import { toast } from "react-toastify";
import RiderCard from "../../common/RiderCard";
import { BsDownload } from "react-icons/bs";
import { generateCSV } from "../../../utils/CSVUtils";
import useUser from "../../../store/User";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PocketBase from "pocketbase";
import {
  RemoveSuspensionRecord,
  RemoveSuspensionResponse,
} from "../../../models/rider";
import useRemoveSuspensionColumns from "../hooks/useRemoveSuspensionColumns";

const RemoveSuspension = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [data, setData] = useState<RemoveSuspensionResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [rejectionReason, setRejectionReason] = useState("");
  const [selectedRows, setSelectedRows] = useState<RemoveSuspensionRecord[]>(
    []
  );
  const [showViewImageModal, setShowViewImageModal] = useState({
    imgSrc: "",
    show: false,
    title: "",
  });
  const [showRiderInfo, setShowRiderInfo] = useState({
    show: false,
    partner_id: 0,
  });
  const [showRejectModal, setShowRejectModal] = useState({
    show: false,
    id: 0,
  });

  const pb = new PocketBase("https://saas.fullship.net");
  const fsRemoveSuspensionCollection = pb.collection(
    "FS_App_Remove_Suspention"
  );

  const fetchData = async () => {
    setIsLoading(true);
    const result: RemoveSuspensionResponse =
      await fsRemoveSuspensionCollection.getList(
        pagination,
        (pagination - 1) * pageSize,
        { sort: "-created" }
      );
    setData(result);
    setIsLoading(false);
  };

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: RemoveSuspensionRecord[]) => {
      setSelectedRows(selectedRows);
    },
  };

  const refetch = () => {
    fetchData();
  };

  const { columns } = useRemoveSuspensionColumns({
    setShowRiderInfo,
    setShowRejectModal,
    setShowViewImageModal,
    refetch,
  });

  useEffect(() => {
    fetchData();
  }, []);

  if (user?.cityID != 1) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <Modal
        title={`Rider Info: ${showRiderInfo.partner_id}`}
        centered
        open={showRiderInfo.show}
        footer={null}
        onCancel={() => {
          setShowRiderInfo({ show: false, partner_id: 0 });
        }}
        styles={{ body: { maxHeight: "70vh", overflowY: "auto" } }}
        width="650px"
      >
        <RiderCard
          show={showRiderInfo.show}
          id={showRiderInfo.partner_id}
          id_type="partner_id"
        />
      </Modal>
      <Modal
        title={t("reject-remove-suspension")}
        centered
        open={showRejectModal.show}
        footer={null}
        onCancel={() => {
          setShowRejectModal({ show: false, id: 0 });
        }}
        styles={{
          body: { maxHeight: "70vh", overflowY: "auto" },
        }}
        width="400px"
      >
        <div className="pt-3 pb-5">
          <Input.TextArea
            onChange={(e) => {
              setRejectionReason(e.target.value);
            }}
            value={rejectionReason}
            rows={5}
          />
        </div>
        <div className="flex justify-end w-full">
          <Button
            type="primary"
            className="bg-primary"
            loading={isLoading}
            onClick={() => {
              if (!rejectionReason.trim()) {
                toast.warn("You should write a rejection reason");
                return;
              }
              setIsLoading(true);
              fsRemoveSuspensionCollection
                .update(showRejectModal.id + "", {
                  status: "rejected",
                  rejection_reason: rejectionReason,
                })
                .then(() => {
                  refetch();
                  toast.success("status changed to rejected");
                })
                .catch(() => {
                  toast.error("Failed to change the status");
                })
                .finally(() => {
                  setShowRejectModal({ show: false, id: 0 });
                  setIsLoading(false);
                  setRejectionReason("");
                });
            }}
          >
            {t("reject")}
          </Button>
        </div>
      </Modal>
      <ViewImageModal
        show={showViewImageModal.show}
        onHide={() => {
          setShowViewImageModal({ imgSrc: "", show: false, title: "" });
        }}
        title={showViewImageModal.title}
        imgSrc={showViewImageModal.imgSrc}
        local={showViewImageModal.title === "Suspension Screenshot"}
        imageProps={{
          crossOrigin:
            showViewImageModal.title === "Suspension Screenshot"
              ? "anonymous"
              : "use-credentials",
        }}
      />
      <div className="flex flex-row justify-end mb-4">
        <Tooltip title={t("export-rider-feedbacks")}>
          <Button
            onClick={() => {
              const selectedDataToGenerated = selectedRows?.length
                ? selectedRows
                : data?.items;
              generateCSV(
                selectedDataToGenerated?.map((d) => {
                  return {
                    partner_id: d.partner_id,
                    rider_name: d.rider_name,
                    rider_city: d.rider_city,
                    status: d.status,
                    created: moment(d.created).format("YYYY-MM-DD hh:mm:ss A"),
                  };
                }) || [],
                "remove-suspension"
              );
            }}
            block
            className="btn-height-40 items-center rounded-border-xl-cstm max-w-[90px]"
            icon={<BsDownload size={16} />}
          />
        </Tooltip>
      </div>
      <Table
        loading={isLoading}
        columns={columns.map((c) => ({ ...c, width: "100px" }))}
        dataSource={data?.items || []}
        rowSelection={rowSelection}
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{ y: "calc(100vh - 370px)", x: "max-content" }}
      />
      <div className="flex justify-between pt-2">
        <div className="font-semibold">
          {t("total")}: {data?.totalItems}
        </div>
        <Pagination
          total={data?.totalItems}
          onChange={(page) => setPagination(page)}
          pageSize={pageSize}
          current={pagination}
          showSizeChanger={true}
          onShowSizeChange={(_, size) => {
            setPageSize(size);
            refetch();
          }}
          locale={{
            items_per_page: "/ " + t("page"),
          }}
        />
      </div>
    </div>
  );
};

export default RemoveSuspension;
