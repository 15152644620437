import { Modal } from "antd";
import useFetch from "../../../../hooks/useFetch";
import { Countries } from "../../../../models/super-panel";
import Table from "../../../UI/Table";
import ActivatePriorityForm from "./ActivatePriorityForm";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useCountriesListColumns from "./hooks/useCountriesListColumns";

interface CountriesListProps {
  show: boolean;
  onHide: () => void;
}

const CountriesList = ({ show, onHide }: CountriesListProps) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState({
    search: "",
  });
  const [showActivateModal, setShowActivateModal] = useState<{
    show: boolean;
    data?: { priority: number; active: string; id: number };
  }>({ show: false });

  const { data, isLoading, refetch } = useFetch<Countries>({
    url: "/lists/countries",
    method: "GET",
    searchQuery: JSON.stringify({
      name: searchQuery.search,
      _limit: pageSize,
      _offset: (pagination - 1) * pageSize,
    }),
    disabled: !show,
  });

  const { columns } = useCountriesListColumns({
    setShowActivateModal,
  });

  return (
    <Modal
      title={t("countries-list")}
      footer={null}
      onCancel={() => {
        onHide();
      }}
      centered
      open={show}
      width="900px"
      styles={{
        body: { maxHeight: "80vh", overflow: "hidden" },
      }}
    >
      <ActivatePriorityForm
        onHide={() => setShowActivateModal({ show: false })}
        show={showActivateModal.show}
        data={showActivateModal.data}
        refetch={refetch}
        route="/lists/countries/"
        title={t("update-country")}
      />
      <Table
        loading={isLoading || false}
        columns={columns}
        data={data?.data || []}
        pagination={true}
        total={data ? data.total : 0}
        pageSize={pageSize}
        currentPage={pagination}
        onPaginationChange={(page) => {
          setPagination(page);
        }}
        onShowSizeChange={(_, size) => {
          setPageSize(size);
        }}
        refetch={refetch}
        filter={true}
        onFilterValuesChange={(_, values) => {
          setSearchQuery({ search: values.searchText });
        }}
        scrollYH="calc(100vh - 300px)"
      />
    </Modal>
  );
};

export default CountriesList;
