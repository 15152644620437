import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import JSONPretty from "react-json-pretty";

interface ViewPayloadModalProps {
  show: boolean;
  onHide: () => void;
  payload: string;
  stack: string;
}

const MoreErrorInfoModal = ({
  show,
  onHide,
  payload,
  stack,
}: ViewPayloadModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t("more-info")}
      footer={null}
      onCancel={() => {
        onHide();
      }}
      centered
      open={show}
      width="600px"
    >
      <div className="my-7">
        <h3>Payload: </h3>
        <JSONPretty id="json-pretty" data={payload} />
      </div>
      {stack && (
        <>
          <hr />
          <div className="my-7">
            <h3>Stack: </h3>
            <div
              style={{
                fontFamily: "sans-serif",
              }}
            >
              {stack}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default MoreErrorInfoModal;
