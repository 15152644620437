import { Button, Col, Form, FormProps, Input, Modal, Row } from "antd";
import { toast } from "react-toastify";
import useAxios from "../../../hooks/useAxios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface CreateManualBackupModalProps {
  show: boolean;
  onHide: () => void;
  refetch: () => void;
}

interface FieldType {
  filename: string;
}
const CreateManualBackupModal = ({
  show,
  onHide,
  refetch,
}: CreateManualBackupModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FieldType>();
  const { fetch, isLoading } = useAxios({ disabledStates: false });

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    fetch({
      url: "/admin/backup",
      method: "POST",
      payload: {
        ...values,
      },
      onSuccess: () => {
        onHide();
        form.resetFields();
        refetch();
        toast.success(t("manual-backup-added-successfully"));
      },
    });
  };

  useEffect(() => {
    if (!show) {
      form.resetFields();
    }
  }, [show, form]);

  return (
    <Modal
      title={t("create-manual-backup")}
      footer={null}
      onCancel={() => {
        onHide();
        form.resetFields();
      }}
      centered
      open={show}
      width="400px"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              label={t("manual-backup-name")}
              name="filename"
              rules={[{ required: true }]}
            >
              <Input className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex justify-end w-full">
          <Button
            htmlType="submit"
            type="primary"
            className="bg-primary"
            loading={isLoading}
          >
            {t("create")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateManualBackupModal;
