import { Routes } from "./routes";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigProvider, Spin, theme, ThemeConfig } from "antd";
import useUser from "./store/User";
import useFetch from "./hooks/useFetch";
import { UserAuth, UserSettings } from "./models/user";
import Loading from "./components/common/Loading";
import { useEffect } from "react";
import useTheme from "./store/Theme";
import { ToastContainer } from "react-toastify";
import useGlobalLoading from "./store/GlobalLoading";
import { useTranslation } from "react-i18next";

const App = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { isAuth, setUser, setIsAuth, setUserSettings } = useUser();
  const { theme: themeMode, setTheme } = useTheme();
  const { isGlobalLoading } = useGlobalLoading();

  const isDevelopment = import.meta.env.VITE_ENVIRONMENT === "dev";

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme && theme === "dark") {
      setTheme("dark");
      document.body.classList.add("dark");
    } else {
      localStorage.setItem("theme", "light");
      setTheme("light");
      document.body.classList.remove("dark");
    }
  }, []);

  const { isLoading } = useFetch<UserAuth>({
    url: "/auth",
    method: "GET",
    isLoadingDefault: true,
    onSuccess: async (data) => {
      if (data.data) {
        setIsAuth(true);
        setUser(data.data);
        navigate(pathname === "/login" ? "/" : `${pathname}${search}`);
      }
    },
    notify: false,
  });

  useFetch<{ setting: UserSettings }>({
    url: "/account/setting",
    method: "GET",
    onSuccess: (res) => {
      setUserSettings(res?.data?.setting || {});
    },
    disabled: isLoading,
    notify: false,
  });

  if (isLoading) {
    return <Loading cHeight="h-screen" />;
  }

  const themes: Record<"dark" | "light", ThemeConfig> = {
    dark: {
      algorithm: theme.darkAlgorithm,
      token: {
        colorPrimary: "#F4D160",
      },
    },
    light: {
      token: {
        colorPrimary: "#356385",
      },
    },
  };

  return (
    <ConfigProvider
      direction={i18n.dir()}
      theme={{
        ...themes[themeMode],
        token: {
          ...(isDevelopment && { colorBgBase: "#15496B" }),
          ...themes[themeMode].token,
        },
      }}
    >
      {isGlobalLoading && (
        <div className="global-loading">
          <Spin size="large" className="loading-indicator" />
        </div>
      )}
      <Routes isAuthorized={isAuth} />
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        stacked
        theme={themeMode}
      />
    </ConfigProvider>
  );
};

export default App;
