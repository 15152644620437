import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/");
  };

  return (
    <div className="h-screen flex flex-row justify-center items-center">
      <Result
        status="404"
        title="404"
        subTitle={t("not-found-message")}
        extra={
          <Button
            type="primary"
            icon={<AiOutlineArrowLeft />}
            onClick={handleBackClick}
          >
            {t("back-home-page")}
          </Button>
        }
      />
    </div>
  );
};

export default NotFoundPage;
