import { RiderStatus, RiderWaitingStatus } from "../models/rider";

export const filterOption = (
  input: string,
  option?: {
    label: string;
    value: string | number;
    idValueSearch?: string | number;
  }
) => {
  const parsedInput = Number(input);
  if (!isNaN(parsedInput) && option?.idValueSearch) {
    return (
      option.idValueSearch.toString().startsWith(parsedInput.toString()) ||
      false
    );
  } else {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  }
};

export const getWaitingListBadgeColor = (
  status: RiderWaitingStatus | "pending"
): string => {
  switch (status) {
    case "accepted":
      return "#36d399";
    case "waiting":
      return "#fbbd23";
    case "pending":
      return "#fbbd23";
    case "review":
      return "#fbbd23";
    case "ex-rider":
      return "#9CA3AF";
    case "rejected":
      return "#e11d48";
    case "interview":
      return "#3b82f6";
    case "training":
      return "#10b981";
    case "warehouse":
      return "#c4b5fd";
    case "onboard":
      return "#f59e0b";
    case "audit":
      return "#4b5563";
    default:
      return "";
  }
};

export const getColorForScoreTen = (score: number) => {
  if (score > 6) {
    return "green";
  } else if (score > 3) {
    return "orange";
  } else {
    return "red";
  }
};

export const riderBadgeType: Record<RiderStatus, string> = {
  working: "#36d399",
  "on leave": "#fbbd23",
  resign: "#e8e8e8",
  terminated: "#e11d48",
  frozen: "#3b82f6",
  suspended: "#e11d48",
  ready: "#28a745",
};

export const extractFirstLetters = (val: string, words: number) => {
  return val
    .split(" ")
    .slice(0, words)
    .map((word) => word.charAt(0))
    .join("");
};
