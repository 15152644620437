import { useState } from "react";
import {
  Form,
  InputNumber,
  Button,
  Select,
  Card,
  Row,
  Col,
  Modal,
  Divider,
} from "antd";
import {
  BatchType,
  PayrollPreferenceType,
  PreferenceType,
} from "../../../../models/finance";
import { toast } from "react-toastify";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import useListsReq from "../../../../hooks/useListsReq";
import { FaSave } from "react-icons/fa";
import useAxios from "../../../../hooks/useAxios";
import useFetch from "../../../../hooks/useFetch";
import Loading from "../../../common/Loading";

const defaultBatch = (): BatchType => ({
  pu: null,
  do: null,
  delivery: null,
});

const defaultPreference = (): PreferenceType => ({
  vehicleTypeIds: [],
  batch: {},
  kmPay: null,
  kmPayMin: null,
  securityDepositPerMonth: null,
  securityDepositSalaryMin: null,
  securityDepositTarget: null,
  serviceFee: null,
  maxCodDiff: null,
  minCodPayOff: null,
  lateShiftPenalty: null,
  maxLateShiftPenalty: null,
});

type numberInputType =
  | "kmPay"
  | "kmPayMin"
  | "securityDepositPerMonth"
  | "securityDepositSalaryMin"
  | "securityDepositTarget"
  | "serviceFee"
  | "maxCodDiff"
  | "minCodPayOff"
  | "lateShiftPenalty"
  | "maxLateShiftPenalty";

const PayrollPreferencesPage = () => {
  const { cities, vehicles } = useListsReq({
    requestLists: [{ type: "cities" }, { type: "vehicles" }],
  });
  const [payrollPreferences, setPayrollPreferences] = useState<
    PayrollPreferenceType[]
  >([{ cityIds: [], preference: [defaultPreference()] }]);

  const [selectedBatches, setSelectedBatches] = useState<
    Record<string, Record<string, string[]>>
  >({});

  const { isLoading, refetch } = useFetch<{
    preference: { setting?: PayrollPreferenceType[] };
  }>({
    url: "/admin/preference/payroll",
    method: "GET",
    onSuccess: (res) => {
      const settings = res?.data?.preference?.setting || [];
      setPayrollPreferences(settings);

      const selectedPatchesUpdate = { ...selectedBatches };
      settings.map((ps, index) => {
        ps.preference.map((pref, pIndex) => {
          if (!selectedPatchesUpdate[index]) {
            selectedPatchesUpdate[index] = {};
          }
          if (!selectedPatchesUpdate[index][pIndex]) {
            selectedPatchesUpdate[index][pIndex] = Object.keys(pref.batch);
          }
        });
      });
      setSelectedBatches(selectedPatchesUpdate);
    },
  });

  const { fetch, isLoading: isLoadingSubmitForm } = useAxios({
    disabledStates: true,
  });

  const handleInputChange = (
    payrollIndex: number,
    prefIndex: number,
    fieldName: numberInputType,
    value: number | null
  ) => {
    const updatedPreferences = [...payrollPreferences];
    updatedPreferences[payrollIndex].preference[prefIndex][fieldName] = value;
    setPayrollPreferences(updatedPreferences);
  };

  const handleBatchChange = (
    payrollIndex: number,
    prefIndex: number,
    batchKey: string,
    field: keyof BatchType,
    value: number | null
  ) => {
    const updatedPreferences = [...payrollPreferences];
    if (
      !updatedPreferences[payrollIndex].preference[prefIndex].batch[batchKey]
    ) {
      updatedPreferences[payrollIndex].preference[prefIndex].batch[batchKey] =
        defaultBatch();
    }
    updatedPreferences[payrollIndex].preference[prefIndex].batch[batchKey][
      field
    ] = value;
    setPayrollPreferences(updatedPreferences);
  };

  const handleCityIdsChange = (values: number[], payrollIndex: number) => {
    const newValue = values.find(
      (value) => !payrollPreferences[payrollIndex]?.cityIds.includes(value)
    );
    const allCityIds: number[] = [];

    payrollPreferences.forEach((preference) => {
      allCityIds.push(...preference.cityIds);
    });

    if (newValue && allCityIds.includes(newValue)) {
      toast.warn("City is already selected");
      return;
    }
    const updatedPreferences = [...payrollPreferences];
    updatedPreferences[payrollIndex].cityIds = values;
    setPayrollPreferences(updatedPreferences);
  };

  const handleVehicleTypeIdsChange = (
    payrollIndex: number,
    prefIndex: number,
    values: number[]
  ) => {
    const newValue = values.find(
      (value) =>
        !payrollPreferences[payrollIndex]?.preference[
          prefIndex
        ]?.vehicleTypeIds?.includes(value)
    );
    const allvehicleTypeIds: number[] = [];

    payrollPreferences[payrollIndex].preference.forEach((preference) => {
      allvehicleTypeIds.push(...preference.vehicleTypeIds);
    });

    if (newValue && allvehicleTypeIds.includes(newValue)) {
      toast.warn("Vehicel is already selected");
      return;
    }

    const updatedPreferences = [...payrollPreferences];
    updatedPreferences[payrollIndex].preference[prefIndex].vehicleTypeIds =
      values;
    setPayrollPreferences(updatedPreferences);
  };

  const addPreference = (payrollIndex: number) => {
    const updatedPreferences = [...payrollPreferences];
    updatedPreferences[payrollIndex].preference.push(defaultPreference());
    setPayrollPreferences(updatedPreferences);
  };

  const addPayrollPreference = () => {
    setPayrollPreferences([
      ...payrollPreferences,
      { cityIds: [], preference: [defaultPreference()] },
    ]);
  };

  const handleAddBatch = (
    value: string,
    payrollIndex: number,
    prefIndex: number
  ) => {
    if (
      selectedBatches[payrollIndex] &&
      selectedBatches[payrollIndex][prefIndex]?.includes(value)
    ) {
      toast.warn("This Batch is already added");
      return;
    }

    setSelectedBatches((prevBatches) => {
      const updatedBatches = { ...prevBatches };

      if (!updatedBatches[payrollIndex]) {
        updatedBatches[payrollIndex] = { [prefIndex]: [] };
      }

      if (
        updatedBatches[payrollIndex] &&
        !updatedBatches[payrollIndex][prefIndex]
      ) {
        updatedBatches[payrollIndex] = {
          ...updatedBatches[payrollIndex],
          [prefIndex]: [],
        };
      }
      updatedBatches[payrollIndex][prefIndex] = [
        ...updatedBatches[payrollIndex][prefIndex],
        value,
      ];

      return updatedBatches;
    });
    setBatchValue("");
    setShowAddBatch({ show: false, payroll: -1, pref: -1 });
  };

  const removePayrollPreference = (index: number) => {
    const updatedPreferences = payrollPreferences.filter((_, i) => i !== index);
    setPayrollPreferences(updatedPreferences);
  };

  const removePreference = (payrollIndex: number, index: number) => {
    const updatedPreferences = payrollPreferences.map((payroll, i) => {
      if (i === payrollIndex) {
        return {
          ...payroll,
          preference: payroll.preference.filter((_, pi) => pi !== index),
        };
      }
      return payroll;
    });
    setPayrollPreferences(updatedPreferences);
  };

  const removeBatchPreference = (
    payrollIndex: number,
    prefIndex: number,
    batchKey: string
  ) => {
    const updatedPreferences = payrollPreferences.map((payroll, i) => {
      if (i === payrollIndex) {
        return {
          ...payroll,
          preference: payroll.preference.map((pref, pi) => {
            if (pi === prefIndex) {
              const updatedPref = { ...pref };
              delete updatedPref.batch[batchKey];
              return updatedPref;
            }
            return pref;
          }),
        };
      }
      return payroll;
    });
    setPayrollPreferences(updatedPreferences);

    setSelectedBatches((prevBatches) => {
      const updatedBatches = { ...prevBatches };
      if (
        updatedBatches[payrollIndex] &&
        updatedBatches[payrollIndex][prefIndex]
      ) {
        if (updatedBatches[payrollIndex][prefIndex].length === 1) {
          delete updatedBatches[payrollIndex][prefIndex];
        } else {
          updatedBatches[payrollIndex][prefIndex] = updatedBatches[
            payrollIndex
          ][prefIndex].filter((b) => b !== batchKey);
        }
      }

      return updatedBatches;
    });
  };

  const [showAddBatch, setShowAddBatch] = useState({
    show: false,
    payroll: -1,
    pref: -1,
  });
  const [batchVal, setBatchValue] = useState<string | null>("");

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Form layout="vertical" className="py-5">
        <Modal
          title={"Add Batch"}
          centered
          open={showAddBatch.show}
          footer={null}
          onCancel={() => {
            setShowAddBatch({ show: false, payroll: -1, pref: -1 });
          }}
          width="400px"
        >
          <div>
            <div>Batch</div>
            <InputNumber
              min={"0"}
              value={batchVal}
              onChange={(val) => setBatchValue(val)}
              className="w-full"
            />
          </div>

          <div className="mt-5 flex justify-end">
            <Button
              htmlType="button"
              type="primary"
              onClick={() => {
                if (batchVal) {
                  handleAddBatch(
                    batchVal,
                    showAddBatch.payroll,
                    showAddBatch.pref
                  );

                  return;
                }
                toast.warn("Write a batch number to add");
              }}
            >
              Add
            </Button>
          </div>
        </Modal>

        {payrollPreferences.map((payroll, payrollIndex) => (
          <div key={payrollIndex}>
            <Card className="mb-5 border-gray-400">
              <Row
                gutter={16}
                align="bottom"
                justify="space-between"
                wrap={false}
              >
                <Col span={22}>
                  <Form.Item label="City" className="m-0 p-0">
                    <Select
                      mode="multiple"
                      placeholder="Select Cities"
                      value={payroll.cityIds}
                      onChange={(value) =>
                        handleCityIdsChange(value, payrollIndex)
                      }
                      className="w-full"
                      options={cities.map((c) => ({
                        label: c.name,
                        value: c.id,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col className="w-fit">
                  <Button
                    danger
                    onClick={() => {
                      removePayrollPreference(payrollIndex);
                    }}
                  >
                    <AiOutlineDelete size={16} />
                  </Button>
                </Col>
              </Row>

              {payroll.preference.map((pref, prefIndex) => (
                <Card key={prefIndex} className="mt-5">
                  <div className="flex flex-row justify-end mb-2">
                    <Button
                      danger
                      onClick={() => {
                        removePreference(payrollIndex, prefIndex);
                      }}
                    >
                      <AiOutlineDelete size={16} />
                    </Button>
                  </div>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Vehicle">
                        <Select
                          mode="multiple"
                          placeholder="Select Vehicles"
                          value={pref.vehicleTypeIds}
                          onChange={(value) =>
                            handleVehicleTypeIdsChange(
                              payrollIndex,
                              prefIndex,
                              value
                            )
                          }
                          className="w-full"
                          options={vehicles.map((v) => ({
                            label: v.name,
                            value: v.id,
                          }))}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Km Pay">
                        <InputNumber
                          placeholder="Km Pay"
                          value={pref.kmPay}
                          onChange={(value) =>
                            handleInputChange(
                              payrollIndex,
                              prefIndex,
                              "kmPay",
                              value
                            )
                          }
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Km Pay Min">
                        <InputNumber
                          placeholder="Km Pay Min"
                          value={pref.kmPayMin}
                          onChange={(value) =>
                            handleInputChange(
                              payrollIndex,
                              prefIndex,
                              "kmPayMin",
                              value
                            )
                          }
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Security Deposit/Month">
                        <InputNumber
                          placeholder="Security Deposit"
                          value={pref.securityDepositPerMonth}
                          onChange={(value) =>
                            handleInputChange(
                              payrollIndex,
                              prefIndex,
                              "securityDepositPerMonth",
                              value
                            )
                          }
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Security Deposit Salary Min">
                        <InputNumber
                          placeholder="Security Deposit Salary Min"
                          value={pref.securityDepositSalaryMin}
                          onChange={(value) =>
                            handleInputChange(
                              payrollIndex,
                              prefIndex,
                              "securityDepositSalaryMin",
                              value
                            )
                          }
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Security Deposit Target">
                        <InputNumber
                          placeholder="Security Deposit Target"
                          value={pref.securityDepositTarget}
                          onChange={(value) =>
                            handleInputChange(
                              payrollIndex,
                              prefIndex,
                              "securityDepositTarget",
                              value
                            )
                          }
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Service Fee">
                        <InputNumber
                          placeholder="Service Fee"
                          value={pref.serviceFee}
                          onChange={(value) =>
                            handleInputChange(
                              payrollIndex,
                              prefIndex,
                              "serviceFee",
                              value
                            )
                          }
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Max COD Diff">
                        <InputNumber
                          placeholder="Max COD Diff"
                          value={pref.maxCodDiff}
                          onChange={(value) =>
                            handleInputChange(
                              payrollIndex,
                              prefIndex,
                              "maxCodDiff",
                              value
                            )
                          }
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Min COD Payoff">
                        <InputNumber
                          placeholder="Min COD Payoff"
                          value={pref.minCodPayOff}
                          onChange={(value) =>
                            handleInputChange(
                              payrollIndex,
                              prefIndex,
                              "minCodPayOff",
                              value
                            )
                          }
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="Late Shift Penalty">
                        <InputNumber
                          placeholder="Late Shift Penalty"
                          value={pref.lateShiftPenalty}
                          onChange={(value) =>
                            handleInputChange(
                              payrollIndex,
                              prefIndex,
                              "lateShiftPenalty",
                              value
                            )
                          }
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Max Late Shift Penalty">
                        <InputNumber
                          placeholder="Max Late Shift Penalty"
                          value={pref.maxLateShiftPenalty}
                          onChange={(value) =>
                            handleInputChange(
                              payrollIndex,
                              prefIndex,
                              "maxLateShiftPenalty",
                              value
                            )
                          }
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {selectedBatches[payrollIndex] &&
                    selectedBatches[payrollIndex][prefIndex]?.map(
                      (batchKey) => (
                        <div key={batchKey}>
                          <Row
                            gutter={16}
                            justify="space-between"
                            className="font-semibold mb-1 mt-3"
                          >
                            <Col className="w-fit">{`Batch ${batchKey}`}</Col>
                            <Col className="w-fit">
                              <Button
                                danger
                                onClick={() => {
                                  removeBatchPreference(
                                    payrollIndex,
                                    prefIndex,
                                    batchKey
                                  );
                                }}
                              >
                                <AiOutlineDelete size={16} />
                              </Button>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={8}>
                              <Form.Item label={`Pick Up`}>
                                <InputNumber
                                  placeholder="Pick Up"
                                  value={pref.batch[batchKey]?.pu}
                                  onChange={(value) =>
                                    handleBatchChange(
                                      payrollIndex,
                                      prefIndex,
                                      batchKey,
                                      "pu",
                                      value
                                    )
                                  }
                                  className="w-full"
                                />
                              </Form.Item>
                            </Col>

                            <Col span={8}>
                              <Form.Item label={`Drop Off`}>
                                <InputNumber
                                  placeholder="Drop Off"
                                  value={pref.batch[batchKey]?.do}
                                  onChange={(value) =>
                                    handleBatchChange(
                                      payrollIndex,
                                      prefIndex,
                                      batchKey,
                                      "do",
                                      value
                                    )
                                  }
                                  className="w-full"
                                />
                              </Form.Item>
                            </Col>

                            <Col span={8}>
                              <Form.Item label={`Delivery`}>
                                <InputNumber
                                  placeholder="Delivery"
                                  value={pref.batch[batchKey]?.delivery}
                                  onChange={(value) =>
                                    handleBatchChange(
                                      payrollIndex,
                                      prefIndex,
                                      batchKey,
                                      "delivery",
                                      value
                                    )
                                  }
                                  className="w-full"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      )
                    )}
                  <Row gutter={16}>
                    <Col span={24}>
                      <Button
                        onClick={() =>
                          setShowAddBatch({
                            show: true,
                            payroll: payrollIndex,
                            pref: prefIndex,
                          })
                        }
                        block
                        className="flex justify-center items-center gap-2 mb-2"
                      >
                        <AiOutlinePlus size={16} /> Batch
                      </Button>
                    </Col>
                  </Row>
                </Card>
              ))}

              <Button
                className="flex justify-center items-center gap-2 my-5"
                type="dashed"
                onClick={() => addPreference(payrollIndex)}
                block
              >
                <AiOutlinePlus size={16} /> Preference
              </Button>
            </Card>
            {payrollIndex < payrollPreferences.length - 1 && <Divider />}
          </div>
        ))}
      </Form>
      <Button
        htmlType="button"
        type="primary"
        className="fixed bottom-6 right-12 btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary"
        onClick={() => {
          const requiredBatches = [1, 2, 3, 4, 5, 6, 7];
          const allCityIds: number[] = [];
          const vehicleIdGroup: Record<string, number[]> = {};
          const batchesIdGroup: Record<string, Record<string, number[]>> = {};

          payrollPreferences.forEach((preference, index) => {
            allCityIds.push(...preference.cityIds);
            preference.preference.forEach((p, prefIndex) => {
              if (!vehicleIdGroup[index]) {
                vehicleIdGroup[index] = [];
              }
              vehicleIdGroup[index].push(...p.vehicleTypeIds);

              if (!batchesIdGroup[index]) {
                batchesIdGroup[index] = {};
                if (!batchesIdGroup[index][prefIndex]) {
                  batchesIdGroup[index] = { [prefIndex]: [] };
                }
              }
              batchesIdGroup[index][prefIndex] = Object.keys(p.batch).map(
                (k) => +k
              );
            });
          });

          if (cities.length !== allCityIds.length) {
            const missedCitites = cities
              .filter((c) => !allCityIds.includes(c.id))
              .map((c) => c.name);

            toast.warn(
              `Prefernce should be assigned or added to all cities, ${missedCitites.join(", ") + " is missing"}`
            );
            return; // Stop function execution
          }

          for (const key of Object.keys(vehicleIdGroup)) {
            if (vehicleIdGroup[key as string].length !== vehicles.length) {
              const missedVehicles = vehicles
                .filter((v) => !vehicleIdGroup[key as string].includes(v.id))
                .map((v) => v.name);
              const payrollOrder = +key + 1;
              toast.warn(
                `${
                  payrollOrder == 1
                    ? "1st"
                    : payrollOrder == 2
                      ? "2nd"
                      : payrollOrder + "th"
                } payroll preferences should contain all vehicles, ${missedVehicles.join(
                  ", "
                )} is missing`
              );
              return; // Stop function execution
            }
          }

          for (const key of Object.keys(batchesIdGroup)) {
            for (const pKey of Object.keys(batchesIdGroup[key])) {
              const missedBatchs = requiredBatches
                .filter((b) => !batchesIdGroup[key as string][pKey].includes(b))
                .map((b) => b);

              if (missedBatchs.length > 0) {
                const payrollOrder = +key + 1;
                toast.warn(
                  `${payrollOrder == 1 ? "1st" : payrollOrder == 2 ? "2nd" : payrollOrder + "th"} payroll preference ${
                    +pKey + 1
                  } batch 1 to 7 is required, ${missedBatchs.join(", ")} is missing`
                );
                return; // Break both loops and stop function execution
              }
            }
          }

          fetch({
            url: "/admin/preference/payroll",
            method: "PUT",
            payload: payrollPreferences,
            onSuccess: () => {
              refetch();
              toast.success("Payroll settings saved successfully");
            },
          });
        }}
        loading={isLoadingSubmitForm}
      >
        <FaSave size={20} />
        <div>Save</div>
      </Button>
      <Button
        className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary"
        type="primary"
        onClick={addPayrollPreference}
      >
        <AiOutlinePlus size={16} /> Payroll Preference
      </Button>
    </div>
  );
};

export default PayrollPreferencesPage;
