import moment from "moment-timezone";

export const separateNumbersWithCommas = (number: number | string) => {
  if (number == 0) {
    return number;
  }
  const formatNumber = Number(number);
  return new Intl.NumberFormat().format(formatNumber);
};

export const convertDecimalHoursToReadable = (decimalHours: number): string => {
  if (decimalHours === 0) {
    return "0";
  }
  const totalMinutes = Math.floor(decimalHours * 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const seconds = Math.floor((decimalHours * 3600) % 60);

  let readableTime = "";
  if (hours > 0) {
    readableTime += `${hours}h `;
    // ${hours > 1 ? "s" : ""}
  }
  if (minutes > 0) {
    // if (readableTime.length > 0) readableTime += " and ";
    readableTime += ` ${minutes}m `;
  }
  if (seconds > 0) {
    // if (readableTime.length > 0) readableTime += " and ";
    readableTime += ` ${seconds}s`;
  }
  return `${readableTime}`;
};

export const findDifferentExactTime = (val: Date) => {
  const duration = moment.duration(moment().diff(val));
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  let timeAgoText = "";
  if (years > 0) {
    timeAgoText += `${years} year${years > 1 ? "s" : ""}`;
  }
  if (months > 0) {
    timeAgoText += ` ${months} month${months > 1 ? "s" : ""}`;
  }
  if (days > 0) {
    timeAgoText += ` ${days} day${days > 1 ? "s" : ""}`;
  }

  return timeAgoText.trim().length > 0 ? `${timeAgoText} ago` : "Just now";
};
