import React, { useRef, useState } from "react";
import { Tabs, Tour, TourProps } from "antd";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useUser from "../../../store/User";
import useAxios from "../../../hooks/useAxios";
import { UserSettings } from "../../../models/user";

const RidersLayout = () => {
  const tabsRef = useRef(null);
  const { t } = useTranslation();
  const { userSettings, setUserSettings } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetch } = useAxios({ disabledStates: true });
  const { user } = useUser();

  const [tourOpen, setTourOpen] = useState<{
    show: boolean;
    steps: TourProps["steps"];
  }>({
    show: true,
    steps: [
      {
        title: t("new-riders-styles"),
        description: t("navigate-riders-message"),
        target: () => tabsRef.current,
      },
    ],
  });
  const lastURLTab = location.pathname.split("/").pop();
  const activeTab = lastURLTab === "riders" ? "list" : lastURLTab;

  const onTabChange = (key: string) => {
    // if (key === "hiring-process") {
    //   navigate(`/hiring-process`);
    //   return;
    // }
    navigate(`/riders/${key}`);
  };

  const items = [
    { label: t("list"), key: "list" },
    { label: t("tags"), key: "tags" },
    { label: t("rider-feedbacks"), key: "rider-feedbacks" },
    {
      label: t("remove-suspension"),
      key: "remove-suspension",
      hidden: user?.cityID != 1,
    },
  ];

  const filteredItems = items.filter((it) => !it.hidden);

  return (
    <div>
      <div ref={tabsRef}>
        <Tabs
          activeKey={activeTab}
          onChange={onTabChange}
          items={filteredItems}
          type="card"
        />
      </div>
      {userSettings && (
        <Tour
          open={!userSettings?.tour?.ridersTab && tourOpen.show}
          onClose={() => setTourOpen({ show: false, steps: [] })}
          steps={tourOpen.steps}
          closable={false}
          onFinish={() => {
            fetch<{ setting: UserSettings }>({
              url: "/account/setting",
              method: "PUT",
              payload: {
                ...userSettings,
                tour: {
                  ...userSettings?.tour,
                  ridersTab: true,
                },
              },
              onSuccess: (res) => {
                setUserSettings(res?.data?.setting || {});
              },
            });
          }}
        />
      )}
      <Outlet />
    </div>
  );
};

export default React.memo(RidersLayout);
