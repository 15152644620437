import { Button, Modal } from "antd";
import useFetch from "../../../../hooks/useFetch";
import { VehicleListRes, VehicleList } from "../../../../models/super-panel";
import Table from "../../../UI/Table";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import VehicleTypeForm from "./VehicleTypeForm";
import { useTranslation } from "react-i18next";
import useVehicleTypeColumns from "./hooks/useVehicleTypeColumns";

interface VehicleTypeProps {
  show: boolean;
  onHide: () => void;
}

const VehicleType = ({ show, onHide }: VehicleTypeProps) => {
  const { t } = useTranslation();
  const [showVehicleForm, setShowVehicleForm] = useState<{
    show: boolean;
    data?: VehicleList;
  }>({
    show: false,
  });

  const { data, isLoading, refetch } = useFetch<VehicleListRes>({
    url: "/lists/vehicles",
    method: "GET",
    disabled: !show,
  });

  const { columns } = useVehicleTypeColumns({
    setShowVehicleForm,
    refetch,
  });

  return (
    <Modal
      title={t("vehicles-list")}
      footer={null}
      onCancel={() => {
        onHide();
      }}
      centered
      open={show}
      width="800px"
    >
      <VehicleTypeForm
        show={showVehicleForm.show}
        data={showVehicleForm.data}
        onHide={() =>
          setShowVehicleForm({
            show: false,
          })
        }
        refetch={refetch}
      />
      <Button
        type="primary"
        className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary mb-2 mt-4"
        onClick={() => setShowVehicleForm({ show: true })}
      >
        <AiOutlinePlus size={20} /> {t("add-vehicle")}
      </Button>
      <Table
        loading={isLoading || false}
        columns={columns}
        data={data?.data || []}
        scrollYH="calc(100vh - 300px)"
      />
    </Modal>
  );
};

export default VehicleType;
