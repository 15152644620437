import { Button } from "antd";
import EmailCard from "./EmailCard";
import { IoAddOutline } from "react-icons/io5";
import { useState } from "react";
import { toast } from "react-toastify";
import { FaSave } from "react-icons/fa";
import {
  RiderEmailCardType,
  RiderEmailPreferencesRes,
} from "../../../../models/super-panel";
import Loading from "../../../common/Loading";
import useFetch from "../../../../hooks/useFetch";
import useAxios from "../../../../hooks/useAxios";
import SelectCityModal from "./SelectCityModal";
import { convertRiderSettingsToEmailCards } from "../../../../utils/preferences";
import useListsReq from "../../../../hooks/useListsReq";
import { useTranslation } from "react-i18next";

const RiderSettings = () => {
  const { t } = useTranslation();
  const { cities } = useListsReq({
    requestLists: [{ type: "cities" }],
  });

  const [showSelectCityModal, setShowSelectCityModal] = useState(false);
  const [emailCards, setEmailCards] = useState<RiderEmailCardType[]>([]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { fetch, isLoading: isLoadingSubmitForm } = useAxios({
    disabledStates: false,
  });

  const { isLoading, refetch } = useFetch<{
    preference: RiderEmailPreferencesRes;
  }>({
    url: "/admin/preference/rider",
    method: "GET",
    onSuccess: async (res) => {
      const setting = res?.data?.preference?.setting || [];
      const convertedRiderSettings = convertRiderSettingsToEmailCards(
        setting,
        cities
      );
      setEmailCards(convertedRiderSettings);
    },
  });

  if (isLoading || isSubmiting) {
    return <Loading />;
  }

  return (
    <div className="mb-10">
      <SelectCityModal
        onHide={() => setShowSelectCityModal(false)}
        show={showSelectCityModal}
        selectedCityIDs={emailCards.map((e) => e.selectedCity.cityID)}
        setEmailCards={setEmailCards}
      />
      <Button
        htmlType="button"
        type="primary"
        className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary mb-5"
        onClick={() => {
          setShowSelectCityModal(true);
        }}
      >
        <IoAddOutline size={20} />
        <div>{t("new")}</div>
      </Button>
      <div className="flex flex-col gap-5">
        {emailCards.map((card) => (
          <EmailCard
            key={card.id}
            emails={card.emails}
            city={card.selectedCity.city}
            name={card.name}
            onRemoveCard={() => {
              setEmailCards((prev) => prev.filter((c) => c.id !== card.id));
            }}
            onChangeName={(value) => {
              setEmailCards((prev) =>
                prev.map((c) => {
                  if (c.id === card.id) {
                    return {
                      ...c,
                      name: value,
                    };
                  }
                  return c;
                })
              );
            }}
            onAddNewEmail={(label) => {
              setEmailCards((prev) =>
                prev.map((c) => {
                  if (card.id === c.id) {
                    return {
                      ...card,
                      emails: {
                        ...card.emails,
                        [label]: [
                          ...card.emails[label],
                          {
                            id: `email ${Date.now() + "" + Math.random()}`,
                            value: "",
                          },
                        ],
                      },
                    };
                  }
                  return c;
                })
              );
            }}
            onRemoveEmail={(label, id) => {
              setEmailCards((prev) =>
                prev.map((c) => {
                  if (c.id === card.id) {
                    return {
                      ...c,
                      emails: {
                        ...c.emails,
                        [label]: c.emails[label].filter(
                          (email) => email.id !== id
                        ),
                      },
                    };
                  }
                  return c;
                })
              );
            }}
            onEmailValueChange={(label, id, value) => {
              setEmailCards((prev) =>
                prev.map((c) => {
                  if (c.id === card.id) {
                    return {
                      ...c,
                      emails: {
                        ...c.emails,
                        [label]: c.emails[label].map((email) => {
                          if (email.id === id) {
                            return { ...email, value: value };
                          }
                          return email;
                        }),
                      },
                    };
                  }
                  return c;
                })
              );
            }}
          />
        ))}
      </div>
      <Button
        htmlType="button"
        type="primary"
        className="fixed bottom-6 right-12 btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary"
        onClick={() => {
          setIsSubmiting(true);

          fetch({
            url: "/admin/preference/rider",
            method: "PUT",
            payload: emailCards.map((ec) => {
              return {
                name: ec.name,
                cityID: ec.selectedCity.cityID,
                admin: {
                  to: ec.emails.adminToInputs.map((em) => em.value),
                  cc: ec.emails.adminCcInputs.map((em) => em.value),
                },
                terminate: {
                  to: ec.emails.terminateToInputs.map((em) => em.value),
                  cc: ec.emails.terminateCcInputs.map((em) => em.value),
                },
                freeze: {
                  to: ec.emails.freezeToInputs.map((em) => em.value),
                  cc: ec.emails.freezeCcInputs.map((em) => em.value),
                },
                changeData: {
                  to: ec.emails.changeDataToInputs.map((em) => em.value),
                  cc: ec.emails.changeDataCcInputs.map((em) => em.value),
                },
              };
            }),
            onSuccess: () => {
              refetch();
              toast.success("Rider Preferences Saved Successfully");
            },
            onEnd: () => {
              setIsSubmiting(false);
            },
          });
        }}
        loading={isLoadingSubmitForm}
      >
        <FaSave size={20} />
        <div>{t("save")}</div>
      </Button>
    </div>
  );
};

export default RiderSettings;
