import { Tabs } from "antd";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { memo } from "react";

export default memo(function PayrollLayout() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const lastURLTab = location.pathname.split("/").pop();
  const activeTab = lastURLTab === "payroll" ? "list" : lastURLTab;

  const onTabChange = (key: string) => {
    navigate(`/finance/payroll/${key}`);
  };

  const items = [
    { label: t("list"), key: "list" },
    { label: t("reviews"), key: "reviews" },
  ];

  return (
    <div>
      <Tabs
        activeKey={activeTab}
        onChange={onTabChange}
        items={items}
        type="card"
      />

      <Outlet />
    </div>
  );
});
