import React, { useState } from "react";
import { Select, Button, Row, Col } from "antd";
import { ListTypeRes } from "../../../../models/super-panel";
import { Access, AccessType, Actions } from "../../../../models/user";
import { accessTypeOptions, actionOptions } from "../../../../data/mockData";
import { filterOption } from "../../../../utils/antdUtils";
import { PiTrashSimple } from "react-icons/pi";
import { useTranslation } from "react-i18next";

interface AccessComponentProps {
  sectionIndex: number;
  accessIndex: number;
  accessData: Access;
  onUpdateAccess: (
    sectionIndex: number,
    accessIndex: number,
    updatedAccess: Access
  ) => void;
  onRemoveAccess: (sectionIndex: number, accessIndex: number) => void;
  cities: ListTypeRes;
  accountNames: { id: number; name: string }[];
}

const AccessComponent: React.FC<AccessComponentProps> = ({
  sectionIndex,
  accessIndex,
  accessData,
  onUpdateAccess,
  onRemoveAccess,
  cities,
  accountNames,
}) => {
  const { t } = useTranslation();
  const [action, setAction] = useState<Actions>(accessData[0]);
  const [accessType, setAccessType] = useState<AccessType>(accessData[1]);
  const [numberArray, setNumberArray] = useState<number[] | null>(
    accessData[2]
  );

  const handleActionChange = (value: Actions) => {
    setAction(value);
    onUpdateAccess(sectionIndex, accessIndex, [value, accessType, numberArray]);
  };

  const handleAccessTypeChange = (value: AccessType) => {
    setAccessType(value);
    setNumberArray(null); // Reset numberArray to null
    onUpdateAccess(sectionIndex, accessIndex, [action, value, null]);
  };

  const handleNumberArrayChange = (value: number[]) => {
    setNumberArray(value.length ? value : null);
    onUpdateAccess(sectionIndex, accessIndex, [
      action,
      accessType,
      value.length ? value : null,
    ]);
  };

  return (
    <Row gutter={16} className="mb-2">
      <Col span={6}>
        <Select
          value={action}
          onChange={handleActionChange}
          options={actionOptions(t)}
          className="w-full"
        />
      </Col>
      <Col span={6}>
        <Select
          value={accessType}
          onChange={handleAccessTypeChange}
          options={accessTypeOptions(t)}
          className="w-full"
        />
      </Col>
      <Col span={10}>
        {(accessType === "city-group" || accessType === "access-group") && (
          <Select
            showSearch
            options={
              (accessType === "city-group" ? cities : accountNames)?.map(
                (c) => ({
                  label: c.name,
                  value: c.id,
                })
              ) || []
            }
            filterOption={filterOption}
            onChange={handleNumberArrayChange}
            mode="multiple"
            value={numberArray || []}
            className="w-full"
          />
        )}
      </Col>
      <Col span={2}>
        <Button
          danger
          onClick={() => onRemoveAccess(sectionIndex, accessIndex)}
        >
          <PiTrashSimple size={16} />
        </Button>
      </Col>
    </Row>
  );
};

export default AccessComponent;
