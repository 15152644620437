import { Avatar, Tooltip } from "antd";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { GoPerson } from "react-icons/go";
import { LiaUserSlashSolid } from "react-icons/lia";

interface AccountProfilePicIconProps {
  onClick?: (e?: MouseEvent) => void;
  imageURL?: string;
  title?: string;
  showImage?: boolean;
  size?: number;
  abv?: string;
}

const AccountProfilePicIcon = ({
  onClick,
  imageURL,
  title = "",
  showImage = false,
  size = 36,
  abv,
}: AccountProfilePicIconProps) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={
        title
          ? title
          : !imageURL
            ? t("rider-profile-picture-is-not-uploaded")
            : ""
      }
    >
      <div className="rounded-full">
        <Avatar
          className={"cursor-pointer bg-[#356385]"}
          size={size}
          onClick={onClick}
          icon={
            abv ? (
              <span className="text-[#F4D160]">{abv}</span>
            ) : imageURL ? (
              <GoPerson size={18} />
            ) : (
              <LiaUserSlashSolid size={18} />
            )
          }
          src={showImage && import.meta.env.VITE_API_URL + "/" + imageURL}
          crossOrigin="use-credentials"
        />
      </div>
    </Tooltip>
  );
};

export default AccountProfilePicIcon;
