import { Button, Popconfirm, TableProps } from "antd";
import { useTranslation } from "react-i18next";
import { FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";
import { Dispatch, SetStateAction } from "react";
import { Role } from "../../../../../models/super-panel";
import useAxios from "../../../../../hooks/useAxios";

interface useRolesColumnsProps {
  setShowDrawer: Dispatch<
    SetStateAction<{
      show: boolean;
      data: Role | null;
    }>
  >;
  refetch: () => void;
}
export default function useRolesColumns({
  setShowDrawer,
  refetch,
}: useRolesColumnsProps) {
  const { t } = useTranslation();
  const { fetch } = useAxios({ disabledStates: true });

  const columns: TableProps<Role>["columns"] = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("description"),
      dataIndex: "description",
    },
    {
      title: t("linked-accounts"),
      dataIndex: "linkCount",
    },
    {
      title: t("actions"),
      key: "action",
      fixed: "right",
      width: "110px",
      render: (row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-1">
            <Button
              className="flex items-center px-3 py-2"
              onClick={() => {
                setShowDrawer({ show: true, data: row });
              }}
            >
              <FaRegEdit size={14} />
            </Button>
            <Popconfirm
              placement="bottomLeft"
              title={t("delete-role")}
              description={t("delete-confirm-message")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={async () => {
                fetch({
                  url: `/admin/authorization/${row.id}`,
                  method: "DELETE",
                  onSuccess: () => {
                    refetch();
                    toast.success(t("role-deleted-successfully"));
                  },
                });
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button danger className="flex items-center px-3 py-2">
                <AiOutlineDelete size={14} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return { columns };
}
