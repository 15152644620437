import { Button, Card, Modal, Tooltip, Typography } from "antd";
import useFetch from "../../hooks/useFetch";
import Table from "../UI/Table";
import { LinkedRecordsRes, Payroll } from "../../models/finance";
import { useTranslation } from "react-i18next";
import { separateNumbersWithCommas } from "../../utils/formatNumbers";
import { useState } from "react";
import useLinkedRecordsModalColumns from "./hooks/useLinkedRecordsModalColumns";
import LinkRecordModal from "./LinkRecordModal";
import { AiOutlinePlus } from "react-icons/ai";

interface LinkedRecordsModalProps {
  show: boolean;
  onHide: () => void;
  payroll?: Payroll;
}

export default function LinkedRecordsModal({
  payroll,
  show,
  onHide,
}: LinkedRecordsModalProps) {
  const { t } = useTranslation();

  const { Text, Title } = Typography;

  const [showLinkRecordModal, setShowLinkRecordModal] = useState(false);

  const { data, isLoading, refetch } = useFetch<LinkedRecordsRes>({
    url: payroll ? `/admin/finance/payroll/review/${payroll.id}` : "",
    method: "GET",
    isLoadingDefault: true,
    disabled: !show,
  });

  const diff = Number(data?.cod?.diff || 0);
  const openingBalance = Number(data?.cod?.opningBalance || 0);
  const riderShare = Number(payroll?.riderShare || 0);
  const totalRecordsAmount =
    data?.recordList?.reduce((acc, record) => acc + Number(record.amount), 0) ||
    0;

  const total = diff + openingBalance + totalRecordsAmount + riderShare;

  const { columns } = useLinkedRecordsModalColumns({
    isUnlink: true,
    payrollId: payroll?.id,
    refetch,
  });

  return (
    <>
      <LinkRecordModal
        onHide={() => setShowLinkRecordModal(false)}
        show={showLinkRecordModal}
        payroll={payroll}
        refetch={refetch}
      />
      <Modal
        open={show}
        footer={null}
        onCancel={onHide}
        title={`Linked Records for: ${payroll?.fullName} (${payroll?.partnerID})`}
        centered
        styles={{
          body: { maxHeight: "90vh", overflowY: "auto", padding: "0 10px" },
        }}
        width={"90%"}
        maskClosable={false}
        destroyOnClose
      >
        <div className="my-6">
          <div className="flex justify-center">
            <Card className="shadow-lg flex flex-col items-centerrounded-lg p-2">
              <Title level={4} className="text-red-800 mb-2">
                {t("total")}
              </Title>
              <Text strong className={`text-3xl font-bold `}>
                {total ? `${separateNumbersWithCommas(total)} IQD` : "N/A"}
              </Text>
            </Card>
          </div>
          <div className="flex mt-6 flex-row gap-10 justify-center">
            <Card className="shadow-lg flex flex-col items-center rounded-lg p-2">
              <Title level={4} className="text-yellow-800 mb-2">
                {t("opening-balance")}
              </Title>
              <Text strong className={`text-2xl font-bold`}>
                {openingBalance
                  ? `${separateNumbersWithCommas(openingBalance)} IQD`
                  : "N/A"}
              </Text>
            </Card>

            <Card className="shadow-lg flex flex-col items-center rounded-lg p-2">
              <Title level={4} className="mb-2">
                {t("difference")}
              </Title>
              <Text strong className={`text-2xl font-bold`}>
                {diff ? `${separateNumbersWithCommas(diff)} IQD` : "N/A"}
              </Text>
            </Card>
            <Card className="shadow-lg flex flex-col items-center rounded-lg p-2">
              <Title level={4} className="mb-2">
                {t("total-records-amount")}
              </Title>
              <Text strong className={`text-2xl font-bold`}>
                {totalRecordsAmount
                  ? `${separateNumbersWithCommas(totalRecordsAmount)} IQD`
                  : "N/A"}
              </Text>
            </Card>
            <Card className="shadow-lg flex flex-col items-center rounded-lg p-2">
              <Title level={4} className="mb-2">
                {t("rider-share")}
              </Title>
              <Text strong className={`text-2xl font-bold`}>
                {riderShare
                  ? `${separateNumbersWithCommas(riderShare)} IQD`
                  : "N/A"}
              </Text>
            </Card>
          </div>
        </div>
        <Table
          actionButton={{
            component: (
              <Tooltip title={t("link-more-records")}>
                <Button
                  block
                  className="btn-height-40 items-center rounded-border-xl-cstm"
                  onClick={() => setShowLinkRecordModal(true)}
                  icon={<AiOutlinePlus size={16} />}
                />
              </Tooltip>
            ),
          }}
          columns={columns}
          data={data?.recordList || []}
          loading={isLoading}
          pagination={false}
          refetch={refetch}
          total={data?.recordList?.length}
        />
      </Modal>
    </>
  );
}
