import { Button, Popconfirm, TableProps } from "antd";
import { LinkedRecord } from "../../../models/finance";
import ClickToCopy from "../../UI/ClickToCopy";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hooks/useAxios";
import { toast } from "react-toastify";
import { separateNumbersWithCommas } from "../../../utils/formatNumbers";

interface useLinkedRecordsModalColumnsProps {
  isUnlink: boolean;
  payrollId: number | undefined;
  refetch: () => void;
  linkRefetch?: () => void;
}
export default function useLinkedRecordsModalColumns({
  isUnlink,
  payrollId,
  refetch,
  linkRefetch,
}: useLinkedRecordsModalColumnsProps) {
  const { t } = useTranslation();
  const { fetch } = useAxios({ disabledStates: true });
  const columns: TableProps<LinkedRecord>["columns"] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (val) => <ClickToCopy text={val}>{val}</ClickToCopy>,
    },
    {
      title: "Account ID",
      dataIndex: "accountId",
      key: "accountId",
      render: (val) => <ClickToCopy text={val}>{val}</ClickToCopy>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (val) => separateNumbersWithCommas(val),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (val) => <ClickToCopy text={val}>{val}</ClickToCopy>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (val) => moment(val).format("MMM DD, YYYY"),
    },
    {
      title: t("actions"),
      key: "action",
      fixed: "right",
      width: "100px",
      render: (_, row) => {
        return (
          <Popconfirm
            placement="bottomLeft"
            title={isUnlink ? t("unlink-record") : t("link-record")}
            description={
              isUnlink
                ? t("unlink-record-confirm-message")
                : t("link-record-confirm-message")
            }
            okText={t("yes")}
            cancelText={t("no")}
            onConfirm={async () => {
              if (!payrollId) return;
              fetch({
                url: `/admin/finance/payroll/${payrollId}/record/${isUnlink ? "unlink" : "link"}/${row.id}`,
                method: "PUT",
                payload: {},
                onSuccess: () => {
                  refetch();
                  linkRefetch && linkRefetch();
                  toast.success(
                    isUnlink
                      ? t("record-unlink-successfully")
                      : t("record-link-successfully")
                  );
                },
              });
            }}
            okButtonProps={{ className: "bg-primary" }}
          >
            <Button className="flex items-center px-3 py-2">
              {isUnlink ? t("unlink") : t("link")}
            </Button>
          </Popconfirm>
        );
      },
    },
  ];
  return { columns };
}
