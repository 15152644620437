import { TFunction } from "i18next";
import { DayAbbreviation } from "../models/super-panel";

interface Option {
  value: number | string;
  label: string;
}

export const cities: Option[] = [
  {
    value: 1,
    label: "Erbil",
  },
  {
    value: 2,
    label: "Sulaymaniyah",
  },
  {
    value: 3,
    label: "Baghdad",
  },
];

export function riderStatus(t: TFunction): Option[] {
  return [
    { label: t("ready"), value: "ready" },
    { label: t("working"), value: "working" },
    { label: t("on-leave"), value: "on leave" },
    { label: t("freeze"), value: "frozen" },
    { label: t("terminated"), value: "terminated" },
    { label: t("resign"), value: "resign" },
    { label: t("suspended"), value: "suspended" },
  ];
}

export function vehicleTypes(t: TFunction): Option[] {
  return [
    {
      value: "Car",
      label: t("car"),
    },
    {
      value: "Bike",
      label: t("bike"),
    },
    {
      value: "Scooter",
      label: t("scooter"),
    },
  ];
}

export function genders(t: TFunction): Option[] {
  return [
    {
      value: "male",
      label: t("male"),
    },
    {
      value: "female",
      label: t("female"),
    },
  ];
}

export function accountTypes(t: TFunction): Option[] {
  return [
    {
      value: "rider",
      label: t("rider"),
    },
    {
      value: "captain",
      label: t("captain"),
    },
    {
      value: "admin",
      label: t("admin"),
    },
    {
      value: "super",
      label: t("super"),
    },
  ];
}

export function leaveReasons(t: TFunction): Option[] {
  return [
    {
      value: "travel",
      label: t("travel"),
    },
    {
      value: "accident",
      label: t("accident"),
    },
    {
      value: "illness",
      label: t("illness"),
    },
    {
      value: "repair",
      label: t("repair"),
    },
    {
      value: "other",
      label: t("other"),
    },
  ];
}

export const mapDays: Record<DayAbbreviation, string> = {
  fr: "Fri",
  mo: "Mon",
  su: "Sun",
  sa: "Sat",
  tu: "Tue",
  we: "Wed",
  th: "Thu",
};

export function riderLeaveStatus(t: TFunction): Option[] {
  return [
    { label: t("pending"), value: "pending" },
    { label: t("approved"), value: "approved" },
    { label: t("rejected"), value: "rejected" },
    { label: t("sent"), value: "sent" },
    { label: t("partner-approved"), value: "partner approved" },
    { label: t("partner-rejected"), value: "partner rejected" },
    { label: t("completed"), value: "completed" },
    { label: t("abuse"), value: "abuse" },
    { label: t("canceled-by-rider"), value: "canceled" },
    { label: t("cancellation-request"), value: "Cancellation Request" },
  ];
}

export function allAccountTypes(t: TFunction): Option[] {
  return [
    { label: t("super"), value: "super" },
    { label: t("admin"), value: "admin" },
    { label: t("rider"), value: "rider" },
    { label: t("captain"), value: "captain" },
    { label: t("system"), value: "system" },
  ];
}

export function accountStatusTypes(t: TFunction): Option[] {
  return [
    { label: t("active"), value: "active" },
    { label: t("pending"), value: "pending" },
    { label: t("rejected"), value: "rejected" },
    { label: t("draft"), value: "draft" },
    { label: t("freeze"), value: "frozen" },
    { label: t("archived"), value: "archived" },
  ];
}

export function recordStatusTypes(t: TFunction): Option[] {
  return [
    {
      label: t("pending"),
      value: "pending",
    },
    {
      label: t("completed"),
      value: "completed",
    },
    {
      label: t("cancelled"),
      value: "cancelled",
    },
    {
      label: t("refunded"),
      value: "refunded",
    },
    {
      label: t("under-review"),
      value: "under-review",
    },
  ];
}

export function actionOptions(t: TFunction): Option[] {
  return [
    { label: t("add"), value: "add" },
    { label: t("edit"), value: "edit" },
    { label: t("delete"), value: "delete" },
    { label: t("extra"), value: "extra" },
  ];
}

export function accessTypeOptions(t: TFunction): Option[] {
  return [
    { label: t("own-data"), value: "own-data" },
    { label: t("own-city"), value: "own-city" },
    { label: t("city-group"), value: "city-group" },
    { label: t("all-cities"), value: "all-cities" },
    { label: t("access-group"), value: "access-group" },
  ];
}

export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const phoneNumberRegex = /^9647\d{9}$/;
export const phoneNumberRegexWithoutCode = /^0?7\d{9}$/;
export const otpRegex = /^\d{6}$/;
export const urlRegex =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const englishAlphabeticCharacterRegex = /^[a-zA-Z\s]+$/;
