import { useState } from "react";
import Table from "../../../UI/Table";
import useFetch from "../../../../hooks/useFetch";
import { Role, RolesList } from "../../../../models/super-panel";
import Drawer from "../../../UI/Drawer";
import AuthorizationComponent from "./AuthorizationComponent";
import { useTranslation } from "react-i18next";
import useRolesColumns from "./hooks/useRolesColumns";

const Roles = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState({
    name: "",
  });
  const [showDrawer, setShowDrawer] = useState<{
    show: boolean;
    data: Role | null;
  }>({ show: false, data: null });

  const { data, isLoading, refetch } = useFetch<RolesList>({
    url: `/admin/authorization`,
    searchQuery: JSON.stringify({
      ...searchQuery,
      _limit: pageSize,
      _offset: (pagination - 1) * pageSize,
    }),
    method: "GET",
    isLoadingDefault: true,
  });

  const onClose = () => {
    setShowDrawer({ show: false, data: null });
  };

  const { columns } = useRolesColumns({
    setShowDrawer,
    refetch,
  });

  return (
    <>
      <Drawer title={t("add-role")} onClose={onClose} open={showDrawer.show}>
        <AuthorizationComponent
          onCloseDrawer={onClose}
          drawerFlag={showDrawer.show}
          data={showDrawer.data}
          refetch={refetch}
        />
      </Drawer>

      <Table
        Buttons={[
          {
            key: "Add",
            label: t("add-role"),
            onClick: () => {
              setShowDrawer({ show: true, data: null });
            },
          },
        ]}
        loading={isLoading || false}
        columns={columns}
        data={data ? data.data : []}
        pagination={true}
        total={data ? data.total : 0}
        pageSize={pageSize}
        currentPage={pagination}
        onPaginationChange={(page) => {
          setPagination(page);
        }}
        onShowSizeChange={(_, size) => {
          setPageSize(size);
        }}
        refetch={refetch}
        filter={true}
        onFilterValuesChange={(_, values) => {
          setSearchQuery({
            name: values.searchText,
          });
        }}
      />
    </>
  );
};

export default Roles;
