import { useCallback, useEffect, useState, useMemo } from "react";
import { request } from "../services";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";

import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import useUser from "../store/User";
import { useTranslation } from "react-i18next";

const { error: ErrorModal, destroyAll } = Modal;

interface FetchProps<T> {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  payload?: object;
  config?: AxiosRequestConfig;
  isLoadingDefault?: boolean;
  onSuccess?: (data: AxiosResponse<T>) => void;
  onError?: (error: string) => void;
  onEnd?: () => void;
  disabledStates?: boolean;
  disabled?: boolean;
  notify?: boolean;
  searchQuery?: string;
}

const useFetch = <T>({
  url,
  method,
  payload,
  config,
  isLoadingDefault,
  onSuccess,
  onEnd,
  onError,
  disabledStates = false,
  disabled,
  notify = true,
  searchQuery,
}: FetchProps<T>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setUser, setIsAuth } = useUser();

  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(isLoadingDefault);

  const memoizedPayload = useMemo(() => payload, [JSON.stringify(payload)]);
  const memoizedSearchQuery = useMemo(() => searchQuery, [searchQuery]);

  const fetch = useCallback(async () => {
    if (disabled) return;
    try {
      if (!disabledStates) {
        setIsLoading(true);
        setError("");
        setData(null);
      }
      const searchQueryObject = memoizedSearchQuery
        ? JSON.parse(memoizedSearchQuery)
        : null;
      let searchParamsString = "";
      if (searchQueryObject) {
        searchParamsString =
          "?" +
          Object.keys(searchQueryObject)
            .map((key) => {
              const keyValue =
                searchQueryObject[key as keyof typeof searchQueryObject];
              if (
                keyValue !== null &&
                keyValue !== undefined &&
                keyValue !== ""
              ) {
                return `${key}=${keyValue}`;
              }
              return null;
            })
            .filter(Boolean)
            .join("&");
      }

      const response: AxiosResponse<T> = await request(
        url + searchParamsString,
        method,
        memoizedPayload,
        {
          withCredentials: true,
          ...config,
        }
      );

      !disabledStates && setData(response.data);
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      !disabledStates && setError((error as Error).message);
      if (axios.isAxiosError(error)) {
        const errorResponse =
          error.response?.data.message || t("unknown-error");

        onError && onError(errorResponse);
        notify && toast.error(errorResponse);

        if (
          error.response?.status == 401 &&
          window.location.pathname !== "/login"
        ) {
          ErrorModal({
            centered: true,
            title: t("not-authenticated"),
            content: t("token-expired-message"),
            onOk: () => {
              setUser(null);
              setIsAuth(false);
              destroyAll();
              navigate("/login");
              window.location.reload();
            },
            className: "ant-error-modal",
          });
        }
        return false;
      }

      const errorResponse = (error as Error).message;
      onError && onError(errorResponse);
      notify && toast.error(errorResponse);
      return false;
    } finally {
      onEnd && onEnd();
      !disabledStates && setIsLoading(false);
    }
  }, [method, memoizedPayload, url, memoizedSearchQuery, disabled]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const refetch = useCallback(() => {
    if (!disabledStates) {
      setData(null);
      setError("");
      setIsLoading(false);
    }
    fetch();
  }, [fetch, disabledStates]);

  return { refetch, data, error, isLoading };
};

export default useFetch;
