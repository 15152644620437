import { Badge, Button, Modal, Popconfirm, TableProps, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import ClickToCopy from "../../UI/ClickToCopy";
import AccountProfilePicIcon from "../../common/AccountProfilePicIcon";
import { Dispatch, SetStateAction } from "react";
import { badgeStatus } from "../badgeStatus";
import ExternalLink from "../../common/ExternalLink";
import moment from "moment-timezone";
import PocketBase from "pocketbase";
import { toast } from "react-toastify";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlinePicture,
} from "react-icons/ai";
import { BiMessageDetail } from "react-icons/bi";
import { GrDocumentUser } from "react-icons/gr";

interface useRiderFeedbacksColumnsProps {
  setShowRiderInfo: Dispatch<
    SetStateAction<{
      show: boolean;
      partner_id: number;
    }>
  >;
  setShowViewImageModal: Dispatch<
    SetStateAction<{
      imgSrc: string;
      show: boolean;
      title: string;
    }>
  >;
  refetch: () => void;
}
export default function useRiderFeedbacksColumns({
  setShowRiderInfo,
  setShowViewImageModal,
  refetch,
}: useRiderFeedbacksColumnsProps) {
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();

  const pb = new PocketBase("https://saas.fullship.net");
  const fsComplainCollection = pb.collection("FS_App_Complain");

  const columns: TableProps["columns"] = [
    {
      title: t("partner-id"),
      dataIndex: "partner_id",
      key: "partner_id",
      render: (val) => <ClickToCopy text={val}>{val}</ClickToCopy>,
    },
    {
      title: t("rider-name"),
      dataIndex: "rider_name",
      key: "rider_name",
      render: (_, row) => (
        <div className="flex flex-row items-center gap-1">
          <div>
            <AccountProfilePicIcon
              imageURL={row.profile_image}
              onClick={() => {
                if (row.profile_image) {
                  setShowViewImageModal({
                    imgSrc: row.profile_image || "",
                    show: true,
                    title: "Rider profile picture",
                  });
                }
              }}
            />
          </div>
          <div>
            <ClickToCopy text={row.rider_name}>
              <div className={`text-left`}>{row.rider_name}</div>
            </ClickToCopy>
          </div>
        </div>
      ),
    },
    {
      title: t("restaurant"),
      dataIndex: "rest_name",
      key: "rest_name",
      render: (val) => <div className="capitalize">{val}</div>,
    },
    {
      title: t("rider-city"),
      dataIndex: "rider_city",
      key: "rider_city",
      render: (val) => (
        <div className="capitalize">
          {val === "suli" ? "sulaymaniyah" : val}
        </div>
      ),
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (status: keyof typeof badgeStatus) => (
        <Badge
          color={badgeStatus[status]}
          count={status}
          className="w-full text-center capitalize min-w-[70px]"
          showZero
        />
      ),
    },
    {
      title: t("category"),
      dataIndex: "category",
      key: "category",
      render: (val) => <div className="capitalize">{val}</div>,
    },
    {
      title: t("location"),
      dataIndex: "location",
      key: "location",
      render: (lc) => <ExternalLink url={lc} />,
    },
    {
      title: t("created"),
      dataIndex: "created",
      key: "created",
      render: (val) => moment(val).format("YYYY-MM-DD hh:mm:ss A"),
    },
    {
      title: t("actions"),
      render: (_, row) => {
        const url = pb.files.getUrl(row, row.picture, { thumb: "100x250" });
        return (
          <div className="flex flex-row items-center justify-end gap-1">
            {contextHolder}
            {row.status === "pending" && (
              <Popconfirm
                placement="bottomLeft"
                title={t("change-status-to-sent")}
                description={t("sent-report-to-partner-confirm-message")}
                okText={t("yes")}
                cancelText={t("no")}
                onConfirm={() => {
                  fsComplainCollection
                    .update(row.id, { status: "sent" })
                    .then(() => {
                      refetch();
                      toast.success(t("status-changed-to-sent"));
                    })
                    .catch(() => {
                      toast.error(t("failed-to-change-status"));
                    });
                }}
              >
                <Button className="flex items-center px-3 py-2">
                  <AiOutlineCheck size={14} />
                </Button>
              </Popconfirm>
            )}
            {row.status !== "rejected" && (
              <Popconfirm
                placement="bottomLeft"
                title={t("change-status-to-rejected")}
                description={t("reject-report-to-partner-confirm-message")}
                okText={t("yes")}
                cancelText={t("no")}
                onConfirm={() => {
                  fsComplainCollection
                    .update(row.id, { status: "rejected" })
                    .then(() => {
                      refetch();
                      toast.success(t("status-changed-to-rejected"));
                    })
                    .catch(() => {
                      toast.error(t("failed-to-change-status"));
                    });
                }}
              >
                <Button className="flex items-center px-3 py-2">
                  <AiOutlineClose size={14} />
                </Button>
              </Popconfirm>
            )}
            {row.picture && (
              <Button
                className="flex items-center px-3 py-2"
                onClick={() => {
                  setShowViewImageModal({
                    imgSrc: url,
                    show: true,
                    title: "Feedback picture",
                  });
                }}
              >
                <AiOutlinePicture size={16} />
              </Button>
            )}

            <Tooltip title={t("view-explanation")}>
              <Button
                onClick={() =>
                  modal.info({
                    title: "Explanation",
                    content: row.explanation,
                    centered: true,
                    closable: true,
                    maskClosable: true,
                  })
                }
                className="flex items-center px-3 py-2"
              >
                <BiMessageDetail size={16} />
              </Button>
            </Tooltip>
            <Tooltip title={t("view-rider-info")}>
              <Button
                onClick={() =>
                  setShowRiderInfo({
                    show: true,
                    partner_id: row.partner_id,
                  })
                }
                className="flex items-center px-3 py-2"
              >
                <GrDocumentUser size={16} />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return { columns };
}
