import { TableProps, Badge, Popconfirm, Button } from "antd";
import { FaEye } from "react-icons/fa";
import { FaRegFaceSadTear } from "react-icons/fa6";
import { GrTools } from "react-icons/gr";
import { toast } from "react-toastify";
import { ErrorLog } from "../../../../models/super-panel";
import { useTranslation } from "react-i18next";
import { useMomentTZ } from "../../../../hooks/useMomentTZ";
import useAxios from "../../../../hooks/useAxios";
import { Dispatch, SetStateAction } from "react";

interface useErrorLogsColumnsProps {
  setShowMoreErrorInfoModal: Dispatch<
    SetStateAction<{
      show: boolean;
      payload: string;
      stack: string;
    }>
  >;
  refetch: () => void;
}
export default function useErrorLogsColumns({
  setShowMoreErrorInfoModal,
  refetch,
}: useErrorLogsColumnsProps) {
  const { t } = useTranslation();
  const { formatDateTimeWithTimezone } = useMomentTZ();
  const { fetch } = useAxios({ disabledStates: true });

  const columns: TableProps<ErrorLog>["columns"] = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      sorter: true,
    },
    {
      key: "name",
      title: t("name"),
      dataIndex: "name",
    },
    {
      key: "reqType",
      title: t("http-method"),
      dataIndex: "reqType",
    },
    {
      key: "operational",
      title: t("operational"),
      dataIndex: "operational",
      render: (value) => (
        <Badge
          count={value ? t("yes") : t("no")}
          showZero
          color={`${value ? "#22bb33" : "text-danger"}`}
        />
      ),
    },
    {
      key: "route",
      title: t("route"),
      dataIndex: "route",
    },

    {
      key: "message",
      title: t("message"),
      dataIndex: "message",
    },
    {
      key: "frequency",
      title: t("frequency"),
      dataIndex: "frequency",
      sorter: true,
    },
    {
      key: "fixed",
      title: t("fixed"),
      dataIndex: "fixed",
      render: (value) => (
        <Badge
          count={value ? t("yes") : t("no")}
          showZero
          color={`${value ? "#22bb33" : "text-danger"}`}
        />
      ),
    },
    {
      key: "occurredAt",
      title: t("occurred-at"),
      dataIndex: "occurredAt",
      render: (value) => formatDateTimeWithTimezone(value),
      sorter: true,
    },
    {
      title: t("actions"),
      key: "action",
      fixed: "right",
      width: "110px",
      render: (_, row) => (
        <div className="flex flex-row items-center justify-end gap-1">
          {!row.fixed && (
            <Popconfirm
              placement="bottomLeft"
              title={t("is-error-fixed")}
              description={t("error-fixed-confirm-message")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={async () => {
                fetch({
                  url: `/admin/error-logs/${row.id}`,
                  method: "PUT",
                  payload: { fixed: true },
                  onSuccess: () => {
                    refetch();
                    toast.success(t("error-log-updated-successfully"));
                  },
                });
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button
                className="flex items-center px-3 py-2"
                onClick={() => {}}
              >
                <GrTools size={14} />
              </Button>
            </Popconfirm>
          )}
          {row.fixed && (
            <Popconfirm
              placement="bottomLeft"
              title={t("is-error-not-fixed")}
              description={t("error-is-not-fixed-confirm-message")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={async () => {
                fetch({
                  url: `/admin/error-logs/${row.id}`,
                  method: "PUT",
                  payload: { fixed: false },
                  onSuccess: () => {
                    refetch();
                    toast.success(t("error-log-updated-successfully"));
                  },
                });
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button
                className="flex items-center px-3 py-2"
                onClick={() => {}}
              >
                <FaRegFaceSadTear size={14} />
              </Button>
            </Popconfirm>
          )}
          <Button
            className="flex items-center px-3 py-2"
            onClick={() => {
              setShowMoreErrorInfoModal({
                show: true,
                payload: JSON.stringify(row.payload),
                stack: row.stack,
              });
            }}
          >
            <FaEye size={14} />
          </Button>
        </div>
      ),
    },
  ];
  return { columns };
}
