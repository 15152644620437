import LeaveSettings from "../../components/SuperPanel/Settings/Leave";
import { Tabs } from "antd";
import Lists from "../../components/SuperPanel/Settings/Lists";
import Roles from "../../components/SuperPanel/Settings/Roles";
import RiderSettings from "../../components/SuperPanel/Settings/Rider";
import PerformanceSettings from "../../components/SuperPanel/Settings/Performance";
import SystemMode from "../../components/SuperPanel/Settings/SystemMode";
import { useTranslation } from "react-i18next";
import PayrollPreferencesPage from "../../components/SuperPanel/Settings/Payroll";

const Settings = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs
        destroyInactiveTabPane
        type="card"
        items={[
          {
            label: t("roles"),
            key: "roles",
            children: <Roles />,
          },
          {
            label: t("rider"),
            key: "rider",
            children: <RiderSettings />,
          },
          {
            label: t("leave"),
            key: "leave",
            children: <LeaveSettings />,
          },
          {
            label: t("payroll"),
            key: "payroll",
            children: <PayrollPreferencesPage />,
          },
          {
            label: t("lists"),
            key: "lists",
            children: <Lists />,
          },
          {
            label: t("performance"),
            key: "performance",
            children: <PerformanceSettings />,
          },
          {
            label: t("system-mode"),
            key: "mode",
            children: <SystemMode />,
          },
        ]}
      />
    </div>
  );
};

export default Settings;
