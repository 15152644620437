import { useEffect } from "react";
import { Button, Col, Form, FormProps, InputNumber, Row } from "antd";
import { toast } from "react-toastify";
import useAxios from "../../../../hooks/useAxios";
import { PerformanceGeneralSettings } from "../../../../models/performance";
import { useTranslation } from "react-i18next";
import { isEmpty, uniqueId } from "lodash-es";
import DraggableTags, { Item } from "../../../UI/DraggableTags";

interface GeneralSettingsFormProps {
  refetch: () => void;
  data?: PerformanceGeneralSettings;
}

const PerformanceGeneralSettingsForm = ({
  refetch,
  data,
}: GeneralSettingsFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<PerformanceGeneralSettings>();
  const { fetch, isLoading } = useAxios({ disabledStates: false });

  useEffect(() => {
    if (data) {
      const initialData = {
        ...data,
        universalRank: data.universalRank.map((val) => ({
          id: Number(uniqueId()),
          text: val.toString(),
        })),
        percentageRank: data.percentageRank.map((val) => ({
          id: Number(uniqueId()),
          text: val.toString(),
        })),
      };

      form.setFieldsValue(initialData);
    }
  }, [data, form]);

  const onFinish: FormProps<PerformanceGeneralSettings>["onFinish"] = async (
    values
  ) => {
    const formattedValues = {
      ...values,
      universalRank: values.universalRank.map((val) => Number(val.text)),
      percentageRank: values.percentageRank.map((val) => Number(val.text)),
    };

    // if (values.leftBlocksEffect + values.rightBlocksEffect !== 1) {
    //   toast.error("Left and Right Blocks Effect should be equal to 100%");
    //   return;
    // }

    // if (values.universalRank.reduce((a, b) => a + b, 0) !== 1) {
    //   toast.error("Sum of Universal Rank should be equal to 100%");
    //   return;
    // }

    // if (values.percentageRank.reduce((a, b) => a + b, 0) !== 1) {
    //   toast.error("Sum of Percentage Rank should be equal to 100%");
    //   return;
    // }

    await fetch({
      url: "/admin/riders/performance/settings/general",
      method: "PUT",
      payload: formattedValues,
      onSuccess: () => {
        toast.success(t("general-settings-updated-successfully"));
        refetch();
      },
    });
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("cluster-size")}
            name="clusterSize"
            rules={[{ required: true, type: "number", min: 2, max: 10 }]}
          >
            <InputNumber min={2} max={10} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("left-blocks-effect")}
            name="leftBlocksEffect"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("right-blocks-effect")}
            name="rightBlocksEffect"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={t("universal-rank")}
            name="universalRank"
            rules={[
              {
                required: true,
                type: "array",
                min: 2,
                max: 10,
                validator: (_, value) =>
                  !isEmpty(value) &&
                  isEmpty(value.filter((v: number) => isNaN(v)))
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(t("universal-rank-should-be-number"))
                      ),

                // validator: (_, value) =>
                //   value &&
                //   value.reduce((a: number, b: number) => a + b, 0) === 1
                //     ? Promise.resolve()
                //     : Promise.reject(
                //         new Error("Sum of Universal Rank should be 100%")
                //       ),
                transform: (value: Item[]) => {
                  return value.map((val) => Number(val.text));
                },
              },
            ]}
          >
            {/* @ts-expect-error the DraggableTags expect to pass value and onChange props but i didn't found any good way to this  */}
            <DraggableTags />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={t("percentage-rank")}
            name="percentageRank"
            rules={[
              {
                required: true,
                type: "array",
                min: 2,
                max: 10,
                validator: (_, value) => {
                  if (isEmpty(value))
                    return Promise.reject(
                      new Error(t("percentage-rank-required"))
                    );
                  if (!isEmpty(value.filter((v: number) => isNaN(v))))
                    return Promise.reject(
                      new Error(t("universal-rank-should-be-number"))
                    );

                  if (value.reduce((a: number, b: number) => a + b, 0) !== 1)
                    return Promise.reject(
                      new Error(t("sum-of-percentage-rank-100"))
                    );

                  return Promise.resolve();
                },
                transform: (value: Item[]) => {
                  return value.map((val) => Number(val.text));
                },
              },
            ]}
          >
            {/* @ts-expect-error the DraggableTags expect to pass value and onChange props but i didn't found any good way to this  */}
            <DraggableTags />
          </Form.Item>
        </Col>
      </Row>
      <div className="flex justify-end w-full">
        <Button
          htmlType="submit"
          type="primary"
          className="bg-primary"
          loading={isLoading}
        >
          {t("save-settings")}
        </Button>
      </div>
    </Form>
  );
};

export default PerformanceGeneralSettingsForm;
