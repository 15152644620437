import { Button, Tooltip } from "antd";
import copy from "copy-to-clipboard";
import useTheme from "../../store/Theme";
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
  children: JSX.Element[] | JSX.Element | string;
}

const ClickToCopy = ({ text, children }: Props) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  return (
    <Tooltip placement="top" title={t("click-to-copy")}>
      <Button
        style={{ color: theme === "dark" ? "white" : "black" }}
        htmlType="button"
        type="text"
        onClick={(e) => {
          e.stopPropagation();
          copy(text);
        }}
        className="flex flex-row justify-start"
      >
        <div>{children}</div>
      </Button>
    </Tooltip>
  );
};

export default ClickToCopy;
