import { useState } from "react";
import { Button, TableProps, Tooltip } from "antd";
import useFetch from "../../../hooks/useFetch";
import Table from "../../../components/UI/Table";
import ClickToCopy from "../../../components/UI/ClickToCopy";
import moment from "moment-timezone";
import PhoneNumberFormatter from "../../../components/UI/PhoneNumberFormatter";
import useListsReq from "../../../hooks/useListsReq";
import { FaEye } from "react-icons/fa";
import { Payroll as PayrollType, PayrollRes } from "../../../models/finance";

import { useSearchParams } from "react-router-dom";
import LinkedRecordsModal from "../../../components/Finance/LinkedRecordsModal";
import { separateNumbersWithCommas } from "../../../utils/formatNumbers";

export default function Reviews() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { cities, riders } = useListsReq({
    requestLists: [{ type: "cities" }, { type: "riders" }],
  });

  const payrollReviewPsl = localStorage.getItem("payroll-review-psl") || "10";

  const paginate = parseInt(searchParams.get("paginate") || "1");
  const pageSize = parseInt(searchParams.get("pageSize") || payrollReviewPsl);
  const searchQuery = JSON.parse(searchParams.get("searchQuery") || "0");

  const [showLinkedRecords, setShowLinkedRecords] = useState<{
    show: boolean;
    payroll?: PayrollType;
  }>({
    show: false,
  });

  const { data, isLoading, refetch } = useFetch<PayrollRes>({
    url: "/admin/finance/payroll",
    method: "GET",
    isLoadingDefault: true,
    searchQuery: JSON.stringify({
      ...searchQuery,
      _limit: pageSize,
      _offset: (paginate - 1) * pageSize,
      _sort: "id",
      _order: "desc",
      status: ["review"],
    }),
  });

  const columns: TableProps<PayrollType>["columns"] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (val) => <ClickToCopy text={val}>{val}</ClickToCopy>,
    },
    {
      title: "Account ID",
      dataIndex: "accountId",
      key: "accountId",
      render: (val) => <ClickToCopy text={val}>{val}</ClickToCopy>,
    },
    {
      title: "Partner ID",
      dataIndex: "partnerID",
      key: "partnerID",
      render: (val) => <ClickToCopy text={val}>{val}</ClickToCopy>,
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (_, row) => (
        <div dir="ltr">
          <div className="flex flex-row items-center">
            <ClickToCopy text={row?.fullName}>
              <div className={`text-left`}>{row?.fullName}</div>
            </ClickToCopy>
          </div>
          <div className="text-xs ms-[15px]">
            <span className="opacity-80 text-nowrap">
              {row.createdBy} ({moment(row.correspondMonth).format("MMM, YYYY")}
              )
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Compensation",
      dataIndex: "compensation",
      key: "compensation",
      render: (val) => separateNumbersWithCommas(val),
    },
    {
      title: "Distance Pay",
      dataIndex: "distancePay",
      key: "distancePay",
      render: (val) => separateNumbersWithCommas(val),
    },
    {
      title: "Order Pay",
      dataIndex: "orderPay",
      key: "orderPay",
      render: (val) => separateNumbersWithCommas(val),
    },
    {
      title: "Penalty",
      dataIndex: "penalty",
      key: "penalty",
      render: (val) => separateNumbersWithCommas(val),
    },
    {
      title: "Rider Share",
      dataIndex: "riderShare",
      key: "riderShare",
      render: (val) => separateNumbersWithCommas(val),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (phoneNo) => <PhoneNumberFormatter phoneNo={phoneNo} />,
    },
    {
      title: "Daily Row Count",
      dataIndex: "dailyRowCount",
      key: "dailyRowCount",
    },
    {
      title: "Actions",
      render: (_, row) => (
        <div className="flex flex-row items-center justify-end gap-1">
          <Tooltip title="View Review Details">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setShowLinkedRecords({ show: true, payroll: row });
              }}
              className="flex items-center px-3 py-2"
            >
              <FaEye size={14} />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <LinkedRecordsModal
        show={showLinkedRecords.show}
        onHide={() => setShowLinkedRecords({ show: false })}
        payroll={showLinkedRecords.payroll}
      />
      <Table
        loading={isLoading}
        columns={columns}
        data={data?.data || []}
        pagination={true}
        total={data ? data.total : 0}
        pageSize={pageSize}
        currentPage={paginate}
        onPaginationChange={(page) => {
          setSearchParams((params) => {
            params.set("paginate", page.toString());
            return params;
          });
        }}
        onShowSizeChange={(_, size) => {
          localStorage.setItem("payroll-review-psl", size.toString());
          setSearchParams((params) => {
            params.set("pageSize", size.toString());
            return params;
          });
        }}
        refetch={refetch}
        filter={true}
        filters={{
          config: [
            {
              type: "selection",
              label: "Riders",
              name: "accountId",
              selectionProps: {
                mode: "multiple",
                options: riders.map((rider) => ({
                  label: rider.name,
                  value: rider.accountId,
                  idValueSearch: rider.partnerId,
                })),
              },
            },

            {
              type: "selection",
              label: "City",
              name: "cityId",
              selectionProps: {
                mode: "multiple",
                options:
                  cities?.map((c) => ({
                    label: c.name,
                    value: c.id,
                  })) || [],
              },
            },
            {
              type: "dateRange",
              label: "Correspond month",
              name: "correspondMonth_",
            },
          ],
        }}
        onFilterValuesChange={(_, values, filters) => {
          const searchQuery = {
            ...(values.searchText && { search: values.searchText }),
            ...(values?.sort?.column && {
              _sort: values.sort.column,
              _order: values.sort.type,
            }),
            ...filters,
          };

          setSearchParams((params) => {
            params.set("searchQuery", JSON.stringify(searchQuery));
            return params;
          });
        }}
        otherTableProps={{
          onRow: (row: PayrollType) => {
            return {
              onClick: () => {
                setShowLinkedRecords({ show: true, payroll: row });
              },
            };
          },
        }}
      />
    </div>
  );
}
