import { Modal } from "antd";
import useFetch from "../../hooks/useFetch";
import { LinkedRecord, Payroll } from "../../models/finance";
import useLinkedRecordsModalColumns from "./hooks/useLinkedRecordsModalColumns";
import Table from "../UI/Table";

interface LinkRecordModalProps {
  show: boolean;
  onHide: () => void;
  payroll: Payroll | undefined;
  refetch: () => void;
}

export default function LinkRecordModal({
  show,
  onHide,
  payroll,
  refetch,
}: LinkRecordModalProps) {
  const {
    data,
    isLoading,
    refetch: linkRefetch,
  } = useFetch<{ data: LinkedRecord[] }>({
    url: payroll ? `/admin/finance/payroll/unlink-records/${payroll.id}` : "",
    method: "GET",
    isLoadingDefault: true,
    disabled: !show,
  });

  const { columns } = useLinkedRecordsModalColumns({
    isUnlink: false,
    payrollId: payroll?.id,
    refetch,
    linkRefetch,
  });

  return (
    <Modal
      title="Records to link"
      centered
      open={show}
      footer={null}
      onCancel={() => {
        onHide();
      }}
      styles={{
        body: { maxHeight: "90vh", overflowY: "auto", padding: "0 10px" },
      }}
      width={"90%"}
      maskClosable={false}
      destroyOnClose
    >
      <Table
        columns={columns}
        data={data?.data || []}
        loading={isLoading}
        pagination={false}
        refetch={refetch}
        total={data?.data?.length}
      />
    </Modal>
  );
}
