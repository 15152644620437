import React, { useState } from "react";
import { Select, Button, Form, Card, Row, Col } from "antd";
import { ListTypeRes } from "../../../../models/super-panel";
import {
  Access,
  AccessType,
  AccessView,
  Authorization,
  Sections,
} from "../../../../models/user";
import { accessTypeOptions } from "../../../../data/mockData";
import AccessComponent from "./AccessComponent";
import { filterOption } from "../../../../utils/antdUtils";
import { PiTrashSimple } from "react-icons/pi";
import { AiOutlinePlus } from "react-icons/ai";
import { useTranslation } from "react-i18next";

interface SectionComponentProps {
  sectionIndex: number;
  sectionData: Authorization;
  onAddAccess: (sectionIndex: number) => void;
  onUpdateAccess: (
    sectionIndex: number,
    accessIndex: number,
    updatedAccess: Access
  ) => void;
  onRemoveAccess: (sectionIndex: number, accessIndex: number) => void;
  onUpdateAccessView: (
    sectionIndex: number,
    updatedAccessView: AccessView
  ) => void;
  onRemoveCard: (sectionName: Sections) => void;
  cities: ListTypeRes;
  accountNames: { id: number; name: string }[];
}

const SectionComponent: React.FC<SectionComponentProps> = ({
  sectionIndex,
  sectionData,
  onAddAccess,
  onUpdateAccess,
  onRemoveAccess,
  onUpdateAccessView,
  onRemoveCard,
  cities,
  accountNames,
}) => {
  const { t } = useTranslation();
  const [accessType, setAccessType] = useState<AccessType>(sectionData[1][0]);
  const [numberArray, setNumberArray] = useState<number[] | null>(
    sectionData[1][1]
  );

  const sectionNames = {
    AC: t("accounts"),
    RI: t("riders"),
    LE: t("leaves"),
    WL: t("waiting-list"),
    IN: t("interview"),
    PE: t("performance"),
    FI: t("finance"),
    PA: t("payroll"),
    WA: t("warehouse"),
    "ONLY-SUPER": "ONLY-SUPER",
  };

  const handleAccessTypeChange = (value: AccessType) => {
    setAccessType(value);
    setNumberArray(null); // Reset numberArray to null
    onUpdateAccessView(sectionIndex, [value, null]);
  };

  const handleNumberArrayChange = (value: number[]) => {
    setNumberArray(value.length ? value : null);
    onUpdateAccessView(sectionIndex, [accessType, value.length ? value : null]);
  };

  return (
    <Card
      title={sectionNames[sectionData[0]] || sectionData[0]}
      extra={
        <div className="flex flex-row gap-3 items-center">
          <Button onClick={() => onAddAccess(sectionIndex)}>
            <AiOutlinePlus size={16} /> {t("access")}
          </Button>
          <Button danger onClick={() => onRemoveCard(sectionData[0])}>
            <PiTrashSimple size={16} />
          </Button>
        </div>
      }
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col
            span={
              accessType === "city-group" || accessType === "access-group"
                ? 12
                : 24
            }
          >
            <Form.Item label={t("access-type")}>
              <Select value={accessType} onChange={handleAccessTypeChange}>
                {accessTypeOptions(t).map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {(accessType === "city-group" || accessType === "access-group") && (
            <Col span={12}>
              <Form.Item
                label={accessType === "city-group" ? t("cities") : t("users")}
              >
                <Select
                  showSearch
                  options={
                    (accessType === "city-group" ? cities : accountNames)?.map(
                      (c) => ({
                        label: c.name,
                        value: c.id,
                      })
                    ) || []
                  }
                  filterOption={filterOption}
                  onChange={handleNumberArrayChange}
                  mode="multiple"
                  value={numberArray || []}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
      {sectionData[2].map((access, accessIndex) => (
        <AccessComponent
          key={accessIndex}
          sectionIndex={sectionIndex}
          accessIndex={accessIndex}
          accessData={access}
          onUpdateAccess={onUpdateAccess}
          onRemoveAccess={onRemoveAccess}
          cities={cities}
          accountNames={accountNames}
        />
      ))}
    </Card>
  );
};

export default SectionComponent;
