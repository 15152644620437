import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { TimezoneProvider } from "./context/TimezoneContext.tsx";
import App from "./App.tsx";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";

import * as Sentry from "@sentry/react";
import "./i18n";
import ErrorBoundary from "./components/UI/ErrorBoundary.tsx";

Sentry.init({
  dsn: "https://76cc75fab11af2498ebbafa9ebaafd28@o4508043089870848.ingest.de.sentry.io/4508043100749904",
  integrations: [
    // Sentry.replayIntegration({
    //   maskAllText: false,
    //   blockAllMedia: false,
    //   maskAllInputs: false,
    //   //@ts-expect-error will be fixed later
    //   maskAttributes: false,
    // }),
    Sentry.browserTracingIntegration(),
  ],
  tracesSampleRate: 1.0,
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.VITE_ENVIRONMENT === "prod",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <>
    <BrowserRouter>
      <ErrorBoundary>
        <TimezoneProvider>
          <App />
        </TimezoneProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </>
);
