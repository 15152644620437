import { Button, Popconfirm, TableProps } from "antd";
import { CityList } from "../../../../../models/super-panel";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";
import useAxios from "../../../../../hooks/useAxios";
import { Dispatch, SetStateAction } from "react";

interface useCityListColumnsProps {
  setShowCityForm: Dispatch<
    SetStateAction<{
      show: boolean;
      data?: CityList;
    }>
  >;
  refetch: () => void;
}
export default function useCityListColumns({
  setShowCityForm,
  refetch,
}: useCityListColumnsProps) {
  const { t } = useTranslation();
  const { fetch } = useAxios({ disabledStates: true });

  const columns: TableProps<CityList>["columns"] = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "name",
      title: t("city"),
      dataIndex: "name",
    },
    {
      key: "country",
      title: t("country"),
      dataIndex: ["countries", "name"],
    },
    {
      key: "createdAt",
      title: t("created-at"),
      dataIndex: "createdAt",
      render: (c) => moment(c).format("YYYY-MM-DD"),
    },
    {
      title: t("actions"),
      key: "action",
      fixed: "right",
      width: "110px",
      render: (_, row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-1">
            <Button
              className="flex items-center px-3 py-2"
              onClick={() => {
                setShowCityForm({
                  show: true,
                  data: row,
                });
              }}
            >
              <FaRegEdit size={14} />
            </Button>
            <Popconfirm
              placement="bottomLeft"
              title={t("delete-city")}
              description={t("delete-confirm-message")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={async () => {
                fetch({
                  url: `/lists/cities/${row.id}`,
                  method: "DELETE",
                  onSuccess: () => {
                    refetch();
                    toast.success(t("city-deleted-successfully"));
                  },
                });
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button danger className="flex items-center px-3 py-2">
                <AiOutlineDelete size={14} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return { columns };
}
