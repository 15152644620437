import { AccessType, Actions, Sections } from "../models/user";
import { UserAuth } from "../models/user";

export const hasViewAccess = (
  section: Sections,
  user: UserAuth | null
): AccessType | boolean => {
  if (user?.type === "super") {
    return true;
  }
  if (!user?.authorization) {
    return false;
  }
  const auth = user.authorization.find((auth) => auth[0] === section);
  return auth && auth[1][0] ? auth[1][0] : false;
};

export const whatAreAccessTypes = (
  section: Sections,
  user: UserAuth | null
): AccessType[] | boolean => {
  if (user?.type === "super") {
    return true;
  }
  if (!user?.authorization) {
    return [];
  }
  const auth = user.authorization.find((auth) => auth[0] === section);
  return auth ? [...new Set(auth[2].map((access) => access[1]))] : [];
};

export const hasAccess = (
  section: Sections,
  action: Actions,
  user: UserAuth | null
): boolean => {
  if (user?.type === "super") {
    return true;
  }
  if (!user?.authorization) {
    return false;
  }
  const auth = user.authorization.find((auth) => auth[0] === section);
  if (auth) {
    return auth[2].some((access) => access[0] === action);
  }
  return false;
};

export const getSectionsWithAccess = (
  action: Actions,
  user: UserAuth | null
): Sections[] => {
  if (!user?.authorization) {
    return [];
  }
  return user.authorization
    .filter((auth) => auth[2].some((access) => access[0] === action))
    .map((auth) => auth[0]);
};

export const getAccessibleSections = (user: UserAuth | null): Sections[] => {
  if (!user?.authorization) {
    return [];
  }
  return user.authorization.map((auth) => auth[0]);
};

export const checkViewAccessType = (
  section: Sections,
  accessType: AccessType,
  user: UserAuth | null
): boolean => {
  if (user?.type === "super") {
    return true;
  }
  if (!user?.authorization) {
    return false;
  }
  const auth = user.authorization.find((auth) => auth[0] === section);
  return auth ? auth[1][0] === accessType : false;
};

export const checkAccessType = (
  section: Sections,
  action: Actions,
  accessType: AccessType,
  user: UserAuth | null
): boolean => {
  if (user?.type === "super") {
    return true;
  }
  if (!user?.authorization) {
    return false;
  }
  const auth = user.authorization.find((auth) => auth[0] === section);
  if (auth) {
    return auth[2].some(
      (access) => access[0] === action && access[1] === accessType
    );
  }
  return false;
};
