import { Button, Col, Form, FormProps, Modal, Row, Select } from "antd";
import { RiderEmailCardType } from "../../../../models/super-panel";
import { toast } from "react-toastify";
import { Dispatch, SetStateAction } from "react";
import useListsReq from "../../../../hooks/useListsReq";
import { useTranslation } from "react-i18next";

interface FieldType {
  city: number;
}

interface SelectCityModalProps {
  show: boolean;
  onHide: () => void;
  selectedCityIDs?: number[];
  setEmailCards: Dispatch<SetStateAction<RiderEmailCardType[]>>;
}

const SelectCityModal = ({
  show,
  onHide,
  selectedCityIDs,
  setEmailCards,
}: SelectCityModalProps) => {
  const { t } = useTranslation();
  const { cities } = useListsReq({
    requestLists: [{ type: "cities" }],
  });

  const [form] = Form.useForm<FieldType>();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    if (selectedCityIDs?.includes(values.city)) {
      toast.warn(t("city-already-selected-message"));
      return;
    }
    const city = cities?.find((c) => c.id === values.city);
    setEmailCards((prev) => [
      {
        id: `card ${Date.now() + "" + Math.random()}`,
        name: `Rider Email`,
        emails: {
          adminToInputs: [{ id: "email main", value: "" }],
          freezeToInputs: [{ id: "email main", value: "" }],
          terminateToInputs: [{ id: "email main", value: "" }],
          changeDataToInputs: [{ id: "email main", value: "" }],
          adminCcInputs: [{ id: "email main", value: "" }],
          freezeCcInputs: [{ id: "email main", value: "" }],
          terminateCcInputs: [{ id: "email main", value: "" }],
          changeDataCcInputs: [{ id: "email main", value: "" }],
        },
        selectedCity: {
          city: city?.name || "",
          cityID: city?.id || values.city,
        },
      },
      ...prev,
    ]);
    onHide();
    form.resetFields();
  };

  return (
    <Modal
      title={t("select-city")}
      centered
      open={show}
      footer={null}
      onCancel={() => {
        onHide();
        form.resetFields();
      }}
      width="400px"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              label={t("city")}
              name="city"
              rules={[{ required: true }]}
            >
              <Select
                options={
                  cities?.map((c) => ({ value: c.id, label: c.name })) || []
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-end w-full">
          <Button htmlType="submit" type="primary" className="bg-primary">
            {t("add")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SelectCityModal;
