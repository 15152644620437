import { useState, useEffect } from "react";
import {
  Upload,
  Select,
  Button,
  Modal,
  Input,
  TableProps,
  Typography,
  Tooltip,
} from "antd";
import Papa from "papaparse";
import Table from "../UI/Table";
import { BsUpload } from "react-icons/bs";
import { filterOption } from "../../utils/antdUtils";
import { toast } from "react-toastify";
import moment, { Moment } from "moment-timezone";
import DatePicker from "../UI/DatePicker";
import CSVInvalidViewer from "./CSVInvalidViewer";
import useTheme from "../../store/Theme";
import CSVMapValues from "./CSVMapValues";
import { downloadBlob } from "../../utils/CSVUtils";
import { useTranslation } from "react-i18next";

export type MapListValuesType = { label: string; value: string | number }[];

export type dbColumnsType = {
  name: string;
  mapValues?: boolean;
  mapListValues?: MapListValuesType;
  optional?: boolean;
}[];

interface CSVProcessorProps {
  onSubmit: (
    file: Blob,
    name: string,
    callback: (res?: { [key: string]: number[] }) => void
  ) => void;
  dbColumns: dbColumnsType;
  mappingLocalStorageKey: string;
  tooltipTitle: string;
  hideDateField?: boolean;
  dateFormat?: string;
  dateMaxDate?: Moment;
}

interface Mapping {
  dbColumn: string;
  csvColumn: string;
}

interface SavedMapping {
  map: Mapping[];
  name: string;
  default?: boolean;
}

const INVALID_DATE = "Invalid Date";
const csvDateFormatOptions = [
  { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
  { label: "DD-MM-YYYY", value: "DD-MM-YYYY" },
  { label: "YYYY/MM/DD", value: "YYYY/MM/DD" },
  { label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
  { label: "MM/DD/YYYY", value: "MM/DD/YYYY" },
];

const CSVProcessor = ({
  onSubmit,
  dbColumns,
  mappingLocalStorageKey,
  hideDateField,
  tooltipTitle,
  dateFormat = "YYYY-MM-DD",
  dateMaxDate,
}: CSVProcessorProps) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [columns, setColumns] = useState<string[]>([]);
  const [mappings, setMappings] = useState<Mapping[]>([]);
  const [selectedMappingIndex, setSelectedMappingIndex] = useState<
    number | null
  >(null);
  const [savedMappings, setSavedMappings] = useState<SavedMapping[]>([]);
  const [data, setData] = useState<string[][]>([]);
  const [fileName, setFileName] = useState("");
  const [csvDateFormat, setCsvDateFormat] = useState(dateFormat);
  const [isMappingModalVisible, setIsMappingModalVisible] = useState(false);
  const [newMappingName, setNewMappingName] = useState("");
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [showErrorModal, setShowErrorModal] = useState<{
    data: string[][];
    show: boolean;
    errorDetails: { [key: string]: number[] };
  }>({ show: false, data: [], errorDetails: {} });

  const [showCSVMapData, setShowCSVMapData] = useState<{
    show: boolean;
    csvColumn: string;
    dbColumn: string;
    csvColumnIndex: number;
    mapListValues?: MapListValuesType;
  }>({
    show: false,
    csvColumn: "",
    dbColumn: "",
    csvColumnIndex: -1,
  });

  const [columnValuesChecked, setColumnValuesChecked] = useState(
    dbColumns
      .filter((db) => db.mapValues)
      .reduce(
        (acc, { name }) => {
          acc[name] = false;
          return acc;
        },
        {} as Record<string, boolean>
      )
  );

  useEffect(() => {
    const storedMappings = localStorage.getItem(mappingLocalStorageKey);
    if (storedMappings) {
      setSavedMappings(JSON.parse(storedMappings));
    }
  }, [columns, mappingLocalStorageKey]);

  const handleUpload = (file: File) => {
    setFileName(file.name);
    Papa.parse(file, {
      complete: (results) => {
        const csvColumns = results.data[0] as string[];
        const csvData = results.data.slice(1) as string[][];
        setColumns(csvColumns);
        setData(csvData);
        setMappings(
          dbColumns.map((dbCol) => {
            const matchingCsvColumn = csvColumns.find(
              (col) => col.toLowerCase() === dbCol.name
            );
            return {
              dbColumn: dbCol.name,
              csvColumn: matchingCsvColumn || "",
            };
          })
        );
      },
      header: false,
    });
  };

  const handleCsvColumnChange = (dbColumn: string, csvColumn: string) => {
    setMappings((prevMappings) =>
      prevMappings.map((mapping) =>
        mapping.dbColumn === dbColumn ? { ...mapping, csvColumn } : mapping
      )
    );
  };

  const saveMapping = () => {
    setIsMappingModalVisible(true);
  };

  const handleSaveMapping = () => {
    if (!newMappingName) {
      return;
    }

    const updatedMappings: SavedMapping[] = [
      ...savedMappings,
      {
        map: mappings.map((m) => {
          return {
            csvColumn: m.csvColumn,
            dbColumn: m.dbColumn,
          };
        }),
        name: newMappingName,
      },
    ];

    setSavedMappings(updatedMappings);
    localStorage.setItem(
      mappingLocalStorageKey,
      JSON.stringify(updatedMappings)
    );
    setIsMappingModalVisible(false);
    setNewMappingName("");
  };

  const handleApplyMapping = (index: number) => {
    const filteredSelectedCSVMapping = savedMappings[index].map
      .filter((sm) => dbColumns.find((c) => c.name === sm.dbColumn))
      .map((sm) => {
        if (!columns.find((c) => c === sm.csvColumn)) {
          return {
            dbColumn: sm.dbColumn,
            csvColumn: "",
          };
        }
        return sm;
      });

    setMappings(filteredSelectedCSVMapping);
    setSelectedMappingIndex(index);
  };

  const handleSubmit = async (downloadCSV: boolean) => {
    const missingMappings = dbColumns
      .filter(
        (dbCol) =>
          !mappings.some(
            (mapping) => mapping.dbColumn === dbCol.name && mapping.csvColumn
          ) && !dbCol.optional
      )
      .map((c) => c.name);

    if (missingMappings.length > 0) {
      toast.warn(
        `Please select a CSV column for the following database columns (${missingMappings.join(
          ", "
        )})`
      );
      return;
    }

    const missingCheckedColumns = Object.keys(columnValuesChecked)
      .map((key) => ({ name: key, checked: columnValuesChecked[key] }))
      .filter((cvc) => !cvc.checked)
      .map((cvc) => cvc.name);

    if (missingCheckedColumns.length > 0) {
      toast.warn(
        `Please check the values of these columns (${missingCheckedColumns.join(
          ", "
        )})`
      );
      return;
    }

    if (!selectedDate && !hideDateField) {
      toast.warn(t("select-date-for-csv"));
      return;
    }

    const mappingDict = mappings.reduce(
      (acc, mapping) => {
        if (mapping.csvColumn) {
          acc[mapping.dbColumn] = mapping.csvColumn;
        }
        return acc;
      },
      {} as { [key: string]: string }
    );

    const newHeaders = [...dbColumns.map((c) => c.name)];

    if (!hideDateField) {
      newHeaders.unshift("date");
    }

    const dateColumnIndex = newHeaders.indexOf("date");

    const filteredData = data
      .map((row) => {
        const dbColumnsCsvData = dbColumns.map((dbCol) => {
          const csvCol = mappingDict[dbCol.name];
          const csvColIndex = columns.indexOf(csvCol);
          return csvColIndex > -1 ? row[csvColIndex] : "";
        });

        if (hideDateField && dateColumnIndex > -1) {
          const dateToMoment = moment(
            dbColumnsCsvData[dateColumnIndex],
            csvDateFormat,
            true
          );

          const formattedDate = dateToMoment.isValid()
            ? dateToMoment.format(dateFormat)
            : INVALID_DATE;

          dbColumnsCsvData[dateColumnIndex] = formattedDate;
        }

        if (hideDateField) {
          return dbColumnsCsvData;
        }

        return [
          selectedDate ? selectedDate.format(dateFormat) : "",
          ...dbColumnsCsvData,
        ];
      })
      .filter((row) => row[1]);

    const hasInvalidDate = filteredData.some((row) =>
      row.includes(INVALID_DATE)
    );

    if (hasInvalidDate) {
      toast.warn(t("invalid-csv-date-format"));
      return;
    }

    const updatedData = [newHeaders, ...filteredData];

    const csvString = Papa.unparse(updatedData);
    const blob = new Blob([csvString], { type: "text/csv" });

    onSubmit(blob, fileName, (res) => {
      if (res) {
        setShowErrorModal({
          show: true,
          data: updatedData,
          errorDetails: res,
        });
      } else {
        onHideModal();
      }
      if (downloadCSV) {
        downloadBlob(blob, `processed ${fileName}`);
      }
    });
  };

  const filteredCsvColumns = columns.filter(
    (csvCol) => !mappings.find((mc) => mc.csvColumn === csvCol)
  );

  const onHideModal = () => {
    setColumns([]);
    setMappings([]);
    setData([]);
    setSavedMappings([]);
    setSelectedMappingIndex(null);
    setFileName("");
    setCsvDateFormat(dateFormat);
    setShowErrorModal({ show: false, data: [], errorDetails: {} });
    setColumnValuesChecked({});
  };

  const tableColumns: TableProps<Mapping>["columns"] = [
    {
      title: t("database-column"),
      dataIndex: "dbColumn",
      key: "dbColumn",
      render: (_, row) => {
        const column = dbColumns.find((d) => d.name === row.dbColumn);

        return (
          <div>
            {row.dbColumn} {column?.optional ? "(Optional)" : ""}
          </div>
        );
      },
    },
    {
      title: t("csv-column"),
      dataIndex: "csvColumn",
      key: "csvColumn",
      render: (_, row) =>
        row.dbColumn === "date" && !hideDateField ? (
          <DatePicker
            onChange={(date) => setSelectedDate(date)}
            className="w-full"
            maxDate={dateMaxDate}
          />
        ) : (
          <Select
            value={row.csvColumn}
            onChange={(value) => handleCsvColumnChange(row.dbColumn, value)}
            className="w-full"
            allowClear
            showSearch
            options={filteredCsvColumns.map((f) => ({ label: f, value: f }))}
            filterOption={filterOption}
          />
        ),
    },
    {
      title: "Map Values",
      render: (_, row) => {
        const mapValues = dbColumns.find((dc) => dc.name === row.dbColumn);

        return (
          <div>
            {hideDateField && row.dbColumn === "date" && (
              <Select
                value={csvDateFormat}
                onChange={setCsvDateFormat}
                className="w-full"
                showSearch
                options={csvDateFormatOptions}
              />
            )}
            {mapValues?.mapValues && (
              <Button
                type="primary"
                onClick={() => {
                  if (!row.csvColumn) {
                    toast.warn(
                      "You should select the csv column then you can take this action"
                    );
                    return;
                  }

                  setShowCSVMapData({
                    show: true,
                    csvColumn: row.csvColumn,
                    csvColumnIndex: columns.indexOf(row.csvColumn),
                    dbColumn: row.dbColumn,
                    mapListValues: mapValues.mapListValues,
                  });
                }}
                disabled={columnValuesChecked[row.dbColumn]}
              >
                {columnValuesChecked[row.dbColumn] ? "checked" : "check"}
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const tableData = [...mappings];
  if (!hideDateField) {
    tableData.unshift({ dbColumn: "date", csvColumn: "date" });
  }

  return (
    <>
      <Upload
        beforeUpload={(file) => {
          handleUpload(file);
          return false;
        }}
        showUploadList={false}
        accept=".csv"
        className="ant-upload-w-full"
      >
        <Tooltip title={tooltipTitle}>
          <Button
            block
            className="btn-height-40 items-center rounded-border-xl-cstm"
            icon={<BsUpload size={16} />}
          />
        </Tooltip>
      </Upload>
      <CSVMapValues
        onHide={() =>
          setShowCSVMapData({
            show: false,
            dbColumn: "",
            csvColumn: "",
            csvColumnIndex: -1,
          })
        }
        show={showCSVMapData.show}
        csvColumnIndex={showCSVMapData.csvColumnIndex}
        csvColumn={showCSVMapData.csvColumn}
        dbColumn={showCSVMapData.dbColumn}
        data={data}
        onSetData={(val) => setData(val)}
        mapListValues={showCSVMapData.mapListValues}
        setColumnValuesChecked={setColumnValuesChecked}
      />
      <CSVInvalidViewer
        show={showErrorModal?.show}
        data={showErrorModal?.data}
        errorDetails={showErrorModal?.errorDetails}
        onHide={() =>
          setShowErrorModal({ show: false, errorDetails: {}, data: [] })
        }
      />
      <Modal
        title={`CSV Column Mapping ${fileName ? `- ${fileName}` : ""}`}
        open={columns.length > 0}
        onCancel={onHideModal}
        centered
        width="800px"
        maskClosable={false}
        footer={
          <>
            <div className="flex flex-row items-center justify-between mt-8">
              <div className="flex flex-row items-center gap-2">
                {columns.length > 0 && (
                  <>
                    <Select
                      onChange={handleApplyMapping}
                      placeholder={t("select-saved-mapping")}
                      value={selectedMappingIndex}
                    >
                      {savedMappings.map((smap, index) => (
                        <Select.Option key={index} value={index}>
                          {smap.name} {smap.default ? "(Default)" : ""}
                        </Select.Option>
                      ))}
                    </Select>
                    <Button onClick={saveMapping}>{t("save-mapping")}</Button>
                  </>
                )}
              </div>
              <div className="flex flex-row items-center gap-2">
                <Button onClick={onHideModal}>{t("cancel")}</Button>
                {columns.length > 0 && (
                  <Button onClick={() => handleSubmit(true)}>
                    {t("download-and-submit-csv")}
                  </Button>
                )}
                {columns.length > 0 && (
                  <Button type="primary" onClick={() => handleSubmit(false)}>
                    {t("submit-csv")}
                  </Button>
                )}
              </div>
            </div>
          </>
        }
        styles={{ body: { maxHeight: "80vh", overflowY: "auto" } }}
      >
        {columns.length > 0 ? (
          <>
            <Typography.Text
              className={`font-semibold bg-[#f0f0f0] p-1 ${
                theme === "dark" ? "text-black" : ""
              }`}
            >
              {t("select-csv-column")}
            </Typography.Text>

            <Table
              data={tableData}
              columns={tableColumns}
              otherTableProps={{
                rowKey: "dbColumn",
              }}
            />
          </>
        ) : (
          <Typography.Text className="block p-2 bg-[#FAFAFA] text-center">
            {t("upload-csv-file-to-map-columns")}
          </Typography.Text>
        )}
      </Modal>
      <Modal
        title={t("save-mapping")}
        open={isMappingModalVisible}
        onCancel={() => {
          setIsMappingModalVisible(false);
          setNewMappingName("");
        }}
        onOk={handleSaveMapping}
        width="400px"
      >
        <Input
          placeholder={t("enter-mapping-name")}
          value={newMappingName}
          onChange={(e) => setNewMappingName(e.target.value)}
        />
      </Modal>
    </>
  );
};

export default CSVProcessor;
