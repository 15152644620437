import { Button, TableProps } from "antd";
import { Country } from "../../../../../models/super-panel";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { FaRegEdit } from "react-icons/fa";

interface useCountriesListColumnsProps {
  setShowActivateModal: Dispatch<
    SetStateAction<{
      show: boolean;
      data?: {
        priority: number;
        active: string;
        id: number;
      };
    }>
  >;
}
export default function useCountriesListColumns({
  setShowActivateModal,
}: useCountriesListColumnsProps) {
  const { t } = useTranslation();

  const columns: TableProps<Country>["columns"] = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "name",
      title: t("name"),
      dataIndex: "name",
    },
    {
      key: "alpha",
      title: t("alpha"),
      dataIndex: "alpha",
    },
    {
      key: "code",
      title: t("code"),
      dataIndex: "code",
    },
    {
      title: t("actions"),
      key: "action",
      fixed: "right",
      width: "110px",
      render: (_, row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-3">
            <Button
              className="flex items-center"
              onClick={() => {
                setShowActivateModal({
                  show: true,
                  data: {
                    priority: row.priority,
                    active: row.active,
                    id: row.id,
                  },
                });
              }}
            >
              <FaRegEdit size={16} />
            </Button>
          </div>
        );
      },
    },
  ];

  return { columns };
}
