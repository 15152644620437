import { Button, Popconfirm, TableProps } from "antd";
import { useTranslation } from "react-i18next";
import useAxios from "../../../../hooks/useAxios";
import { toast } from "react-toastify";
import { downloadBlob } from "../../../../utils/CSVUtils";
import { AiOutlineDelete } from "react-icons/ai";
import { IoReload } from "react-icons/io5";
import { LiaDownloadSolid } from "react-icons/lia";

interface useBackupColumnsProps {
  refetch: () => void;
  type: string;
  deleteBackupOption?: boolean;
}

export default function useBackupColumns({
  type,
  deleteBackupOption,
  refetch,
}: useBackupColumnsProps) {
  const { t } = useTranslation();
  const { fetch } = useAxios({ disabledStates: true });

  const backupType = type.toLowerCase();

  const restoreBackup = (backup: string, type: string) => {
    fetch({
      url: `/admin/backup/restore`,
      method: "POST",
      payload: {
        type: type,
        filename: backup,
      },
      onSuccess: () => {
        refetch();
        toast.success(t("backup-restored-successfully"));
      },
      globalLoading: true,
    });
  };
  const downloadBackup = (backup: string, type: string) => {
    fetch<ArrayBuffer>({
      url: `/admin/backup/download/${type}/${backup}`,
      method: "POST",
      payload: {},
      config: {
        responseType: "blob",
        headers: {},
      },
      onSuccess: (response) => {
        downloadBlob(new Blob([response.data]), backup);
        refetch();
        toast.success(t("backup-downloaded-successfully"));
      },
      globalLoading: true,
    });
  };
  const deleteBackup = (backup: string, type: string) => {
    fetch({
      url: `/admin/backup/${type}/${backup}`,
      method: "DELETE",
      onSuccess: () => {
        refetch();
        toast.success(t("backup-deleted-successfully"));
      },
      globalLoading: true,
    });
  };

  const columns: TableProps<{
    id: string;
    backup: string;
  }>["columns"] = [
    {
      dataIndex: "#",
      title: "#",
      key: "#",
      render: (_, __, index) => <div>{index + 1}</div>,
      width: "65px",
    },
    { title: `${type} Backups`, dataIndex: "backup", key: "backup" },
    {
      title: "",
      render: (_, row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-3">
            {deleteBackupOption && (
              <Popconfirm
                placement="bottomLeft"
                title={t("delete-backup")}
                description={t("delete-confirm-message")}
                okText={t("yes")}
                cancelText={t("no")}
                onConfirm={() => {
                  deleteBackup(row.backup, backupType);
                }}
                okButtonProps={{ className: "bg-primary" }}
              >
                <Button danger className="flex items-center">
                  <AiOutlineDelete size={16} />
                </Button>
              </Popconfirm>
            )}
            <Popconfirm
              placement="bottomLeft"
              title={t("restore-backup")}
              description={t("restore-confirm-message")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={() => {
                restoreBackup(row.backup, backupType);
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button className="flex items-center">
                <IoReload size={16} />
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="bottomLeft"
              title={t("download-backup")}
              description={t("download-confirm-message")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={() => {
                downloadBackup(row.backup, backupType);
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button className="flex items-center">
                <LiaDownloadSolid size={16} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return { columns };
}
