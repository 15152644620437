import { useEffect, useState } from "react";
import { Role } from "../../../../models/super-panel";

import {
  Access,
  AccessView,
  Authorization,
  Sections,
} from "../../../../models/user";

import SectionComponent from "./SectionComponent";
import useAxios from "../../../../hooks/useAxios";
import { toast } from "react-toastify";
import { Button, Form, Input, Select } from "antd";
import useListsReq from "../../../../hooks/useListsReq";
import { useTranslation } from "react-i18next";

interface AuthorizationComponentProps {
  onCloseDrawer?: () => void;
  drawerFlag?: boolean;
  data: Role | null;
  refetch: () => void;
}

const AuthorizationComponent = ({
  drawerFlag,
  data,
  onCloseDrawer,
  refetch,
}: AuthorizationComponentProps) => {
  const { t } = useTranslation();
  const { cities, accounts } = useListsReq({
    requestLists: [{ type: "cities" }, { type: "accounts" }],
  });

  const { fetch, isLoading } = useAxios({ disabledStates: false });

  const [authorizations, setAuthorizations] = useState<Authorization[]>([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const sectionsToAuthorize = [
    { label: t("accounts"), value: "AC" },
    { label: t("riders"), value: "RI" },
    { label: t("leaves"), value: "LE" },
    { label: t("finance"), value: "FI" },
    { label: t("waiting-list"), value: "WL" },
    { label: t("interview"), value: "IN" },
    { label: t("performance"), value: "PE" },
    { label: t("payroll"), value: "PA" },
    { label: t("warehouse"), value: "WA" },
  ];

  const handleAddAccess = (sectionIndex: number) => {
    const newAuthorizations = [...authorizations];
    newAuthorizations[sectionIndex][2].push(["add", "own-data", null]);
    setAuthorizations(newAuthorizations);
  };

  const handleUpdateAccess = (
    sectionIndex: number,
    accessIndex: number,
    updatedAccess: Access
  ) => {
    const newAuthorizations = [...authorizations];
    newAuthorizations[sectionIndex][2][accessIndex] = updatedAccess;
    setAuthorizations(newAuthorizations);
  };

  const handleRemoveAccess = (sectionIndex: number, accessIndex: number) => {
    const newAuthorizations = [...authorizations];
    newAuthorizations[sectionIndex][2].splice(accessIndex, 1);
    setAuthorizations(newAuthorizations);
  };

  const handleUpdateAccessView = (
    sectionIndex: number,
    updatedAccessView: AccessView
  ) => {
    const newAuthorizations = [...authorizations];
    newAuthorizations[sectionIndex][1] = updatedAccessView;
    setAuthorizations(newAuthorizations);
  };

  const handleRemoveCard = (sectionName: Sections) => {
    const updatedAuthorizations = authorizations.filter(
      (authz) => authz[0] !== sectionName
    );
    setAuthorizations(updatedAuthorizations);
  };

  const onFinish = async () => {
    await fetch({
      url: data?.id
        ? `/admin/authorization/${data?.id}`
        : "/admin/authorization",
      method: data ? "PUT" : "POST",
      payload: {
        authorization: authorizations,
        name,
        description,
      },
      onSuccess: () => {
        toast.success(
          data?.id
            ? t("role-updated-successfully")
            : t("role-added-successfully")
        );
        onCloseDrawer && onCloseDrawer();
        refetch && refetch();
      },
    });
  };

  useEffect(() => {
    if (drawerFlag && data) {
      setAuthorizations(data.authorization);
      setName(data.name);
      setDescription(data.description);
    } else {
      setAuthorizations([]);
      setName("");
      setDescription("");
    }
  }, [drawerFlag, data]);

  return (
    <div>
      <Form layout="vertical">
        <Form.Item label={t("authorize-section")}>
          <Select
            options={sectionsToAuthorize.filter(
              (sta) => !authorizations.find((authz) => authz[0] === sta.value)
            )}
            onChange={(selected) => {
              if (selected) {
                setAuthorizations([
                  ...authorizations,
                  [selected, ["own-data", null], []],
                ]);
              }
            }}
            value={null}
          />
        </Form.Item>
        <Form.Item label={t("name")}>
          <Input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Form.Item>
        <div className="flex flex-col gap-3">
          {authorizations.map((authorization, sectionIndex) => (
            <SectionComponent
              key={sectionIndex}
              sectionIndex={sectionIndex}
              sectionData={authorization}
              onAddAccess={handleAddAccess}
              onUpdateAccess={handleUpdateAccess}
              onRemoveAccess={handleRemoveAccess}
              onUpdateAccessView={handleUpdateAccessView}
              onRemoveCard={handleRemoveCard}
              cities={cities}
              accountNames={accounts.map((ac) => ({
                id: ac.id,
                name: ac.fullName,
              }))}
            />
          ))}
        </div>
        <Form.Item label={t("description")} className="mt-5">
          <Input.TextArea
            rows={5}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Form.Item>
        <div className="flex justify-end w-full mt-8">
          <Button
            onClick={() => {
              onFinish();
            }}
            type="primary"
            className="bg-primary"
            loading={isLoading}
          >
            {data ? t("update-role") : t("add-role")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AuthorizationComponent;
