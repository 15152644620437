import { Button, Modal } from "antd";
import useFetch from "../../../../hooks/useFetch";
import {
  CityList as SingleCityList,
  CityListRes,
} from "../../../../models/super-panel";
import Table from "../../../UI/Table";
import CityListForm from "./CityListForm";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import useCityListColumns from "./hooks/useCityListColumns";

interface CityProps {
  show: boolean;
  onHide: () => void;
}

const CityList = ({ show, onHide }: CityProps) => {
  const { t } = useTranslation();

  const [showCityForm, setShowCityForm] = useState<{
    show: boolean;
    data?: SingleCityList;
  }>({
    show: false,
  });

  const { data, isLoading, refetch } = useFetch<CityListRes>({
    url: "/lists/cities",
    method: "GET",
    disabled: !show,
  });

  const { columns } = useCityListColumns({
    setShowCityForm,
    refetch,
  });

  return (
    <Modal
      title={t("city-list")}
      footer={null}
      onCancel={() => {
        onHide();
      }}
      centered
      open={show}
      width="800px"
    >
      <CityListForm
        show={showCityForm.show}
        data={showCityForm.data}
        onHide={() =>
          setShowCityForm({
            show: false,
          })
        }
        refetch={refetch}
      />
      <Button
        type="primary"
        className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary mb-2 mt-4"
        onClick={() => setShowCityForm({ show: true })}
      >
        <AiOutlinePlus size={20} /> {t("add-city")}
      </Button>
      <Table
        loading={isLoading || false}
        columns={columns}
        data={data?.data || []}
        scrollYH="calc(100vh - 300px)"
      />
    </Modal>
  );
};

export default CityList;
