import Table from "../../components/UI/Table";
import useFetch from "../../hooks/useFetch";
import { LogsList } from "../../models/super-panel";
import useLogsColumns from "../../components/SuperPanel/hooks/useLogsColumns";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const Logs = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const logsPsl = localStorage.getItem("logs-psl") || "10";

  const paginate = parseInt(searchParams.get("paginate") || "1");
  const pageSize = parseInt(searchParams.get("pageSize") || logsPsl);
  const searchQuery = JSON.parse(searchParams.get("searchQuery") || "0");

  const { data, isLoading, refetch } = useFetch<LogsList>({
    url: `/logs`,
    searchQuery: JSON.stringify({
      ...searchQuery,
      _limit: pageSize,
      _offset: (paginate - 1) * pageSize,
      _sort: "id",
      _order: "desc",
    }),
    method: "GET",
    isLoadingDefault: true,
  });
  const { columns, components } = useLogsColumns({ refetch });

  return (
    <div>
      {components}
      <Table
        loading={isLoading || false}
        columns={columns}
        data={data ? data.users : []}
        pagination={true}
        total={data ? data.total : 0}
        pageSize={pageSize}
        currentPage={paginate}
        onPaginationChange={(page) => {
          setSearchParams((params) => {
            params.set("paginate", page.toString());
            return params;
          });
        }}
        onShowSizeChange={(_, size) => {
          localStorage.setItem("logs-psl", size.toString());
          setSearchParams((params) => {
            params.set("pageSize", size.toString());
            return params;
          });
        }}
        refetch={refetch}
        filter={true}
        firstPopoverFilters={{
          initialValue: "",
          text: "Type",
          filters: [
            { label: t("all"), value: "" },
            { label: t("get"), value: "get" },
            { label: t("insert"), value: "insert" },
            { label: t("update"), value: "update" },
            { label: t("delete"), value: "delete" },
            { label: t("save"), value: "save" },
            { label: t("other"), value: "other" },
          ],
        }}
        secondPopoverFilters={{
          initialValue: "",
          text: "Section",
          filters: [
            { label: t("all"), value: "" },
            { label: t("accounts"), value: "accounts" },
            { label: t("backup"), value: "backup" },
            { label: t("riders"), value: "riders" },
            { label: t("leaves"), value: "leaves" },
            { label: t("settings"), value: "settings" },
            { label: t("waiting-list"), value: "waitingList" },
            { label: t("error-logs"), value: "errorLogs" },
            { label: t("performance"), value: "performance" },
          ],
        }}
        onFilterValuesChange={(_, values) => {
          const searchQuery = {
            ...(values.searchText && { search: values.searchText }),
            ...(values?.firstPopoverFilters && {
              action: values.firstPopoverFilters,
            }),
            ...(values?.secondPopoverFilters && {
              section: values.secondPopoverFilters,
            }),
          };

          setSearchParams((params) => {
            params.set("searchQuery", JSON.stringify(searchQuery));
            return params;
          });
        }}
      />
    </div>
  );
};

export default Logs;
