import { Button, Popconfirm, TableProps } from "antd";
import { VehicleList } from "../../../../../models/super-panel";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Dispatch, SetStateAction } from "react";
import useAxios from "../../../../../hooks/useAxios";
import { FaRegEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";

interface useVehicleTypeColumnsProps {
  setShowVehicleForm: Dispatch<
    SetStateAction<{
      show: boolean;
      data?: VehicleList;
    }>
  >;
  refetch: () => void;
}

export default function useVehicleTypeColumns({
  setShowVehicleForm,
  refetch,
}: useVehicleTypeColumnsProps) {
  const { t } = useTranslation();
  const { fetch } = useAxios({ disabledStates: true });
  const columns: TableProps<VehicleList>["columns"] = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "name",
      title: t("name"),
      dataIndex: "name",
    },
    {
      key: "type",
      title: t("type"),
      dataIndex: "type",
    },
    {
      key: "createdAt",
      title: t("created-at"),
      dataIndex: "createdAt",
      render: (c) => moment(c).format("YYYY-MM-DD"),
    },
    {
      title: t("actions"),
      key: "action",
      fixed: "right",
      width: "110px",
      render: (_, row) => {
        return (
          <div className="flex flex-row items-center justify-end gap-1">
            <Button
              className="flex items-center px-3 py-2"
              onClick={() => {
                setShowVehicleForm({
                  show: true,
                  data: row,
                });
              }}
            >
              <FaRegEdit size={14} />
            </Button>
            <Popconfirm
              placement="bottomLeft"
              title={t("delete-vehicle")}
              description={t("delete-confirm-message")}
              okText={t("yes")}
              cancelText={t("no")}
              onConfirm={async () => {
                fetch({
                  url: `/lists/vehicles/${row.id}`,
                  method: "DELETE",
                  onSuccess: () => {
                    refetch();
                    toast.success(t("vehicle-deleted-successfully"));
                  },
                });
              }}
              okButtonProps={{ className: "bg-primary" }}
            >
              <Button danger className="flex items-center px-3 py-2">
                <AiOutlineDelete size={14} />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  return { columns };
}
