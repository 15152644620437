import { useState } from "react";
import { Button, TableProps } from "antd";
import { FaEye } from "react-icons/fa";
import { useMomentTZ } from "../../../hooks/useMomentTZ";
import { Log } from "../../../models/super-panel";
import MoreErrorInfoModal from "../../../components/SuperPanel/ErrorLogs/MoreErrorInfoModal";
import { useTranslation } from "react-i18next";
import { ImUndo } from "react-icons/im";
import useAxios from "../../../hooks/useAxios";
import { toast } from "react-toastify";

interface useLogsColumnsProps {
  refetch?: () => void;
}

const useLogsColumns = ({ refetch }: useLogsColumnsProps) => {
  const { t } = useTranslation();
  const { fetch } = useAxios({ disabledStates: true });
  const { formatDateTimeWithTimezone } = useMomentTZ();
  const [showMoreErrorInfoModal, setShowMoreErrorInfoModal] = useState({
    show: false,
    payload: "",
  });

  const columns: TableProps<Log>["columns"] = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "section",
      title: t("section"),
      dataIndex: "section",
    },
    {
      key: "detail",
      title: t("detail"),
      dataIndex: "detail",
    },
    {
      key: "type",
      title: t("type"),
      dataIndex: "type",
    },
    {
      key: "action",
      title: t("action"),
      dataIndex: "action",
    },
    {
      key: "accountName",
      title: t("account-name"),
      dataIndex: "accountName",
    },
    {
      key: "linkId",
      title: t("link-id"),
      dataIndex: "linkId",
    },
    {
      key: "createdAt",
      title: t("created-at"),
      dataIndex: "createdAt",
      render: (column) => formatDateTimeWithTimezone(column),
    },
    {
      title: t("actions"),
      key: "action",
      fixed: "right",
      width: "110px",
      render: (_, row) => (
        <div className="flex flex-row items-center justify-end gap-1">
          <Button
            className="flex items-center px-3 py-2"
            onClick={() => {
              setShowMoreErrorInfoModal({
                show: true,
                payload: row.data
                  ? typeof row.data === "string"
                    ? row.data
                    : JSON.stringify(row.data)
                  : "Empty",
              });
            }}
          >
            <FaEye size={14} />
          </Button>
          {!!row?.linkId && (
            <Button
              className="flex items-center px-3 py-2"
              onClick={() => {
                fetch({
                  url: `/logs/restore/${row.id}`,
                  method: "PUT",
                  payload: {},
                  onSuccess: () => {
                    refetch && refetch();
                    toast.success("Log restored successfully");
                  },
                });
              }}
            >
              <ImUndo size={14} />
            </Button>
          )}
        </div>
      ),
    },
  ];

  const relatedComponents = [
    <MoreErrorInfoModal
      key="moreErrorInfoModal"
      show={showMoreErrorInfoModal.show}
      payload={showMoreErrorInfoModal.payload}
      stack={""}
      onHide={() => setShowMoreErrorInfoModal({ show: false, payload: "" })}
    />,
  ];

  return {
    columns,
    components: relatedComponents,
  };
};

export default useLogsColumns;
