import {
  Button,
  Col,
  Form,
  FormProps,
  InputNumber,
  Modal,
  Row,
  Select,
} from "antd";
import { toast } from "react-toastify";
import { useEffect } from "react";
import useAxios from "../../../../hooks/useAxios";
import { useTranslation } from "react-i18next";

interface ActivatePriorityFormProps {
  show: boolean;
  data?: { priority: number; active: string; id: number };
  onHide: () => void;
  refetch: () => void;
  route: string;
  title: string;
}

interface FieldType {
  active: string;
  priority: string;
}

const ActivatePriorityForm = ({
  show,
  data,
  route,
  title,
  onHide,
  refetch,
}: ActivatePriorityFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FieldType>();
  const { fetch, isLoading } = useAxios({ disabledStates: false });

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    fetch({
      url: data?.id ? `${route}${data.id}` : route,
      method: data?.id ? "PUT" : "POST",
      payload: {
        ...values,
      },
      onSuccess: () => {
        onHide();
        form.resetFields();
        refetch();
        toast.success(
          data?.id ? t("updated-successfully") : t("added-successfully")
        );
      },
    });
  };

  useEffect(() => {
    if (!show) {
      form.resetFields();
    } else {
      form.setFieldValue("active", data?.active);
      form.setFieldValue("priority", data?.priority);
    }
  }, [show, form, data]);

  return (
    <Modal
      title={title}
      footer={null}
      onCancel={() => {
        onHide();
        form.resetFields();
      }}
      centered
      open={show}
      width="400px"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              label={t("active")}
              name="active"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { label: t("yes"), value: "yes" },
                  { label: t("no"), value: "no" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              label={t("priority")}
              name="priority"
              rules={[{ required: true }]}
            >
              <InputNumber className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex justify-end w-full">
          <Button
            htmlType="submit"
            type="primary"
            className="bg-primary"
            loading={isLoading}
          >
            {data?.id ? t("update") : t("add")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ActivatePriorityForm;
