import { useEffect } from "react";
import { Button, Col, Form, InputNumber, Row, FormProps } from "antd";
import { toast } from "react-toastify";
import useAxios from "../../../../hooks/useAxios";
import moment from "moment-timezone";
import { PerformanceCalculationSettingForm } from "../../../../models/performance";
import DatePicker from "../../../UI/DatePicker";
import { useTranslation } from "react-i18next";

interface CalculationPreferencesFormProps {
  refetch: () => void;
  data?: PerformanceCalculationSettingForm;
}

const PerformanceCalculationSettingsForm = ({
  refetch,
  data,
}: CalculationPreferencesFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<PerformanceCalculationSettingForm>();
  const { fetch, isLoading } = useAxios({ disabledStates: true });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        startDate: data.startDate && moment(data.startDate),
        endDate: data.endDate && moment(data.endDate),
      });
    }
  }, [data, form]);

  const onFinish: FormProps<PerformanceCalculationSettingForm>["onFinish"] =
    async (values) => {
      await fetch({
        url: "/admin/riders/performance/settings/calculation-preferences",
        method: "PUT",
        payload: {
          ...values,
          startDate: moment(values.startDate).format("YYYY-MM-DD"),
          endDate: moment(values.endDate).format("YYYY-MM-DD"),
        },
        onSuccess: () => {
          toast.success(t("calculation-preference-updated-successfully"));
          refetch();
        },
      });
    };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("start-date")}
            name="startDate"
            rules={[{ required: true }]}
          >
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("end-date")}
            name="endDate"
            // rules={[{ required: true }]}
          >
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("daily-score-weight")}
            name="dailyScoreWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("block-score-weight")}
            name="blockScoreWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("daily-fine-tune-factor")}
            name="dailyFineTuneFactor"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("block-fine-tune-factor")}
            name="blockFineTuneFactor"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("working-hours-max")}
            name="workingHoursMax"
            rules={[{ required: true, type: "number", min: 0, max: 24 }]}
          >
            <InputNumber min={0} max={24} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("working-hours-mid")}
            name="workingHoursMid"
            rules={[{ required: true, type: "number", min: 0, max: 24 }]}
          >
            <InputNumber min={0} max={24} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("working-hours-min")}
            name="workingHoursMin"
            rules={[{ required: true, type: "number", min: 0, max: 24 }]}
          >
            <InputNumber min={0} max={24} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("working-hours-weight")}
            name="workingHoursWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("acceptance-rate-max")}
            name="acceptanceRateMax"
            rules={[{ required: true, type: "number", min: 0.5, max: 1 }]}
          >
            <InputNumber min={0.5} max={1} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("acceptance-rate-mid")}
            name="acceptanceRateMid"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.99 }]}
          >
            <InputNumber min={0.1} max={0.99} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("acceptance-rate-min")}
            name="acceptanceRateMin"
            rules={[{ required: true, type: "number", min: 0, max: 0.99 }]}
          >
            <InputNumber min={0} max={0.99} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("acceptance-rate-weight")}
            name="acceptanceRateWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("late-shifts-max")}
            name="lateShiftsMax"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("late-shifts-mid")}
            name="lateShiftsMid"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("late-shifts-min")}
            name="lateShiftsMin"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("late-shifts-weight")}
            name="lateShiftsWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("absent-shifts-max")}
            name="absentShiftsMax"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("absent-shifts-mid")}
            name="absentShiftsMid"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("absent-shifts-min")}
            name="absentShiftsMin"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber min={0} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("absent-shifts-weight")}
            name="absentShiftsWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("break-hours-max")}
            name="breakHoursMax"
            rules={[{ required: true, type: "number", min: 0, max: 24 }]}
          >
            <InputNumber min={0} max={24} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("break-hours-mid")}
            name="breakHoursMid"
            rules={[{ required: true, type: "number", min: 0, max: 24 }]}
          >
            <InputNumber min={0} max={24} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("break-hours-min")}
            name="breakHoursMin"
            rules={[{ required: true, type: "number", min: 0, max: 24 }]}
          >
            <InputNumber min={0} max={24} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("break-hours-weight")}
            name="breakHoursWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("working-diff-hours-max")}
            name="workingDiffHoursMax"
            rules={[{ required: true, type: "number", min: 0, max: 6 }]}
          >
            <InputNumber min={0} max={6} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("working-diff-hours-mid")}
            name="workingDiffHoursMid"
            rules={[{ required: true, type: "number", min: 0, max: 6 }]}
          >
            <InputNumber min={0} max={6} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("working-diff-hours-min")}
            name="workingDiffHoursMin"
            rules={[{ required: true, type: "number", min: 0, max: 6 }]}
          >
            <InputNumber min={0} max={6} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("working-diff-hours-weight")}
            name="workingDiffHoursWeight"
            rules={[{ required: true, type: "number", min: 0.1, max: 0.9 }]}
          >
            <InputNumber min={0.1} max={0.9} step={0.1} className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <div className="flex justify-end w-full">
        <Button
          htmlType="submit"
          type="primary"
          className="bg-primary"
          loading={isLoading}
        >
          {t("save-settings")}
        </Button>
      </div>
    </Form>
  );
};

export default PerformanceCalculationSettingsForm;
