import { Button, Col, Form, FormProps, Input, Modal, Row } from "antd";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import { useState } from "react";
import SendOTPModal from "./SendOTPModal";
import ForgetPasswordModal from "./ForgetPasswordModal";
import { Account } from "../../models/accounts";
import { useTranslation } from "react-i18next";

interface ChangePasswordModalProps {
  show: boolean;
  onHide: () => void;
  userAccountInfo?: Account | null;
}

interface FieldType {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

const ChangePasswordModal = ({
  show,
  onHide,
  userAccountInfo,
}: ChangePasswordModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FieldType>();
  const { fetch, isLoading } = useAxios({ disabledStates: false });
  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState({
    show: false,
    phoneNumber: "",
  });
  const [showSendOTPModal, setShowSendOTPModal] = useState(false);

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    fetch({
      url: "/account/password",
      method: "PUT",
      payload: {
        newPassword: values.password,
        oldPassword: values.oldPassword,
      },
      onSuccess: () => {
        toast.success(t("password-reset-successfully"));
        onHide();
        form.resetFields();
      },
    });
  };
  return (
    <>
      <SendOTPModal
        show={showSendOTPModal}
        onHide={() => setShowSendOTPModal(false)}
        openForgetPasswordModal={(phoneNumber) =>
          setShowForgetPasswordModal({ show: true, phoneNumber })
        }
        phoneNumber={userAccountInfo?.phoneNumber}
      />
      <ForgetPasswordModal
        show={showForgetPasswordModal.show}
        onHide={() =>
          setShowForgetPasswordModal({ show: false, phoneNumber: "" })
        }
        phoneNumber={
          "964" + showForgetPasswordModal.phoneNumber?.replace(/^0?7/, "7")
        }
        openSendOTPModal={() => setShowSendOTPModal(true)}
      />
      <Modal
        title={t("forget-password")}
        centered
        open={show}
        footer={null}
        onCancel={() => {
          onHide();
          form.resetFields();
        }}
        width="400px"
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item<FieldType>
                label={t("old-password")}
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    //  min: 8
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item<FieldType>
                label={t("new-password")}
                name="password"
                rules={[
                  {
                    required: true,
                    // min: 8
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item<FieldType>
                label={t("confirm-new-password")}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    // min: 8
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t("passwords-do-not-match"));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-end w-full">
            <Button
              htmlType="submit"
              type="primary"
              className="bg-primary"
              loading={isLoading}
            >
              {t("submit")}
            </Button>
          </div>
          <div className="">
            <Button
              onClick={() => {
                onHide();
                setShowSendOTPModal(true);
              }}
              className="border-none shadow-none text-[#356385]"
            >
              {t("forgot-password")}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
