import { Button } from "antd";
import { LiaCitySolid } from "react-icons/lia";
import { MdOutlineDirectionsCarFilled } from "react-icons/md";
import CityList from "./CityList";
import { useState } from "react";
import VehicleType from "./VehicleType";
import LanguagesList from "./LanguagesList";
import { LuLanguages } from "react-icons/lu";
import CountriesList from "./CountriesList";
import { GrLanguage } from "react-icons/gr";
import { useTranslation } from "react-i18next";

const Lists = () => {
  const { t } = useTranslation();
  const [showCity, setShowCity] = useState(false);
  const [showVehicle, setShowVehicle] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const [showCountries, setShowCountries] = useState(false);

  return (
    <>
      <CityList show={showCity} onHide={() => setShowCity(false)} />
      <VehicleType show={showVehicle} onHide={() => setShowVehicle(false)} />
      <LanguagesList
        show={showLanguages}
        onHide={() => setShowLanguages(false)}
      />
      <CountriesList
        show={showCountries}
        onHide={() => setShowCountries(false)}
      />

      <div className="flex items-center flex-wrap gap-3">
        <Button
          type="primary"
          className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary"
          onClick={() => setShowCity(true)}
        >
          <LiaCitySolid size={20} />
          <div>{t("cities")}</div>
        </Button>
        <Button
          type="primary"
          className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary"
          onClick={() => setShowVehicle(true)}
        >
          <MdOutlineDirectionsCarFilled size={20} />
          <div>{t("vehicles")}</div>
        </Button>
        <Button
          type="primary"
          className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary"
          onClick={() => setShowLanguages(true)}
        >
          <LuLanguages size={20} />
          <div>{t("languages")}</div>
        </Button>
        <Button
          type="primary"
          className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 bg-primary"
          onClick={() => setShowCountries(true)}
        >
          <GrLanguage size={20} />
          <div>{t("countries")}</div>
        </Button>
      </div>
    </>
  );
};

export default Lists;
