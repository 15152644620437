import { useState } from "react";
import { Modal, Form, Row, Col, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/es/upload/interface";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface FileUploadModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (file: RcFile, callback: () => void) => void;
  title: string;
  accepts: string;
  isLoading: boolean;
}

const FileUploadModal = ({
  title,
  accepts,
  show,
  isLoading,
  onHide,
  onSubmit,
}: FileUploadModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onFinish = () => {
    if (fileList.length === 0) {
      toast.warn(t("select-file-to-upload"));
      return;
    }
    const file = fileList[0].originFileObj as RcFile;
    const callback = () => {
      form.resetFields();
      setFileList([]);
      onHide();
    };
    onSubmit(file, callback);
  };

  const handleChange = (info: { fileList: UploadFile[] }) => {
    setFileList(info.fileList);
  };

  return (
    <Modal
      open={show}
      footer={null}
      onCancel={() => {
        onHide();
        form.resetFields();
        setFileList([]);
      }}
      title={title}
      centered
      width="400px"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={t("file")}
              name="file"
              rules={[{ required: true }]}
            >
              <Upload
                fileList={fileList}
                beforeUpload={() => false}
                onChange={handleChange}
                accept={accepts}
                maxCount={1}
                className="upload-w-full"
              >
                <Button className="w-full" icon={<UploadOutlined />}>
                  {t("select-file")}
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-end w-full">
          <Button
            className="bg-primary"
            htmlType="submit"
            type="primary"
            loading={isLoading}
          >
            {t("submit")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default FileUploadModal;
