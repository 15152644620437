import Table from "../../UI/Table";
import useBackupColumns from "./hooks/useBackupColumns";

interface BackupTableProps {
  refetch: () => void;
  data: string[];
  type: string;
  deleteBackupOption?: boolean;
}

const BackupTable = ({
  type,
  data,
  refetch,
  deleteBackupOption = true,
}: BackupTableProps) => {
  const { columns } = useBackupColumns({ type, deleteBackupOption, refetch });

  return (
    <Table
      loading={false}
      columns={columns}
      data={
        (data &&
          data.map((backup) => ({
            id: backup,
            backup,
          }))) ||
        []
      }
      pagination={false}
      filter={false}
      otherTableProps={{ scroll: { y: 500 } }}
    />
  );
};

export default BackupTable;
