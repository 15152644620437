import {
  Button,
  Col,
  Form,
  FormProps,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
} from "antd";
import { otpRegex } from "../../data/mockData";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface ForgetPasswordModalProps {
  show: boolean;
  onHide: () => void;
  phoneNumber: string;
  openSendOTPModal: (phoneNumber: string) => void;
}

interface FieldType {
  otp: number;
  password: string;
  confirmPassword: string;
}

const ForgetPasswordModal = ({
  show,
  onHide,
  phoneNumber,
  openSendOTPModal,
}: ForgetPasswordModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FieldType>();
  const { fetch, isLoading } = useAxios({ disabledStates: false });
  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    fetch({
      config: {
        withCredentials: false,
      },
      url: "/auth/forgot-password",
      method: "PUT",
      payload: {
        password: values.password,
        otp: values.otp,
        phoneNumber: phoneNumber,
      },
      onSuccess: () => {
        toast.success(t("password-reset-successfully"));
        onHide();
        form.resetFields();
      },
    });
  };
  return (
    <Modal
      title={t("forget-password")}
      centered
      open={show}
      footer={null}
      onCancel={() => {
        onHide();
        form.resetFields();
      }}
      width="400px"
    >
      <div className="pt-1 pb-3">
        {t("otp-code-sent-to")} <strong>{phoneNumber}</strong>
      </div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              label="OTP"
              name="otp"
              rules={[
                { required: true },
                {
                  pattern: otpRegex,
                  message: t("otp-must-be-6"),
                },
              ]}
            >
              <Space.Compact className="w-full">
                <InputNumber className="w-full" placeholder="e.g., 123456" />
                <Button
                  htmlType="button"
                  onClick={() => {
                    fetch({
                      url: "/auth/forgot-password",
                      method: "POST",
                      payload: { phoneNumber },
                      onSuccess: () => {
                        toast.success("OTP sent successfully!");
                      },
                    });
                  }}
                >
                  {t("resend")}
                </Button>
              </Space.Compact>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              label={t("new-password")}
              name="password"
              rules={[
                {
                  required: true,
                  //  min: 8
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              label={t("confirm-new-password")}
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  //  min: 8
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t("passwords-do-not-match"));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-end w-full gap-3">
          <Button
            htmlType="button"
            onClick={() => {
              openSendOTPModal(
                phoneNumber.startsWith("964")
                  ? phoneNumber.replace("964", "")
                  : phoneNumber
              );
              onHide();
            }}
          >
            {t("back-to-send-otp")}
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            className="bg-primary"
            loading={isLoading}
          >
            {t("submit")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ForgetPasswordModal;
