import { Button } from "antd";
import ClickToCopy from "./ClickToCopy";
import { IoQrCodeOutline } from "react-icons/io5";

type qrCode = { label: string; value: string; key: string };

interface PhoneNumberFormatterProps {
  phoneNo: string | string[];
  onShowQRModal?: (qrCodes: qrCode[]) => void;
}

const PhoneNumberFormatter = ({
  phoneNo,
  onShowQRModal,
}: PhoneNumberFormatterProps) => {
  const selectedPhoneNumber = Array.isArray(phoneNo) ? phoneNo[0] : phoneNo;

  return (
    <div className="flex flex-row" dir="ltr">
      {onShowQRModal && (
        <Button
          type="text"
          onClick={() => {
            const phoneNumbers = Array.isArray(phoneNo) ? phoneNo : [phoneNo];
            const qrCodes: qrCode[] = [];
            phoneNumbers.forEach((phn, index) => {
              if (phn) {
                qrCodes.push({
                  label: index < 1 ? "Primary No." : "Secondary No.",
                  value: `tel:+${phn}`,
                  key: `${index} ${phn}`,
                });
              }
            });

            onShowQRModal(qrCodes);
          }}
          className="flex items-center px-3 py-2"
        >
          <IoQrCodeOutline size={20} />
        </Button>
      )}
      <ClickToCopy text={selectedPhoneNumber}>
        <div dir="ltr">
          {selectedPhoneNumber?.replace(
            /(\d{3})(\d{3})(\d{3})(\d{4})/,
            "(+$1) $2 $3 $4"
          )}
        </div>
      </ClickToCopy>
    </div>
  );
};

export default PhoneNumberFormatter;
