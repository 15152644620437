import { Upload, UploadFile, UploadProps } from "antd";
import { GoPerson } from "react-icons/go";
import ViewImageModal from "../common/ViewImageModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface UploadAndViewImageProps {
  selectedFiles?: UploadProps & UploadFile[];
  title: string;
  onChange: (fileList: UploadFile[]) => void;
}

const UploadAndViewImage = ({
  onChange,
  selectedFiles,
  title,
}: UploadAndViewImageProps) => {
  const { t } = useTranslation();
  const [showViewImageModal, setShowViewImageModal] = useState({
    title: "",
    imgSrc: "",
    show: false,
  });

  return (
    <>
      <ViewImageModal
        show={showViewImageModal.show}
        onHide={() => {
          setShowViewImageModal({
            title: "",
            imgSrc: "",
            show: false,
          });
        }}
        title={showViewImageModal.title}
        imgSrc={showViewImageModal.imgSrc}
        local
      />
      <Upload
        fileList={selectedFiles}
        beforeUpload={() => {
          return false;
        }}
        onChange={(e) => {
          const fileList = e.fileList;
          onChange([fileList[fileList.length - 1]]);
        }}
        listType="picture-circle"
        onPreview={async () => {
          if (selectedFiles && selectedFiles.length > 0) {
            const arrayBuffer =
              await selectedFiles[0]?.originFileObj?.arrayBuffer();

            const url = selectedFiles[0].url;
            setShowViewImageModal({
              title: title,
              imgSrc:
                (arrayBuffer
                  ? URL.createObjectURL(new Blob([arrayBuffer]))
                  : url) || "",
              show: true,
            });
          }
          return false;
        }}
        multiple={false}
        onRemove={() => {
          onChange([]);
          return false;
        }}
      >
        <button
          style={{ border: 0, background: "none" }}
          className={`flex flex-col items-center justify-center`}
          type="button"
        >
          <GoPerson size={20} />
          <div style={{ marginTop: 8 }}>{t("upload")}</div>
        </button>
      </Upload>
    </>
  );
};

export default UploadAndViewImage;
