import { Badge, Button, Modal, Popconfirm, TableProps, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import ClickToCopy from "../../UI/ClickToCopy";
import { removeSuspensionStatus } from "../removeSuspensionStatus";
import AccountProfilePicIcon from "../../common/AccountProfilePicIcon";
import { Dispatch, SetStateAction } from "react";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { VscSend } from "react-icons/vsc";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlinePicture,
} from "react-icons/ai";
import { GrDocumentUser } from "react-icons/gr";
import { BiMessageDetail } from "react-icons/bi";
import PocketBase from "pocketbase";

interface useRemoveSuspensionColumnsProps {
  setShowRiderInfo: Dispatch<
    SetStateAction<{
      show: boolean;
      partner_id: number;
    }>
  >;
  setShowRejectModal: Dispatch<
    SetStateAction<{
      show: boolean;
      id: number;
    }>
  >;
  setShowViewImageModal: Dispatch<
    SetStateAction<{
      imgSrc: string;
      show: boolean;
      title: string;
    }>
  >;
  refetch: () => void;
}

export default function useRemoveSuspensionColumns({
  setShowRiderInfo,
  setShowRejectModal,
  setShowViewImageModal,
  refetch,
}: useRemoveSuspensionColumnsProps) {
  const { t } = useTranslation();

  const pb = new PocketBase("https://saas.fullship.net");
  const fsRemoveSuspensionCollection = pb.collection(
    "FS_App_Remove_Suspention"
  );

  const columns: TableProps["columns"] = [
    {
      title: t("partner-id"),
      dataIndex: "partner_id",
      key: "partner_id",
      render: (val) => <ClickToCopy text={val}>{val}</ClickToCopy>,
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (status: keyof typeof removeSuspensionStatus) => (
        <Badge
          color={removeSuspensionStatus[status]}
          count={status}
          className="w-full text-center capitalize min-w-[70px]"
          showZero
        />
      ),
    },
    {
      title: t("rider"),
      dataIndex: "rider_name",
      key: "rider_name",
      render: (_, row) => (
        <div className="flex flex-row items-center gap-1">
          <div>
            <AccountProfilePicIcon
              imageURL={row.rider_picture}
              onClick={() => {
                if (row.rider_picture) {
                  setShowViewImageModal({
                    title: `${row.rider_name} Profile Pic`,
                    imgSrc: row.rider_picture || "",
                    show: true,
                  });
                }
              }}
            />
          </div>
          <div>
            <ClickToCopy text={row.rider_name}>
              <div className={`text-left`}>{row.rider_name}</div>
            </ClickToCopy>
          </div>
        </div>
      ),
    },
    {
      title: t("rider-city"),
      dataIndex: "rider_city",
      key: "rider_city",
    },
    {
      title: t("created"),
      dataIndex: "created",
      key: "created",
      render: (val) => moment(val).format("YYYY-MM-DD hh:mm:ss A"),
    },
    {
      title: t("actions"),
      render: (_, row) => {
        const url = pb.files.getUrl(row, row.suspention_screenshot, {
          thumb: "100x250",
        });

        return (
          <div className="flex flex-row items-center justify-end gap-1">
            {row.status === "pending" && (
              <Popconfirm
                placement="bottomLeft"
                title={t("change-status-to-sent")}
                description={t("sent-report-to-partner-confirm-message")}
                okText={t("yes")}
                cancelText={t("no")}
                onConfirm={() => {
                  fsRemoveSuspensionCollection
                    .update(row.id, { status: "sent" })
                    .then(() => {
                      refetch();
                      toast.success(t("status-changed-to-sent"));
                    })
                    .catch(() => {
                      toast.error(t("failed-to-change-status"));
                    });
                }}
              >
                <Button className="flex items-center px-3 py-2">
                  <VscSend size={14} />
                </Button>
              </Popconfirm>
            )}

            {row.status === "sent" && (
              <Popconfirm
                placement="bottomLeft"
                title={t("change-status-to-solve")}
                description={t("solved-confirm-message")}
                okText={t("yes")}
                cancelText={t("no")}
                onConfirm={() => {
                  fsRemoveSuspensionCollection
                    .update(row.id, { status: "solved" })
                    .then(() => {
                      refetch();
                      toast.success(t("status-changed-to-solved"));
                    })
                    .catch(() => {
                      toast.error(t("failed-to-change-status"));
                    });
                }}
              >
                <Button className="flex items-center px-3 py-2">
                  <AiOutlineCheck size={14} />
                </Button>
              </Popconfirm>
            )}

            {row.status === "sent" && (
              <Button
                onClick={() => {
                  setShowRejectModal({ show: true, id: row.id });
                }}
              >
                <AiOutlineClose size={14} />
              </Button>
            )}
            {row.suspention_screenshot && (
              <Button
                className="flex items-center px-3 py-2"
                onClick={() => {
                  setShowViewImageModal({
                    imgSrc: url,
                    show: true,
                    title: "Suspension Screenshot",
                  });
                }}
              >
                <AiOutlinePicture size={16} />
              </Button>
            )}

            {row.rejection_reason && (
              <Tooltip title={t("view-rejection-reason")}>
                <Button
                  onClick={() =>
                    Modal.info({
                      title: "Rejection Reason",
                      content: row.rejection_reason,
                      centered: true,
                      closable: true,
                      maskClosable: true,
                    })
                  }
                  className="flex items-center px-3 py-2"
                >
                  <BiMessageDetail size={16} />
                </Button>
              </Tooltip>
            )}
            <Tooltip title={t("view-rider-info")}>
              <Button
                onClick={() =>
                  setShowRiderInfo({
                    show: true,
                    partner_id: row.partner_id,
                  })
                }
                className="flex items-center px-3 py-2"
              >
                <GrDocumentUser size={16} />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  return { columns };
}
