import { Button } from "antd";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/common/Loading";
import { AiOutlinePlus } from "react-icons/ai";
import CreateManualBackupModal from "../../components/SuperPanel/Backup/CreateManualBackupModal";
import { useState } from "react";
import useAxios from "../../hooks/useAxios";
import { toast } from "react-toastify";
import { BsUpload } from "react-icons/bs";
import FileUploadModal from "../../components/common/FileUploadModal";
import BackupTable from "../../components/SuperPanel/Backup/BackupTable";
import { useTranslation } from "react-i18next";

const Backup = () => {
  const { t } = useTranslation();
  const [showManualBackupModal, setShowManualBackupModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const { isLoading, data, refetch } = useFetch<{
    autoBackupFiles: string[];
    manualBackupFiles: string[];
    tempBackupFiles: string[];
    uploadBackupFiles: string[];
  }>({
    url: `/admin/backup`,
    method: "GET",
    isLoadingDefault: true,
  });
  const { fetch, isLoading: isSubmitLoading } = useAxios({
    disabledStates: false,
  });

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <CreateManualBackupModal
        show={showManualBackupModal}
        onHide={() => {
          setShowManualBackupModal(false);
        }}
        refetch={refetch}
      />
      <FileUploadModal
        show={showFileUploadModal}
        onHide={() => {
          setShowFileUploadModal(false);
        }}
        isLoading={isSubmitLoading}
        title={t("upload-backup-file")}
        accepts=".backup"
        onSubmit={(file, callback) => {
          const formData = new FormData();
          formData.append("file", file, file.name);
          fetch({
            url: "/admin/backup/upload",
            method: "POST",
            payload: formData,
            onSuccess: () => {
              refetch();
              toast.success(t("backup-uploaded-successfully"));
              callback();
            },
          });
        }}
      />
      <div className="flex justify-between items-center">
        <Button
          onClick={() => {
            setShowManualBackupModal(true);
          }}
          className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 mb-5 bg-primary"
          type="primary"
        >
          <AiOutlinePlus size={20} /> {t("create-manual-backup")}
        </Button>
      </div>
      <div className="flex flex-row justify-between">
        <div className="w-[48%]">
          <BackupTable
            refetch={refetch}
            data={data?.manualBackupFiles || []}
            type="Manual"
          />
        </div>
        <div className="w-[48%]">
          <BackupTable
            refetch={refetch}
            data={data?.autoBackupFiles || []}
            type="Auto"
            deleteBackupOption={false}
          />
        </div>
      </div>
      <div className="flex justify-between items-center mt-10">
        <Button
          onClick={() => {
            setShowFileUploadModal(true);
          }}
          className="btn-height-40 rounded-border-xl-cstm flex justify-center items-center gap-2 mb-5 bg-primary"
          type="primary"
        >
          <BsUpload size={20} /> {t("upload-backup")}
        </Button>
      </div>
      <div className="flex flex-row justify-between">
        <div className="w-[48%]">
          <BackupTable
            refetch={refetch}
            data={data?.uploadBackupFiles || []}
            type="Upload"
          />
        </div>
        <div className="w-[48%]">
          <BackupTable
            refetch={refetch}
            data={data?.tempBackupFiles || []}
            type="Temp"
            deleteBackupOption={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Backup;
