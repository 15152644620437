import { mapDays } from "../data/mockData";

import {
  DayAbbreviation,
  EmailCardType,
  LeaveEmailPreference,
  ListTypeRes,
  LookupTableLeavePreferences,
  RiderEmailCardType,
  RiderEmailResSetting,
} from "../models/super-panel";
import { uniqueId } from "./IDUtils";

const convertLookupTableIntoDays = (
  lookupTable: LookupTableLeavePreferences,
  index: number
) => {
  const days: string[] = [];
  const keysLookUpTable = Object.keys(lookupTable) as DayAbbreviation[];

  keysLookUpTable.map((key) => {
    if (lookupTable[key] == index) {
      days.push(mapDays[key]);
    }
  });
  return days;
};

export const convertPresetsToEmailCards = (
  city: LeaveEmailPreference,
  name: string
) => {
  const converted: EmailCardType[] = city?.presets?.map((pr, index) => ({
    id: `card ${index} ${uniqueId()}`,
    name: pr.name,
    group: pr.group,
    selectedCityDays: {
      city: name,
      days: convertLookupTableIntoDays(city.lookupTable, index),
    },
    emails: {
      adminToInputs: pr.admin.to.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
      adminCcInputs: pr.admin.cc.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
      reqLeaveToInputs: pr.leaveReq.to.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
      reqLeaveCcInputs: pr.leaveReq.cc.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
      leaveCancelToInputs: pr.leaveCancel.to.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
      leaveCancelCcInputs: pr.leaveCancel.cc.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
    },
  }));
  return converted;
};

export const generateLeavePreferences = (emailCard: EmailCardType[]) => {
  const leavePreferences = {
    lookupTable: {
      sa: emailCard.findIndex((c) =>
        c.selectedCityDays.days.find((v) => v === "Sat")
      ),
      su: emailCard.findIndex((c) =>
        c.selectedCityDays.days.find((v) => v === "Sun")
      ),
      mo: emailCard.findIndex((c) =>
        c.selectedCityDays.days.find((v) => v === "Mon")
      ),
      tu: emailCard.findIndex((c) =>
        c.selectedCityDays.days.find((v) => v === "Tue")
      ),
      we: emailCard.findIndex((c) =>
        c.selectedCityDays.days.find((v) => v === "Wed")
      ),
      th: emailCard.findIndex((c) =>
        c.selectedCityDays.days.find((v) => v === "Thu")
      ),
      fr: emailCard.findIndex((c) =>
        c.selectedCityDays.days.find((v) => v === "Fri")
      ),
    },
    presets: emailCard.map((c) => ({
      name: c.name,
      group: c.group,
      admin: {
        to: c.emails.adminToInputs.map((em) => em.value),
        cc: c.emails.adminCcInputs.map((em) => em.value),
      },
      leaveReq: {
        to: c.emails.reqLeaveToInputs.map((em) => em.value),
        cc: c.emails.reqLeaveCcInputs.map((em) => em.value),
      },
      leaveCancel: {
        to: c.emails.leaveCancelToInputs.map((em) => em.value),
        cc: c.emails.leaveCancelCcInputs.map((em) => em.value),
      },
    })),
  };
  return leavePreferences;
};

export const convertRiderSettingsToEmailCards = (
  data: RiderEmailResSetting[],
  cities: ListTypeRes
) => {
  const converted: RiderEmailCardType[] = data?.map((pr, index) => ({
    id: `card ${index} ${uniqueId()}`,
    name: pr.name,
    selectedCity: {
      city: cities.find((c) => c.id === pr.cityID)?.name || "",
      cityID: pr.cityID,
    },
    emails: {
      adminToInputs: pr.admin.to.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
      adminCcInputs: pr.admin.cc.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
      freezeToInputs: pr.freeze.to.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
      freezeCcInputs: pr.freeze.cc.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
      terminateToInputs: pr.terminate.to.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
      terminateCcInputs: pr.terminate.cc.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
      changeDataToInputs: pr.changeData.to.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
      changeDataCcInputs: pr.changeData.cc.map((val, index) => {
        if (index == 0) {
          return {
            value: val,
            id: "email main",
          };
        }
        return { value: val, id: `email ${index} ${uniqueId()}` };
      }),
    },
  }));
  return converted;
};
