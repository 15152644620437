import Table from "../../components/UI/Table";
import useFetch from "../../hooks/useFetch";
import { ErrorLogsList } from "../../models/super-panel";
import { useState } from "react";
import MoreErrorInfoModal from "../../components/SuperPanel/ErrorLogs/MoreErrorInfoModal";
import { useTranslation } from "react-i18next";
import useErrorLogsColumns from "../../components/SuperPanel/ErrorLogs/hooks/useErrorLogsColumns";
import { useSearchParams } from "react-router-dom";

const ErrorLogs = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const errorLogsPsl = localStorage.getItem("errorLogs-psl") || "10";

  const paginate = parseInt(searchParams.get("paginate") || "1");
  const pageSize = parseInt(searchParams.get("pageSize") || errorLogsPsl);
  const searchQuery = JSON.parse(searchParams.get("searchQuery") || "0");

  const [showMoreErrorInfoModal, setShowMoreErrorInfoModal] = useState({
    show: false,
    payload: "",
    stack: "",
  });

  const { data, isLoading, refetch } = useFetch<ErrorLogsList>({
    url: `/admin/error-logs`,
    searchQuery: JSON.stringify({
      ...searchQuery,
      _limit: pageSize,
      _offset: (paginate - 1) * pageSize,
      _sort: "id",
      _order: "desc",
    }),
    method: "GET",
    isLoadingDefault: true,
  });

  const { columns } = useErrorLogsColumns({
    setShowMoreErrorInfoModal,
    refetch,
  });

  return (
    <div>
      <MoreErrorInfoModal
        show={showMoreErrorInfoModal.show}
        payload={showMoreErrorInfoModal.payload}
        stack={showMoreErrorInfoModal.stack}
        onHide={() => {
          setShowMoreErrorInfoModal({ show: false, payload: "", stack: "" });
        }}
      />
      <Table
        loading={isLoading || false}
        columns={columns}
        data={data ? data.data : []}
        pagination={true}
        total={data ? data.total : 0}
        pageSize={pageSize}
        currentPage={paginate}
        onPaginationChange={(page) => {
          setSearchParams((params) => {
            params.set("paginate", page.toString());
            return params;
          });
        }}
        onShowSizeChange={(_, size) => {
          localStorage.setItem("errorLogs-psl", size.toString());
          setSearchParams((params) => {
            params.set("pageSize", size.toString());
            return params;
          });
        }}
        refetch={refetch}
        filter={true}
        filters={{
          config: [
            {
              type: "selection",
              label: t("fixed"),
              name: "fixed",
              selectionProps: {
                options: [
                  { label: t("yes"), value: "Yes" },
                  { label: t("no"), value: "No" },
                ],
              },
            },
            {
              type: "selection",
              label: t("operational"),
              name: "operational",
              selectionProps: {
                options: [
                  { label: t("yes"), value: "Yes" },
                  { label: t("no"), value: "No" },
                ],
              },
            },
            {
              type: "dateRange",
              label: t("occurred-at"),
              name: "occurred_at_",
            },
          ],
        }}
        onFilterValuesChange={(_, values, filters) => {
          const searchQuery = {
            ...(values.searchText && { search: values.searchText }),
            ...(values?.sort?.column && {
              _sort: values.sort.column,
              _order: values.sort.type,
            }),
            ...filters,
          };

          setSearchParams((params) => {
            params.set("searchQuery", JSON.stringify(searchQuery));
            return params;
          });
        }}
      />
    </div>
  );
};

export default ErrorLogs;
