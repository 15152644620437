import React, { Key, ReactNode, useState } from "react";
import { GoPerson, GoTasklist } from "react-icons/go";
import { PiMoney, PiUsers } from "react-icons/pi";
import { AiOutlineLogout, AiOutlineProfile } from "react-icons/ai";
import {
  Button,
  Dropdown,
  Layout,
  Menu,
  MenuProps,
  theme,
  Tooltip,
} from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useUser from "../../store/User";
import useAxios from "../../hooks/useAxios";
import {
  MdOutlineAdminPanelSettings,
  MdOutlineSportsMotorsports,
} from "react-icons/md";
import { BsSuitcase } from "react-icons/bs";
import { LiaClipboardListSolid } from "react-icons/lia";
import { CiLight } from "react-icons/ci";
import { CiDark } from "react-icons/ci";
import useTheme from "../../store/Theme";
import { CgPerformance } from "react-icons/cg";
import { Sections } from "../../models/user";
import { hasViewAccess } from "../../utils/authorizationUtils";
import { LuLayoutDashboard } from "react-icons/lu";
import { FaRegChartBar } from "react-icons/fa";
import Fullship from "../../assets/icon/fullship.svg?react";
import Tahadi from "../../assets/icon/tahadi.svg?react";
import RulesAndRegulationsModal from "./RulesAndRegulationsModal";
import BugReportForm from "./ReportBug";
import LanguageSwitcher from "../UI/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import ListFetcher from "../../hooks/useListFetcher";

const LOGO = (import.meta.env.VITE_LOGO + "").includes("fullship")
  ? Fullship
  : Tahadi;

const { Header, Content, Sider } = Layout;

const MainLayout = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { theme: themeMode, setTheme } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsAuth, setUser } = useUser();
  const { fetch } = useAxios({ disabledStates: true });
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("sider-collapsed") == "1"
  );

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleLogout = async () => {
    await fetch({
      url: "/auth/logout",
      method: "POST",
      payload: {},
      onSuccess: (data) => {
        if (data && data.status == 200) {
          setUser(null);
          setIsAuth(false);
          navigate("/login");
          return;
        }
        window.location.reload();
      },
      onError: () => {
        window.location.reload();
      },
    });
  };

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: ReactNode,
    key: Key,
    section: Sections | false,
    icon?: ReactNode,
    children?: MenuItem[]
  ): MenuItem | null {
    if (section && !hasViewAccess(section, user)) {
      return null;
    }
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  }

  const siderItems: MenuItem[] = [
    getItem(t("dashboard"), "", false, <LuLayoutDashboard size={18} />),
    getItem(t("accounts"), "accounts", "ONLY-SUPER", <PiUsers size={18} />),
    getItem(
      t("riders"),
      "riders",
      "RI",
      <MdOutlineSportsMotorsports size={18} />
    ),
    getItem(
      t("hiring-process"),
      "hiring-process",
      // temp check for hiding the menu
      hasViewAccess("WL", user)
        ? "WL"
        : hasViewAccess("IN", user)
          ? "IN"
          : "ONLY-SUPER",
      <LiaClipboardListSolid size={18} />
    ),
    getItem(t("leaves"), "leaves", "LE", <BsSuitcase size={18} />),
    getItem(t("finance"), "finance", "FI", <PiMoney size={18} />, [
      getItem(t("cash-collection"), "finance/cash-collection", false),
      getItem(t("records"), "finance/records", false),
      getItem(t("cod"), "finance/cod", "ONLY-SUPER", false),
      getItem(t("sales"), "finance/sales", "ONLY-SUPER", false),
      getItem(t("wallets"), "finance/wallets", "ONLY-SUPER", false),
      getItem(t("payroll"), "finance/payroll", "ONLY-SUPER", false),
    ]),
    getItem(t("performance"), "performance", "PE", <CgPerformance size={18} />),
    getItem(
      t("statistics"),
      "statistics",
      "ONLY-SUPER",
      <FaRegChartBar size={18} />,
      [
        getItem(t("performance-ranks"), "performance-ranks", false),
        getItem(t("performance-fda"), "performance-fda", false),
      ]
    ),
    getItem(
      t("processes"),
      "processes",
      "ONLY-SUPER",
      <GoTasklist size={18} />
    ),
    getItem(
      t("super-panel"),
      "super-panel",
      "ONLY-SUPER",
      <MdOutlineAdminPanelSettings size={18} />,
      [
        getItem(t("settings"), "super-panel/settings", "ONLY-SUPER"),
        getItem(t("logs"), "super-panel/logs", "ONLY-SUPER"),
        getItem(t("error-logs"), "super-panel/error-logs", "ONLY-SUPER"),
        getItem(t("backup"), "super-panel/backup", "ONLY-SUPER"),
      ]
    ),
  ];

  const profileDropdownItems = [
    {
      key: "profile",
      label: (
        <div className="flex items-center gap-4 min-w-28 py-1">
          <AiOutlineProfile size={18} />
          <span>{t("my-account")}</span>
        </div>
      ),
      onClick: () => navigate("/account"),
    },
    // {
    //   key: "settings",
    //   label: (
    //     <div className="flex items-center gap-4 min-w-28 py-1">
    //       <SettingOutlined />
    //       <span>Settings</span>
    //     </div>
    //   ),
    //   onClick: () => navigate("/settings"),
    // },
    {
      key: "logout",
      label: (
        <div className="flex items-center gap-4 min-w-28 py-1">
          <AiOutlineLogout size={18} />
          <span>{t("logout")}</span>
        </div>
      ),
      onClick: handleLogout,
    },
  ];

  const filteredSiderItems = siderItems.filter(Boolean);
  const defaultSelectedKey = filteredSiderItems[0]?.key
    ? (filteredSiderItems[0].key as string)
    : "";
  const activeTab = location.pathname.replace("/", "");

  const checkSubRoutes = (route: string) => {
    if (route.includes("hiring-process")) {
      return "hiring-process";
    }
    if (route.includes("accounts")) {
      return "accounts";
    }
    if (route.includes("riders")) {
      return "riders";
    }
    return route;
  };
  return (
    <Layout className="h-screen">
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => {
          localStorage.setItem("sider-collapsed", value ? "1" : "0");
          setCollapsed(value);
        }}
        className="overflow-y-auto"
      >
        <div
          className={`relative px-6 flex items-center justify-center gap-6 pt-3`}
        >
          <LOGO />
          {/* <div
            className={`absolute text-white text-nowrap text-base font-semibold transition-all left-20 ${
              collapsed ? "opacity-0" : "opacity-100"
            }`}
          >
            Fullship
          </div> */}
        </div>
        <Menu
          theme={themeMode === "light" ? "dark" : undefined}
          defaultSelectedKeys={[defaultSelectedKey]}
          selectedKeys={[
            location.pathname !== "/"
              ? checkSubRoutes(activeTab)
              : defaultSelectedKey,
          ]}
          mode="inline"
          items={filteredSiderItems}
          onSelect={({ key }) => navigate(`/${key}`)}
          className="mt-6 pb-16"
        />
      </Sider>

      <Layout>
        <Header
          className={`w-full flex justify-between items-center px-0 border-b-[1px] z-10 ${
            themeMode === "dark" ? "border-b-[#303030]" : "border-b-[#F0F0F0]"
          } antd-cstm-header`}
          style={{
            background: colorBgContainer,
          }}
        >
          <div className="ms-5 flex gap-3 items-center">
            <BugReportForm />
            <LanguageSwitcher />
          </div>
          <div className="ms-auto me-5 flex gap-3 items-center">
            {user?.cityID === 1 && <RulesAndRegulationsModal />}
            <Tooltip title={t("change-theme")}>
              <Button
                className="border-1 rounded-full p-3 flex items-center justify-center bg-primary"
                shape="circle"
                onClick={() => {
                  if (themeMode === "dark") {
                    setTheme("light");
                    localStorage.setItem("theme", "light");
                    document.body.classList.remove("dark");
                  } else {
                    setTheme("dark");
                    localStorage.setItem("theme", "dark");
                    document.body.classList.add("dark");
                  }
                }}
              >
                {themeMode === "dark" ? (
                  <CiDark size={22} />
                ) : (
                  <CiLight size={22} />
                )}
              </Button>
            </Tooltip>
            <Dropdown
              menu={{ items: profileDropdownItems }}
              placement="bottomLeft"
              arrow
              trigger={["click"]}
            >
              <Button
                type="primary"
                className="border-1 rounded-full p-2 flex items-center justify-center bg-primary"
              >
                <GoPerson size={16} />
              </Button>
            </Dropdown>
          </div>
        </Header>
        <div className="antd-cstm-content overflow-auto w-full p-3">
          <Content
            className="p-4"
            style={{
              backgroundColor: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet />
          </Content>
        </div>
      </Layout>
      <ListFetcher />
    </Layout>
  );
};

export default React.memo(MainLayout);
