import React from "react";
import { LayoutRoute } from "../models/interfaces";
import { generateRoutes } from "./generate-routes";
import AnonymousLayout from "../components/layouts/AnonymousLayout";
import MainLayout from "../components/layouts/MainLayout";
import Login from "../pages/Login";
import Account from "../pages/Account";
import ErrorLogs from "../pages/SuperPanel/ErrorLogs";
import Logs from "../pages/SuperPanel/Logs";
import Backup from "../pages/SuperPanel/Backup";
import NotFoundPage from "../components/UI/NotFoundPage";
import Settings from "../pages/SuperPanel/Settings";
import HomePage from "../pages/HomePage";
import HiringProcessLayout from "../components/HiringProcess/Layout";
import AccountsLayout from "../components/Accounts/Layout";
import RidersLayout from "../components/Riders/Layout";
import RiderFeedbacks from "../components/Riders/Mobile/RiderFeedbacks";
import RemoveSuspension from "../components/Riders/Mobile/RemoveSuspension";
import PayrollLayout from "../components/Finance/layouts/PayrollLayout";
import Reviews from "../pages/Finance/Payroll/Reviews";

const Tags = React.lazy(() => import("../pages/Tags"));
const Processes = React.lazy(() => import("../pages/Processes"));
const Wallets = React.lazy(() => import("../pages/Finance/Wallets"));
const Analytics = React.lazy(() => import("../pages/Analytics"));
const Statistics = React.lazy(() => import("../pages/Statistics"));
const FSCash = React.lazy(() => import("../pages/Finance/FSCash"));
const FinanceRecords = React.lazy(
  () => import("../pages/Finance/FinanceRecords")
);
const Sales = React.lazy(() => import("../pages/Finance/Sales"));
const CashOnDelivery = React.lazy(
  () => import("../pages/Finance/CashOnDelivery")
);
const Payroll = React.lazy(() => import("../pages/Finance/Payroll/Payroll"));
const WaitingList = React.lazy(
  () => import("../pages/HiringProcess/WaitingList")
);
const InterviewList = React.lazy(
  () => import("../pages/HiringProcess/InterviewList")
);
const TrainingList = React.lazy(
  () => import("../pages/HiringProcess/TrainingList")
);
const HiringList = React.lazy(
  () => import("../pages/HiringProcess/HiringList")
);
const Onboard = React.lazy(() => import("../pages/HiringProcess/Onboard"));
const WarehouseList = React.lazy(
  () => import("../pages/HiringProcess/WarehouseList")
);
const AuditList = React.lazy(() => import("../pages/HiringProcess/AuditList"));

const Riders = React.lazy(() => import("../pages/Riders"));
const Leaves = React.lazy(() => import("../pages/Leaves"));
const Performance = React.lazy(() => import("../pages/Performance"));
const Accounts = React.lazy(() => import("../pages/Accounts/AdminAccounts"));
const CaptainAccounts = React.lazy(
  () => import("../pages/Accounts/CaptainAccounts")
);
const RidersAccounts = React.lazy(
  () => import("../pages/Accounts/RidersAccounts")
);

export const routes: LayoutRoute[] = [
  {
    layout: AnonymousLayout,
    routes: [
      {
        title: "Login page",
        component: Login,
        path: "/login",
        isPublic: true,
      },
    ],
  },
  {
    layout: MainLayout,
    routes: [
      {
        // name:"",
        title: "Home Page",
        component: HomePage,
        path: "/",
      },
      {
        name: "ONLY-SUPER",
        title: "Accounts",
        component: Accounts,
        path: "/accounts/*",
        layout: AccountsLayout,
        routes: [
          {
            name: "ONLY-SUPER",
            title: "Admins Acc",
            component: Accounts,
            path: "/accounts/admins",
          },
          {
            name: "ONLY-SUPER",
            title: "Captains Acc",
            component: CaptainAccounts,
            path: "/accounts/captains",
          },
          {
            name: "ONLY-SUPER",
            title: "Riders Acc",
            component: RidersAccounts,
            path: "/accounts/riders",
          },
        ],
      },
      {
        name: "RI",
        title: "Riders",
        component: Riders,
        path: "/riders/*",
        layout: RidersLayout,
        routes: [
          {
            name: "RI",
            title: "Riders",
            component: Riders,
            path: "/riders/list",
          },
          {
            name: "RI",
            title: "Tags",
            component: Tags,
            path: "/riders/tags",
          },
          {
            name: "RI",
            title: "Rider Feedbacks",
            component: RiderFeedbacks,
            path: "/riders/rider-feedbacks",
          },
          {
            name: "RI",
            title: "Remove Suspension",
            component: RemoveSuspension,
            path: "/riders/remove-suspension",
          },
        ],
      },
      {
        // name: "A",
        title: "Account Page",
        component: Account,
        path: "/account",
      },
      {
        name: "LE",
        title: "Leave Page",
        component: Leaves,
        path: "/leaves",
      },
      {
        name: "FI",
        title: "Finance Page",
        component: FSCash,
        path: "/finance/*",
        routes: [
          {
            title: "Cash Collection Page",
            component: FSCash,
            path: "/finance/cash-collection",
          },
          {
            title: "Records Page",
            component: FinanceRecords,
            path: "/finance/records",
          },
          {
            name: "ONLY-SUPER",
            title: "Wallets Page",
            component: Wallets,
            path: "/finance/wallets",
          },
          {
            name: "ONLY-SUPER",
            title: "Sales Page",
            component: Sales,
            path: "/finance/sales",
          },
          {
            name: "ONLY-SUPER",
            title: "COD Page",
            component: CashOnDelivery,
            path: "/finance/cod",
          },
          {
            name: "ONLY-SUPER",
            title: "Payroll",
            component: Payroll,
            path: "/finance/payroll/*",
            layout: PayrollLayout,
            routes: [
              {
                name: "ONLY-SUPER",
                title: "List",
                component: Payroll,
                path: "/finance/payroll/list",
              },
              {
                name: "ONLY-SUPER",
                title: "Reviews",
                component: Reviews,
                path: "/finance/payroll/reviews",
              },
            ],
          },
        ],
      },
      {
        name: "PE",
        title: "Performance Page",
        component: Performance,
        path: "/performance",
      },
      {
        name: "ONLY-SUPER",
        title: "Statistics Page",
        component: Statistics,
        path: "/performance-ranks",
      },
      {
        name: "ONLY-SUPER",
        title: "Analytics Page",
        component: Analytics,
        path: "/performance-fda",
      },
      {
        name: "WL",
        title: "Hiring Process",
        component: WaitingList,
        path: "/hiring-process/*",
        layout: HiringProcessLayout,
        routes: [
          {
            name: "WL",
            title: "Waiting List",
            component: WaitingList,
            path: "/hiring-process/waiting-list",
          },
          {
            name: "IN",
            title: "Interview List",
            component: InterviewList,
            path: "/hiring-process/interview",
          },
          {
            name: "WL",
            title: "Training List",
            component: TrainingList,
            path: "/hiring-process/training",
          },
          {
            name: "WL",
            title: "Audit List",
            component: AuditList,
            path: "/hiring-process/audit",
          },
          {
            name: "WL",
            title: "Warehouse List",
            component: WarehouseList,
            path: "/hiring-process/warehouse",
          },
          {
            name: "WL",
            title: "Onboard",
            component: Onboard,
            path: "/hiring-process/onboard",
          },
          {
            name: "WL",
            title: "Hiring List",
            component: HiringList,
            path: "/hiring-process/all",
          },
        ],
      },
      {
        name: "ONLY-SUPER",
        title: "Processes",
        component: Processes,
        path: "/processes",
      },
      {
        name: "ONLY-SUPER",
        title: "Admin Panel",
        component: ErrorLogs,
        path: "/super-panel/*",
        routes: [
          {
            name: "ONLY-SUPER",
            title: "Error Logs",
            component: ErrorLogs,
            path: "/super-panel/error-logs",
          },
          {
            name: "ONLY-SUPER",
            title: "Logs",
            component: Logs,
            path: "/super-panel/logs",
          },
          {
            name: "ONLY-SUPER",
            title: "Backup",
            component: Backup,
            path: "/super-panel/backup",
          },
          {
            name: "ONLY-SUPER",
            title: "Settings",
            component: Settings,
            path: "/super-panel/settings/*",
          },
        ],
      },
    ],
  },
  {
    layout: AnonymousLayout,
    routes: [
      {
        path: "/*",
        title: "404 Page",
        component: NotFoundPage,
      },
    ],
  },
];

export const Routes = generateRoutes(routes);
