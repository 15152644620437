import React, { useState } from "react";
import { Button, Form, type FormProps, Input } from "antd";
import useAxios from "../hooks/useAxios";
import useUser from "../store/User";
import { Navigate, useNavigate } from "react-router-dom";
import { UserAuth, UserSettings } from "../models/user";
import Bowser from "bowser";
import { phoneNumberRegexWithoutCode } from "../data/mockData";
import ForgetPasswordModal from "../components/ForgetPassword/ForgetPasswordModal";
import SendOTPModal from "../components/ForgetPassword/SendOTPModal";
import { toast } from "react-toastify";
import useTheme from "../store/Theme";
import Fullship from "../assets/icon/fullship.svg?react";
import Tahadi from "../assets/icon/tahadi.svg?react";
import { t } from "i18next";

const LOGO = (import.meta.env.VITE_LOGO + "").includes("fullship")
  ? Fullship
  : Tahadi;

type FieldType = {
  password?: string;
  phoneNumber?: string;
};

const Login: React.FC = () => {
  const { theme } = useTheme();
  const { setUser, setIsAuth, user, setUserSettings } = useUser();
  const navigate = useNavigate();
  const { fetch, isLoading } = useAxios({ disabledStates: false });
  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState({
    show: false,
    phoneNumber: "",
  });
  const [showSendOTPModal, setShowSendOTPModal] = useState({
    show: false,
    phoneNumber: "",
  });

  const getUserSettings = async () => {
    await fetch<{ setting: UserSettings }>({
      url: "/account/setting",
      method: "GET",
      onSuccess: (res) => {
        setUserSettings(res?.data?.setting || {});
      },
    });
  };

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    const browser = Bowser.parse(window.navigator.userAgent);
    const browserInfo = `${browser.browser.name} ${browser.browser.version}`;
    const deviceInfo = `${browser.platform.type}`;
    const systemInfo = `${browser.os.name} ${browser.os.version}`;

    const modifiedPhoneNumber =
      "964" + values?.phoneNumber?.replace(/^0?7/, "7");

    await fetch<UserAuth>({
      url: "/auth/login",
      method: "POST",
      payload: {
        phoneNumber: modifiedPhoneNumber,
        password: values.password,
        browser: browserInfo,
        device: deviceInfo,
        system: systemInfo,
      },
      onSuccess: async (response) => {
        await getUserSettings();
        setUser(response.data);
        setIsAuth(true);
        navigate("/");
        toast.success(t("logged-in-successfully"));
      },
      onError: async (err) => {
        if (err.message === "You are already authenticated") {
          window.location.reload();
        }
      },
    });
  };

  if (user?.id) {
    return <Navigate to="/" />;
  }

  return (
    <Form
      name="depo-login-form"
      labelAlign="left"
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      className={`border-2 rounded-border-xl-cstm px-20 pt-0 pb-12 min-w-[600px] flex flex-col ${
        theme === "dark" ? "bg-[#141414] border-none" : "bg-white"
      }`}
    >
      <SendOTPModal
        show={showSendOTPModal.show}
        onHide={() => setShowSendOTPModal({ show: false, phoneNumber: "" })}
        openForgetPasswordModal={(phoneNumber) =>
          setShowForgetPasswordModal({ show: true, phoneNumber })
        }
        phoneNumber={showSendOTPModal.phoneNumber}
        fromLogin={true}
      />
      <ForgetPasswordModal
        show={showForgetPasswordModal.show}
        onHide={() =>
          setShowForgetPasswordModal({ show: false, phoneNumber: "" })
        }
        phoneNumber={
          "964" + showForgetPasswordModal.phoneNumber?.replace(/^0?7/, "7")
        }
        openSendOTPModal={(phoneNumber) =>
          setShowSendOTPModal({ show: true, phoneNumber })
        }
      />
      <div className={`flex flex-col items-center justify-center gap-5 py-12`}>
        <LOGO width="80px" height="80px" />
        <div
          className={`text-[#356385] text-nowrap text-2xl font-semibold capitalize`}
        >
          {import.meta.env.VITE_TITLE}
        </div>
      </div>
      <Form.Item<FieldType>
        label={t("phone-number")}
        name="phoneNumber"
        rules={[
          { required: true },
          {
            pattern: phoneNumberRegexWithoutCode,
            message: t("enter-valid-phone-number"),
          },
        ]}
        className="mb-6"
      >
        <Input addonBefore="+964" placeholder="7*********" />
      </Form.Item>

      <Form.Item<FieldType>
        label={t("password")}
        name="password"
        rules={[
          {
            required: true,
            // min: 8
          },
        ]}
      >
        <Input.Password placeholder="password" />
      </Form.Item>
      <Button
        className="mx-auto mt-10 bg-primary"
        type="primary"
        htmlType="submit"
        loading={isLoading}
      >
        {t("login")}
      </Button>
      <div className="mt-5">
        <Button
          onClick={() => setShowSendOTPModal({ show: true, phoneNumber: "" })}
          className="border-none shadow-none text-[#356385]"
        >
          {t("forgot-password")}
        </Button>
      </div>
    </Form>
  );
};
export default Login;
