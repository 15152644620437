import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { Flex, Input, InputRef, Tag, theme } from "antd";
import {
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { AiOutlinePlus } from "react-icons/ai";
import DraggableTag from "./DraggableTag";
import { isEmpty, uniqueId } from "lodash-es";

export type Item = { id: number; text: string };

interface DraggableTagsProps {
  value: Item[];
  onChange: (value: Item[]) => void;
}

export default function DraggableTags({
  value = [],
  onChange,
}: DraggableTagsProps) {
  console.log(value);
  const { useToken, getDesignToken } = theme;
  const { token } = useToken();
  const sensors = useSensors(useSensor(PointerSensor));

  const designToken = getDesignToken();
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  const tagInputStyle: CSSProperties = {
    width: 64,
    height: designToken.controlHeight,
    marginInlineEnd: 8,
    verticalAlign: "top",
  };

  const tagPlusStyle: CSSProperties = {
    height: designToken.controlHeight,
    background: token.colorBgContainer,
    borderStyle: "dashed",
    alignItems: "center",
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) {
      return;
    }
    if (active.id !== over.id) {
      const oldIndex = value.findIndex((item) => item.id === active.id);
      const newIndex = value.findIndex((item) => item.id === over.id);
      onChange(arrayMove(value, oldIndex, newIndex));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (value && !value.some((val) => val.text === inputValue)) {
      onChange([...value, { id: Number(uniqueId()), text: inputValue }]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void = (e) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...value];
    newTags[editInputIndex] = {
      ...newTags[editInputIndex],
      text: editInputValue,
    };
    onChange(newTags);
    setEditInputIndex(-1);
    setEditInputValue("");
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleClose: (tagId: number) => MouseEventHandler<HTMLElement> =
    (tagId) => () => {
      const newTags = value.filter((val) => val.id != tagId);
      onChange(newTags);
    };

  const handleDoubleClick: (
    index: number,
    tag: Item
  ) => MouseEventHandler<HTMLSpanElement> = (index, tag) => (e) => {
    e.preventDefault();
    setEditInputIndex(index);
    setEditInputValue(tag.text);
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      collisionDetection={closestCenter}
    >
      <SortableContext items={value} strategy={horizontalListSortingStrategy}>
        <Flex gap="4px 0" wrap>
          {!isEmpty(value) &&
            value.map<ReactNode>((tag, index) => (
              <DraggableTag
                {...{
                  editInputRef,
                  editInputValue,
                  tag,
                  index,
                  editInputIndex,
                  onEditInputChange: handleEditInputChange,
                  onEditInputConfirm: handleEditInputConfirm,
                  onClose: handleClose,
                  onDoubleClick: handleDoubleClick,
                }}
              />
            ))}
          {inputVisible ? (
            <Input
              ref={inputRef}
              size="small"
              style={tagInputStyle}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
            />
          ) : (
            <Tag
              style={tagPlusStyle}
              icon={<AiOutlinePlus />}
              onClick={showInput}
            />
          )}
        </Flex>
      </SortableContext>
    </DndContext>
  );
}
