import {
  ChangeEventHandler,
  CSSProperties,
  MouseEventHandler,
  RefObject,
} from "react";
import { Item } from "./DraggableTags";
import { useSortable } from "@dnd-kit/sortable";
import { Input, InputRef, Tag, theme } from "antd";

interface DraggableTagProps {
  editInputRef: RefObject<InputRef>;
  editInputIndex: number;
  index: number;
  tag: Item;
  editInputValue: string;
  onEditInputChange: ChangeEventHandler<HTMLInputElement>;
  onEditInputConfirm: () => void;
  onClose: (tagId: number) => MouseEventHandler<HTMLElement>;
  onDoubleClick: (
    index: number,
    tag: Item
  ) => MouseEventHandler<HTMLSpanElement>;
}

export default function DraggableTag({
  tag,
  index,
  editInputIndex,
  editInputRef,
  editInputValue,
  onEditInputChange,
  onEditInputConfirm,
  onClose,
  onDoubleClick,
}: DraggableTagProps) {
  const { getDesignToken } = theme;
  const designToken = getDesignToken();
  const { listeners, transform, transition, isDragging, setNodeRef } =
    useSortable({ id: tag.id });

  const tagInputStyle: CSSProperties = {
    width: 64,
    height: designToken.controlHeight,
    marginInlineEnd: 8,
    verticalAlign: "top",
  };

  const commonStyle: CSSProperties = {
    userSelect: "none",
    height: designToken.controlHeight,
    alignItems: "center",
    transition: "unset", // Prevent element from shaking after drag
  };

  const style = transform
    ? {
        ...commonStyle,
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        transition: isDragging ? "unset" : transition, // Improve performance/visual effect when dragging
      }
    : commonStyle;

  if (editInputIndex === index) {
    return (
      <Input
        ref={editInputRef}
        key={tag.id}
        type="text"
        size="small"
        style={tagInputStyle}
        value={editInputValue}
        onChange={onEditInputChange}
        onBlur={onEditInputConfirm}
        onPressEnter={onEditInputConfirm}
      />
    );
  }

  return (
    <Tag key={tag.id} closable style={style} onClose={onClose(tag.id)}>
      <span
        style={{ cursor: "move" }}
        ref={setNodeRef}
        {...listeners}
        onDoubleClick={onDoubleClick(index, tag)}
      >
        {tag.text}
      </span>
    </Tag>
  );
}
